import { useSelector } from "react-redux";
import CertifiedIcon from "../../../../assets/images/domain-expert/certified-icon.png";
import JobRoleIcon from "../../../../assets/images/domain-expert/job-role-icon.png";
import ProgressIcon from "../../../../assets/images/domain-expert/progress-icon.png";

const JobRoleCalculationList = () => {
  const { roleCount } = useSelector(
    (state) => state?.domainExpert
  );

  return (
    <>
      <div className="job-role-wrapper mt-4">
        <div className="job-value-list">
          <ul>
              <li >
                <div className="job-value-box">
                  <span>
                    <img src={JobRoleIcon} alt="icon" />
                  </span>
                  <div className="job-title">
                    <h4>Total Job Roles</h4>
                    <h3>{roleCount?.total || 0}</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="job-value-box">
                  <span>
                    <img src={CertifiedIcon} alt="icon" />
                  </span>
                  <div className="job-title">
                    <h4>Job Roles Certified</h4>
                    <h3>{roleCount?.certified || 0}</h3>
                  </div>
                </div>
              </li>
              <li >
                <div className="job-value-box">
                  <span>
                    <img src={ProgressIcon} alt="icon" />
                  </span>
                  <div className="job-title">
                    <h4>Job Roles In-Progress</h4>
                    <h3>{roleCount?.non_certified || 0}</h3>
                  </div>
                </div>
              </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default JobRoleCalculationList;
