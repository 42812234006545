import { Button, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../components/dropdown";
import { jobListOption } from "../../constant";
import { useDispatch } from "react-redux";
import { storeSelValues } from "../../store/skillRenewal";

const RelevantSkillValidate = ({show ,handleClose}) => {

  return (
    <div>
      <Modal
        visible={show}
        onOk={handleClose}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleClose}
        cancelButtonProps={{ style: { display: "none" } }}
        className="skill-qs-modal validate-modal"
        width={600}
      >
        <div className="test-question-modal">
          <h2 className="title">
            Tests for this role are being Validated by the Domain Expert
          </h2>
          <div className="text-validate-box">
            <p>
            The domain expert is currently validating the test, ensuring its accuracy and relevance. Their expertise guarantees that the test effectively assesses the required skills.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RelevantSkillValidate;
