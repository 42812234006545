import React, { useEffect, useState } from "react";
import { Button, Divider, Modal, Steps, message } from "antd";
import { Col, Row } from "antd";
import ProfileTabs from "./ProfileTabs";
import { generateUniqueID } from "../helper/helper";
import Collepsetimeline from "./timeline/collepsetimeline";
import Loader from "./loader";
import { callGetApi, callPostApi } from "../services/roadmap_api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { storeGlobalSkillsDes, storeGlobalValues } from "../store/slice";
import { useDispatch, useSelector } from "react-redux";
import { storeAllSkillTests } from "../store/skillRenewal";
import { uniqueByTargetRoleName } from "../dashboard/utils";
import { DoubleLeftOutlined } from "@ant-design/icons";

import { Card } from "react-bootstrap";

import apiFetch, { BASE_URL } from "../api";

const { Step } = Steps;

const UserGoalProfile = ({current_company}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [step1Verified, setStep1Verified] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [linkorfile, setLinkorfile] = useState(null);
  const [roadmapres, setRoadmapres] = useState("");
  const [uniqueSkills, setUniqueSkills] = useState(null);

  let isView = localStorage.getItem("showlinks");

  const [activeTab, setActiveTab] = useState("1");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [interest, setInterest] = useState(false);
  const [roadmapLoader, setRoadmapLoader] = useState(false);
  /* States for form */
  const [addformdata, setAddformdata] = useState([]); // Complete form data
  const [previousjobs, setPerviousjobs] = useState([]); //  for only previous job data
  const [addpervjobsfields, setAddpervjobsfields] = useState([]); //  for previous job data

  ///////////// my codiing here

  const [allRoles, setAllRoles] = useState([]);
  const [loadingFor, setLoadingFor] = useState("");
  const [target, setTarget] = useState({ tar: "", skill: "" });
  const [mentorsData, setMentorsData] = useState([]);
  const [cities, setCities] = useState([]);

  let user = JSON?.parse(localStorage.getItem("user"));

  const { allRolesDes } = useSelector((state) => state?.skillRenewal);

  useEffect(() => {
    if (current_company) {
      getPreferencesData();
      getProfileData();
      const analyserData = JSON?.parse(localStorage.getItem("analyserData"));
      console.log(analyserData, "analyserData");
      if (analyserData) {
        setLoadingFor("");
        setCurrentStep(3);
        setRoadmapres(analyserData);
      } else {
        getRoleOptions();
      }
      return () => {
        localStorage.removeItem("isMap");
        // localStorage.removeItem("renewal-data")
        localStorage.removeItem("renewal-val");
        localStorage.removeItem("analyserData");
        localStorage.removeItem("is_beta");
      };
    }
  }, []);

  const getPreferencesData = async () => {
    try {
      const data = await apiFetch("/api/get-all-cities/");
      if (data.success) {
        console.log("cities data", data);
        setCities(data.cities); // Adjust according to your API response
      } else {
        //message.error(data.message || "Failed to fetch cities.");
      }
    } catch (error) {
      //message.error("An error occurred while fetching cities.");
      console.error("Error fetching cities:", error);
    }
  };

  const isMap = localStorage.getItem("isMap");

  useEffect(() => {
    if (isMap) {
      let data = localStorage.getItem("renewal-data");
      if (data) {
        getRoleOptions();
        let role = JSON.parse(data);
        if (role) {
          getRole(role?.target_role_name);
        }
      }
    }
  }, [isMap]);

  const getAllTests = async () => {
    // const res = await callGetApi(
    //   `/api/user/get-skill-renewal-dashboard/?user_id=${
    //     user?.user_id || "1111"
    //   }`
    // );
    const res = await apiFetch(
      "/api/get-skill-renewal-dashboard/",
      "POST",
      {
        company_id: current_company?.id,
      },
      {
        "Content-Type": "application/json",
        Authorization: `Token ${user?.token}`,
      }
    );
    if (res && res?.success == true) {
      let filteredData = uniqueByTargetRoleName(res);
      dispatch(storeGlobalValues({ type: "user_tests", data: filteredData }));
    }
  };

  useEffect(() => {
    if (current_company && current_company?.id) {
      getAllTests();
    }
  }, [current_company]);

  const getRole = async (role) => {
    const response = await callGetApi(`/api/target-role/names/`);
    const getData = response?.filter(
      (it) => it?.name?.toLowerCase() == role?.toLowerCase()
    );
    console.log(getData, "allRoles");
    if (getData?.length > 0) {
      let target = {
        label: getData[0]?.name,
        value: getData[0]?.id,
        sector: getData[0]?.sector,
      };
      setTarget({ ...target, tar: target });
      setStep1Verified(true);
      setNextDisabled(false);
    }
  };

  const getRoleOptions = async () => {
    const response = await callGetApi(`/api/target-role/names/`);
    dispatch(storeAllSkillTests({ type: "allRoles", data: response }));
    const formattedData = response?.map((it) => ({
      label: it.name,
      value: it.id,
      sector: it?.sector,
    }));
    setAllRoles(formattedData);
  };

  const parseDate = (dateStr) => {
    if (dateStr === "Present") {
      return null;
    }
    const dateParts = dateStr?.split("T")[0]?.split("-");
    return {
      year: parseInt(dateParts[0], 10),
      month: parseInt(dateParts[1], 10),
      day: parseInt(dateParts[2], 10),
    };
  };

  useEffect(() => {
    if (linkorfile === null || undefined || "") {
      if (!isMap) {
        setNextDisabled(true);
      }
    }
  }, [linkorfile]);

  const handleNextStep = async () => {
    try {
      if (currentStep === 1 && linkorfile !== (null || undefined)) {
        if (typeof linkorfile === "string") {
          setLoader(true);
          setLoadingFor("Retrieving your LinkedIn profile data...");
          localStorage.setItem("is_run_skill", true);
          const response = await callPostApi(
            `/api/parse/?linkedin_profile_url=${encodeURIComponent(linkorfile)}`
          );
          if (response.data) {
            const { summary, skills, experiences, education, certificates } =
              response.data;
            let exp = experiences?.map((job, index) => {
              if (job.id) {
                return job;
              }
              const newJob = {
                ...job,
                id: generateUniqueID(),
              };
              return newJob;
            });
            exp.sort((a, b) => a.id - b.id);
            const userData = {
              summary: summary,
              skills: skills,
              experiences: exp,
              education: education
                ?.map((degree) => degree?.degree_name)
                ?.filter((degree) => Boolean(degree)),
              certificates: certificates?.map(
                (certificate) => certificate.name
              ),
            };
            setAddformdata(userData);
          }
          setLoadingFor("");
          setLoader(false);
        } else {
          const cvData = new FormData();
          if (linkorfile) {
            setLoader(true);
            setLoadingFor("Retrieving your resume data...");
            cvData.append("file", linkorfile);
            localStorage.setItem("is_run_skill", true);
            const response = await callPostApi(`/api/parse/`, cvData, {
              headers: { "Content-Type": "application/pdf" },
            });

            if (response?.data) {
              const { summary, skill, experience, education, certificate } =
                response.data;
              let exp = [];
              if (experience != null) {
                exp =
                  experience.length > 0
                    ? experience.map((item) => ({
                        title: item?.title ? item?.title : "",
                        description: item?.description ? item?.description : "",
                        starts_at: item?.startDate
                          ? parseDate(item?.startDate)
                          : null,
                        ends_at: item.endDate ? parseDate(item?.endDate) : null,
                        id: generateUniqueID(),
                      }))
                    : [];
              }
              const userData = {
                summary: summary,
                skills: skill,
                experiences: exp,
                education: education,
                certificates: certificate,
              };
              setAddformdata(userData);
            }
            setLoader(false);
            setLoadingFor("");
          }
        }
        setShowNext(false);
        setActiveTab("3");
        return;
      } else {
        console.log("Please select Linkedin Profile");
        return;
      }
      setNextDisabled(true);
    } catch (error) {
      setLoadingFor("");
      setLoader(false);
      console.error("Error:", error);
    }
  };

  const getProfileData = async () => {
    try {
      const data = await apiFetch("/api/user-talent-profile/", "GET", null, {
        Authorization: `Token ${user?.token}`,
      });
      console.log(data);
      setAddformdata(data);
      setInterest(data?.interest ? data?.interest : []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getTotalSkills = async (role) => {
    const resSkills = await callGetApi(
      `/api/target-role/skill-count/?target_role_name=${encodeURIComponent(
        role || "Audit Manager"
      )}`
    );
    if (resSkills) {
      let total =
        Number(resSkills?.skills?.technical_skill_count) +
        Number(resSkills?.skills?.soft_skill_count);
      return total?.toString();
    }
    return "0";
  };

  const postjobRoleData = async (role) => {
    let count = await getTotalSkills(role);

    const getDesciption = (val) => {
      if (!allRolesDes || !val) return val;
      let des = allRolesDes.find(
        (it) => it?.name?.toLowerCase() === val?.toLowerCase()
      );
      return des ? des.description : val;
    };

    let payload = {
      id: 0,
      user_id: user?.user_id || "",
      target_role_name: role,
      skill_ids: [""],
      is_beta: false,
      is_roadmap: false,
      is_content: false,
      description: getDesciption(role),
      status: "yet to start",
      roadmap_id: "",
      total_count: count || "0",
      reached_count: "0",
    };
    console.log(payload, "payload isRole");
    let res = await callPostApi(`/api/user/skill-renewal-dashboard/`, payload);
    if (res && res !== undefined) {
      localStorage.setItem("renewal-data", JSON.stringify(res));
    }
  };

  useEffect(() => {
    if (window) {
      window?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [roadmapres]);

  const handleBack = () => {
    localStorage.removeItem("analyserData");
    localStorage.removeItem("analyserDataCopy");
    localStorage.removeItem("showlinks");
    let route = searchParams.get("pathway")
      ? "/dashboard/skill-compass/pathway"
      : "/dashboard/renewal";
    navigate(route);
  };

  //  useEffect(()=>{
  //   let data=localStorage.getItem("renewal-data");
  //   if(data){
  //     let role=JSON.parse(data);
  //     if(role){
  //       setStep1Verified(true);
  //       setCurrentStep(1)
  //       setTarget({ tar: {label:role?.target_role_name}, skill: {Job_Role:role?.target_role_name} })
  //     }
  //   }
  //  },[])

  return (
    <Card>
      <div className="card-header">
        {isView ? (
          <>
            {" "}
            <Button className="primary-border-btn" onClick={handleBack}>
              <span className="me-1">
                <DoubleLeftOutlined />
              </span>
              Back
            </Button>
            <h2 className="layout-heading mb-4">Goal Profile</h2>
          </>
        ) : (
          <h5 className="card-title mb-0">Save My Profile</h5>
        )}
      </div>
      <div className="card-body">
        <div className="home-page roadmap-layout profile-layout">
          <Row className="roadmap-row">
            <Col span={24}>
              {/* <Divider className="header" /> */}
              {loadingFor !== "" ? <Loader text={loadingFor} /> : ""}

              <ProfileTabs
                setNextDisabled={setNextDisabled}
                setShowNext={setShowNext}
                showNext={showNext}
                setLinkorfile={setLinkorfile}
                activeTab={activeTab} // Pass activeTab as a prop
                setActiveTab={setActiveTab} // Pass setActiveTab as a prop
                setAddformdata={setAddformdata} // pass Add form data
                setPerviousjobs={setPerviousjobs} // pass complete pervious job
                setAddpervjobsfields={setAddpervjobsfields} // pass complete pervious job fields
                addformdata={addformdata}
                previousjobs={previousjobs}
                addpervjobsfields={addpervjobsfields}
                target={target}
                setRoadmapres={setRoadmapres}
                setLoader={setLoader}
                uniqueSkills={uniqueSkills}
                loader={loader}
                setRoadmapLoader={setRoadmapLoader}
                setLoadingFor={setLoadingFor}
                mentorsData={mentorsData}
                cities={cities}
                setCities={setCities}
              />

              <div className="nxtbckbtn skill-btn-field">
                {isView ? (
                  <div className="back-btn">
                    {/* <Button onClick={handleBack}>Back</Button> */}
                  </div>
                ) : (
                  <>
                    {showNext && (
                      <div >
                        <Button type="secondary" onClick={handleNextStep} disabled={nextDisabled}>
                          Next
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};
export default UserGoalProfile;
