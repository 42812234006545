import { Button, Flex, Progress } from "antd";
import { useNavigate ,useHistory, useNavigation} from "react-router-dom";
import { capitalizeFirstLetter, toMessage } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { storeNextSkillRun, storeTabStatus } from "../../../store/skillRenewal";

const ExpertiseLevel = ({getData}) => {
  // const history = useHistory();
  const navigate=useNavigate();
  const dispatch = useDispatch();

  const handleProceed=(type)=>{
    if(type=="continue"){
      dispatch(storeNextSkillRun(true));
    }else{
      localStorage.removeItem("next_skill");
    }
    localStorage.removeItem("mcq_skills");
    dispatch(storeTabStatus("skills"));
    navigate("/dashboard/renewal",{replace:true});

  }

  const formatedVal=(word,type)=>{
    if(word){
      if(type=="TechnicalSkills"){
        return word?.replace("level", "")?.slice(-1);
      }else{
         return word?.charAt(0).toUpperCase() + word?.slice(1);
      }
    }
     return ""
  }

  return (
    <>
      <div className="expertise-level-wrap">
        <div className="expertise-head">
          <h2>Expertise unleashed in test : {capitalizeFirstLetter(getData?.skill_name || "Communication") }</h2>
        </div>
        <div className="expertise-box">
          <div className="skill-proficiency-level">
            <h6>Skill Proficiency</h6>
            <h3>
              Level - <span>{formatedVal(getData?.proficiency,getData?.type || getData?.skill_type)}</span>
            </h3>
            <div className="skill-progressbar">
              <Progress percent={getData?.level || 20} showInfo={false} size={[400, 40]} />
            </div>
          </div>
          <div className="skill-btn">
            <Flex gap="small">
              <Button  className="primary-btn" onClick={()=>handleProceed("continue")}>Continue Journey</Button>
              <Button onClick={()=>handleProceed("break")} className="primary-border-btn">Time for a Break</Button>
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};
export default ExpertiseLevel;
