// src/features/counterSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'global',
  initialState: {
    isLoggedIn: true,
    users:{},
    gJobRole:'',
    gSkillDes:[],
    gUserSkills:[],
    gUserTests:[]
  },
  reducers: {
    storeLoginStatus: (state,action) => {
      state.isLoggedIn=action?.payload
    },
    storeUserDetails: (state,action) => {
      state.users=action?.payload
    },
    storeGlobalRole: (state,action) => {
      state.gJobRole=action?.payload
    },
    storeGlobalSkillsDes: (state, action) => {
      state.gSkillDes = [...(state.gSkillDes || []), ...action.payload];
    },
    storeGlobalValues: (state,action) => {
      if(action?.payload?.type==="user_skills"){
        state.gUserSkills=action?.payload?.data
      }else if(action?.payload?.type==="user_tests"){
        state.gUserTests=action?.payload?.data
      }
    },
  },
});

export const { storeLoginStatus,storeUserDetails,storeGlobalRole,storeGlobalSkillsDes ,storeGlobalValues } = counterSlice.actions;

// export const selectCount = (state) => state.counter.value;

export default counterSlice.reducer;
