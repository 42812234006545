import React, { createContext, useEffect, useContext, useState } from "react";

// Create a context for managing the Google Translate state
const GoogleTranslateContext = createContext();

export const GoogleTranslateProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  // Function to load the Google Translate script
  const loadGoogleTranslateScript = () => {
    const existingScript = document.getElementById("google-translate-script");
    if (existingScript) {
      existingScript.remove(); // Remove any existing script before loading a new one
    }

    const script = document.createElement("script");    
    script.id = "google-translate-script";
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit&data-no-cookie=true";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    window.googleTranslateElementInit = () => {
      try {
        setTimeout(() => {
          new window.google.translate.TranslateElement(
            {
              pageLanguage: "en",
              includedLanguages:
                "ta,te,hi,kn,mr,bn,ar,en,zh-CN,zh-TW,nl,fr,de,id,ja,ko,ms,mn,pl,pt,es,th,vi,sw",
              gaTrack: true,
              gaId: "UA-XXXXXXXX-X",
              autoDisplay: true,
            },
            "google_translate_element"
          );
        }, 500); // Add a delay before initialization
      } catch (error) {
        console.error("Error initializing Google Translate:", error);
      }
    };

    script.onload = () => {
      setIsLoaded(true); // Ensure that the state is updated after the script loads
    };

    script.onerror = () => {
      console.error("Error loading Google Translate script.");
    };
  };

  useEffect(() => {
    if (!isLoaded) {
      loadGoogleTranslateScript();
    }

    // Handle window focus and reload the script when returning to the window
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && !isLoaded) {
        loadGoogleTranslateScript();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      // Cleanup script on unmount
      const existingScript = document.getElementById("google-translate-script");
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [isLoaded]);

  return (
    <GoogleTranslateContext.Provider value={isLoaded}>
      {children}
    </GoogleTranslateContext.Provider>
  );
};

// Hook to access the translate status
export const useGoogleTranslate = () => {
  return useContext(GoogleTranslateContext);
};
