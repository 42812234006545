import React, { useState, useEffect } from "react";
import { Row, Col, Select, Button, message } from "antd";
import { useParams } from "react-router-dom";
import apiFetch, { BASE_URL } from "../../api";
import Loader from "../../components/loader";

const { Option } = Select;

const AssessmentAssignTalentPool = ({current_company, setCurrent_company, current_type, setCurrent_type}) => {
  const user = JSON?.parse(localStorage.getItem("user"));
  const [assessmentData, setAssessmentData] = useState({});
  const [talentPoolData, setTalentPoolData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");
  const [talentPool, setTalentPool] = useState("");

  const { id } = useParams();

  const talentPoolOptions = talentPoolData.map((talentPool) => (
    <Option key={talentPool.id} value={talentPool.id}>
      {talentPool.name}
    </Option>
  ));

  useEffect(() => {
    if (id) {
      fetchAssessmentData(id);
    }
  }, [id]);

  useEffect(() => {
    if (assessmentData?.target_roles) {
      fetchTalentPoolData(assessmentData?.target_roles);
      // Set initial talent pool value
      console.log("assessmentData", assessmentData);
      setTalentPool(assessmentData?.assigned_talent_pool || "");
    }
  }, [assessmentData]);

  const fetchTalentPoolData = async (jobs) => {
    setLoading(true);
    setLoadingFor("Getting Talent Pool Data");
    try {
      const data = await apiFetch(
        `/api/get-role-talentpools/`,
        "POST",
        {
          "company_id": current_company?.id,
          "jobs": jobs,
        },
        { Authorization: `Token ${user?.token}` }
      );
      if (data.success) {
        const talentpools = data.data;
        console.log(talentpools, "talentpools");
        setTalentPoolData(talentpools);
      } else {
        console.error("Failed to retrieve talent pools:", data.message);
      }
    } catch (error) {
      console.error("Error fetching talent pools data:", error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  const fetchAssessmentData = async (assessmentId) => {
    setLoading(true);
    setLoadingFor("Getting Assessment Data");
    try {
      const data = await apiFetch(
        `/api/assessment/${assessmentId}/`,
        "GET",
        null,
        { Authorization: `Token ${user?.token}` }
      );
      if (data.success) {
        const assessment = data.data;
        console.log(assessment, "assessment");
        setAssessmentData(assessment);
        // Set the initial talent pool value based on the fetched data
        setTalentPool(assessment.assigned_talent_pool?.id || "");
      } else {
        console.error("Failed to retrieve assessment:", data.message);
      }
    } catch (error) {
      console.error("Error fetching assessment data:", error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  const assignTalentPoolToAssessment = async () => {
    if (!talentPool) {
      message.error("Please select a talent pool to assign.");
      return;
    }

    setLoading(true);
    setLoadingFor("Assigning Talent Pool");
    try {
      const data = await apiFetch(`/api/assign-talentpool/`, "POST", {
        assessment_id: id,
        talent_pool_id: talentPool
      }, { Authorization: `Token ${user?.token}` });
      if (data.success) {
        message.success("Talent pool successfully assigned to the assessment.");
      } else {
        message.error("Failed to assign talent pool: " + data.message);
      }
    } catch (error) {
      message.error("Error assigning talent pool: " + error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  return (
    <div className="mb-4">
      <div class="headingbar d-flex justify-content-between">
        <div class="title">
          <h2>Assign Talent Pool To Assessment</h2>
        </div>
      </div>
      <div className="assign-assessment">
        {assessmentData && (
          <>
            <Row className="aseessment-row">
              <Col span={4}>
                <label className="assessment-label">Assessment Name:</label>
              </Col>
              <Col span={9}>
                <label className="assessment-value">
                  {assessmentData?.name}
                </label>
              </Col>
            </Row>

            <Row className="aseessment-row">
              <Col span={4}>
                <label className="assessment-label">Domain:</label>
              </Col>
              <Col span={9}>
                <label className="assessment-value">
                  {assessmentData?.domainDetails?.name}
                </label>
              </Col>
            </Row>

            <Row className="aseessment-row">
              <Col span={4}>
                <label className="assessment-label">Jobs:</label>
              </Col>
              <Col span={9}>
                <ul class="assessment-list">
                  {assessmentData.jobDetails &&
                    assessmentData.jobDetails.length > 0 &&
                    assessmentData.jobDetails.map((job) => (
                      <li key={job?.id}>{job?.name}</li>
                    ))}
                </ul>
              </Col>
            </Row>

            <Row className="aseessment-row">
              <Col span={4}>
                <label className="assessment-label">Skills:</label>
              </Col>
              <Col span={9} className="assessment-skills">
                <ul class="skill-list">
                  {assessmentData.skills &&
                    assessmentData.skills.length > 0 &&
                    assessmentData.skills
                      .filter((skill) => skill.status === true)
                      .map((skill) => <li key={skill?.id}>{skill?.name}</li>)}
                </ul>
              </Col>
            </Row>

            <Row className="aseessment-row">
              <Col span={4}>
                <label className="assessment-label">Talent Pools:</label>
              </Col>
              <Col span={8}>
                <Select
                  showSearch={true}
                  value={talentPool}
                  onChange={setTalentPool}
                  placeholder="Select Talent Pool"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {talentPoolOptions}
                </Select>
              </Col>
            </Row>

            <Row className="aseessment-row">
              <Col span={13} style={{ textAlign: "right", marginTop: 20 }}>
                <Button
                  type="primary"
                  onClick={assignTalentPoolToAssessment}
                  disabled={loading}
                >
                  Assign
                </Button>
              </Col>
            </Row>
          </>
        )}
        {loading && <Loader text={loadingFor} />}
      </div>
    </div>
  );
};

export default AssessmentAssignTalentPool;
