import React, { useEffect, useState } from "react";
import { Row, Timeline, Col } from "antd";
import apiFetch from "../../api";
const UserRoadmap = ({ skillGapAnalysis, showLinks, roadmapId }) => {
  console.log("sprints", skillGapAnalysis);
  const userLo = localStorage.getItem("user");
  const user = userLo ? JSON.parse(localStorage.getItem("user")) : null;
  const [newSprints, setNewSprints] = useState({}); // Change to object
  const [mode, setMode] = useState("");

  useEffect(() => {
    if (roadmapId) {
      getNewSprint();
    }
  }, [roadmapId]);

  const getNewSprint = async () => {
    const saveNewContentPlan = await apiFetch(
      `/api/new-sprint/`,
      "POST",
      {
        roadmap_id: roadmapId,
      },
      {
        "Content-Type": "application/json",
        Authorization: `Token ${user?.token}`,
      }
    );
    if (saveNewContentPlan && saveNewContentPlan.data) {
      setNewSprints(saveNewContentPlan.data);
      setMode(saveNewContentPlan.mode);
    }
  };

  return (
    <div className="roadmap-skill">
      <Row>
        <Col span={24}>
          <h5 className="roadmap-description"></h5>
          <div className="roadmap-line">
            <Timeline mode="left">
              {/* Rendering new sprints if available */}

              {mode === "" ? (
                <>
                  {Array.isArray(skillGapAnalysis) &&
                    skillGapAnalysis?.map((item, index) => {
                      // console.log("inside");
                      const firstKey = Object.keys(item)[0];
                      let displaceKey = "";
                      if (item[firstKey]?.length == 0) {
                        return <></>;
                      }
                      if (
                        firstKey !== "Mentorships" &&
                        firstKey !== "Reminder" &&
                        firstKey !== "Mentor_OG_Images"
                      ) {
                        if (firstKey === "Solidify_Fundamentals") {
                          displaceKey = "Solidify Fundamentals";
                        }
                        if (firstKey === "Expand_Horizons") {
                          displaceKey = "Expand Horizons";
                        }
                        if (firstKey === "Practice_Makes_Perfect") {
                          displaceKey = "Practice Makes Perfect";
                        }
                        if (firstKey === "Certifications") {
                          displaceKey = "Certifications";
                        }
                        {
                          console.log(
                            "skillGapAnalysis",
                            item[firstKey],
                            firstKey,
                            item,
                            skillGapAnalysis
                          );
                        }
                        return (
                          <Timeline.Item color="#3F3EED" key={index}>
                            <h4>
                              {displaceKey.length > 1 ? displaceKey : firstKey}
                            </h4>
                            <ul className="roadmapListing">
                              {firstKey !== "Mentorships" &&
                                typeof firstKey === "string" &&
                                Array.isArray(item[firstKey]) &&
                                item[firstKey].length > 0 &&
                                item[firstKey]?.map((subItem, index) => {
                                  return (
                                    <li key={index}>
                                      <div className="timelineInfo">
                                        {subItem.Topic ? (
                                          <h5>{subItem.Topic}</h5>
                                        ) : (
                                          <h5>{subItem.topic}</h5>
                                        )}
                                        {subItem.Description ? (
                                          <h6>{subItem.Description}</h6>
                                        ) : (
                                          <h6>{subItem.description}</h6>
                                        )}
                                        {showLinks ? (
                                          <ul>
                                            {subItem.links?.map(
                                              (item, index) => (
                                                <li key={index}>
                                                  <a
                                                    target="_blank"
                                                    href={item}
                                                    rel="noreferrer"
                                                  >
                                                    {item}
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        ) : null}
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Timeline.Item>
                        );
                      }
                    })}
                </>
              ) : (
                <>
                  {Object.keys(newSprints).length > 0 &&
                    Object.entries(newSprints).map(
                      ([category, courses], categoryIndex) => {
                        if (Array.isArray(courses) && courses.length > 0) {
                          return (
                            <Timeline.Item
                              color="#3F3EED"
                              key={`new-${categoryIndex}`}
                            >
                              <h4>{category.replace(/_/g, " ")}</h4>
                              <ul className="roadmapListing">
                                {courses.map((course, courseIndex) => (
                                  <li key={`${category}-${courseIndex}`}>
                                    <div className="timelineInfo">
                                      {mode === "RAG" ? (
                                        <>                                          
                                          <h5>{course.title}</h5>
                                          <h6>
                                           {course.description}
                                          </h6>
                                          <ul>
                                            <li key={`url-${courseIndex}`}>
                                              <a
                                                target="_blank"
                                                href={course.url}
                                                rel="noreferrer"
                                              >
                                                {course.url}
                                              </a>
                                            </li>
                                          </ul>
                                        </>
                                      ) : (
                                        <>
                                          <ul>
                                            <li key={`url-${courseIndex}`}>
                                              <a
                                                target="_blank"
                                                href={course}
                                                rel="noreferrer"
                                              >
                                                {course}
                                              </a>
                                            </li>
                                          </ul>
                                        </>
                                      )}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </Timeline.Item>
                          );
                        }
                        return null; // Don't render empty categories
                      }
                    )}
                </>
              )}
            </Timeline>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default UserRoadmap;
