
import DashboardHead from "../../common/dashboard-head";

import JobRoleCalculationList from "./job-role-calculation-value";
const DashboardExpert = ({rolesBackup}) => {
  return (
    <>
      <DashboardHead pageTitle="Dashboard" subPageTitle="Domain Expert" showMenu={true}/>
     
      
    </>
  );
};
export default DashboardExpert;
