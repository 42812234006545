import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import StarIcon from "../../assets/images/star-icon.png";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import {
  getCoreMap,
  getCoreSingleMap,
  getTecMap,
  getTecSingleMap,
  removeDuplicateSkills,
  uniqueSkillName,
} from "../../dashboard/utils";

const proficiencyCustomTooltip = () => {
  return (
    <>
      <div className="custom-toolip">
        <div className="tooltip-desc mb-2">
          <h2 className="d-flex align-items-center">
            Core Skills
            <span className="ms-3 skill-view-field d-flex align-items-center">
              <span className="skill-box advance">B</span>
              <span className="skill-box advance">I</span>
              <span className="skill-box Intermediate">A</span>
            </span>
          </h2>
          <p>
            Measured on a scale of <b>B</b>asic, <b>I</b>ntermediate, and{" "}
            <b>A</b>dvanced, indicating the level of understanding and ability
            to apply these core skills.
          </p>
        </div>
        <div className="tooltip-desc  mb-2">
          <h2 className="d-flex align-items-center">
            Technical Skills
            <span className="ms-3 skill-view-field d-flex align-items-center">
              <span className="skill-box fill-skill">1</span>
              <span className="skill-box fill-skill">2</span>
              <span className="skill-box fill-skill">3</span>
              <span className="skill-box progress-skill">4</span>
              <span className="skill-box progress-skill">5</span>
              <span className="skill-box progress-skill">6</span>
            </span>
          </h2>
          <p>
            Measured on a scale of 1 to 6. Higher numbers represent a greater
            level of proficiency and expertise.
          </p>
        </div>
        <div className="tooltip-Proficiency">
          <div className="Proficiency-lavel">
            <div className="prf-level">
              <span className="current-level"></span>
              <span>Current Proficiency</span>
            </div>
            <div className="prf-level">
              <span className="Expected-level"></span>
              <span>Expected Proficiency</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const UserSkill = ({
  existingSkills,
  userAllPreDetils,
  updateCurrentSkill,
}) => {
  let isBeta = localStorage.getItem("is_beta");
  const [currentSkills, setCurrentSkills] = useState([]);
  const [skillGaps, setSkillGaps] = useState([]);
  const [coreChartHeight, setCoreChartHeight] = useState("400");
  const [techChartHeight, setTechChartHeight] = useState("400");
  const [coreSkillsChartData, setCoreSkillsChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false, // Hide the toolbar if not needed
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
          barHeight: "15", // Adjust as needed for consistent width
        },
      },
      tooltip: {
        y: {
          formatter: (val) => {
            const levels = ["Basic", "Intermediate", "Advanced"];
            return levels[val - 1] || "Unknown"; // Convert index to proficiency level
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: (val) => {
            const levels = ["Basic", "Intermediate", "Advanced"];
            return levels[val - 1] || "0"; // Convert index to proficiency level
          },
        },
        tickAmount: 3, // Limit number of ticks to show on x-axis
        min: 0, // Set minimum value for the x-axis
        max: 3, // Set maximum value for the x-axis
      },
      dataLabels: {
        enabled: true,
        offsetX: -45,
        formatter: (val) => {
          const levels = ["Basic", "Intermediate", "Advanced"];
          return levels[val - 1] || ""; // Convert index to proficiency level
        },
      },
      yaxis: {
        title: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false, // Remove grid lines
      },
      stroke: {
        show: false, // Remove borders from bars
      },
    },
  });

  // State for TechnicalSkills chart
  const [techSkillsChartData, setTechSkillsChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false, // Hide the toolbar if not needed
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
          barHeight: "15", // Adjust as needed for consistent width
        },
      },
      xaxis: {
        categories: [],
        tickAmount: 6, // Limit number of ticks to show on x-axis
        min: 0, // Set minimum value for the x-axis
        max: 6, // Set maximum value for the x-axis
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
      },
      yaxis: {
        title: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false, // Remove grid lines
      },
      stroke: {
        show: false, // Remove borders from bars
      },
    },
  });

  useEffect(() => {
    let CoreSkills = [];
    let TechnicalSkills = [];
    if (userAllPreDetils?.length > 0 && updateCurrentSkill) {
      userAllPreDetils.forEach((it) => {
        const skill = {
          skillName: it?.skill,
          skillProficiencies: it?.proficiency,
        };
        if (it?.type === "ccs") {
          CoreSkills.push(skill);
        } else {
          TechnicalSkills.push(skill);
        }
      });
    }

    if (existingSkills && Array.isArray(existingSkills[0])) {
      setCurrentSkills(
        updateSkills(existingSkills[0][0], CoreSkills, TechnicalSkills)
      );
    } else {
      setCurrentSkills(
        updateSkills(existingSkills[0], CoreSkills, TechnicalSkills)
      );
    }
    if (existingSkills && Array.isArray(existingSkills[1])) {
      setSkillGaps(existingSkills[1][0]);
    } else {
      setSkillGaps(existingSkills[1]);
    }
    console.log(
      existingSkills,
      userAllPreDetils,
      updateCurrentSkill,
      "existingSkills, userAllPreDetils,updateCurrentSkill"
    );
  }, [existingSkills, userAllPreDetils, updateCurrentSkill]);

  // const addNewSkills = (skills, core, tech) => {
  //   const data = skills.map((it) => {
  //     let skill = { skillName: it?.skill, skillProficiencies: it?.proficiency };
  //     if (it?.type == "ccs") {
  //       core.push(skill);
  //     } else {
  //       tech.push(skill);
  //     }
  //   });
  // };

  const updateSkills = (skills, CoreSkills, TechnicalSkills) => {
    if (skills) {
      skills.CoreSkills = [...CoreSkills, ...(skills.CoreSkills || [])];
      skills.TechnicalSkills = [
        ...TechnicalSkills,
        ...(skills.TechnicalSkills || []),
      ];
    }

    let uniqueSkills = {
      CoreSkills: removeDuplicateSkills(skills?.CoreSkills),
      TechnicalSkills: removeDuplicateSkills(skills?.TechnicalSkills),
    };

    return uniqueSkills;
  };

  const { gSkillDes } = useSelector((state) => state?.counterSlice);

  const getSkillDes = (name) => {
    if (name) {
      const des = gSkillDes?.filter(
        (it) => it?.name?.toLowerCase() == name?.toLowerCase()
      );
      if (des?.length > 0) {
        return des?.[0]?.description || name;
      }
    }

    return "";
  };

  const CoreProfToNumber = (prof) => {
    // Convert the skill to lowercase for comparison

    let ls = prof?.toLowerCase();

    // Define the proficiency levels in an array
    let arr = ["basic", "intermediate", "advanced"];

    // Use findIndex to get the index of the matching proficiency level
    let index = arr.findIndex((it) => ls === it);

    // Return index + 1 for 1-based result or 0 if not found
    return index >= 0 ? index + 1 : 0; // Returns 0 if skill is not found
  };

  useEffect(() => {
    if (skillGaps && skillGaps?.CoreSkills) {
      const coreSkills = skillGaps.CoreSkills;
      const coreSkillNames = [];
      const coreRequiredProficiencies = [];
      const coreCurrentProficiencies = [];

      coreSkills.forEach((skill) => {
        const cp = getCoreSkillMap(skill, "ccs");
        const rp = skill.skillProficiencies;

        const requiredProficiency = CoreProfToNumber(rp) || 0;
        const currentProficiency = CoreProfToNumber(cp) || 0;
        coreSkillNames.push(skill.skillName);
        coreRequiredProficiencies.push(Number(requiredProficiency) || 0);
        coreCurrentProficiencies.push(Number(currentProficiency) || 0);
      });

      const numberOfCoreSkills = coreSkills.length; // Number of core skills
      setCoreChartHeight(numberOfCoreSkills * 40 + 100); // Calculate height based on skills
      setCoreSkillsChartData((prevData) => ({
        ...prevData,
        series: [
          {
            name: "Required Proficiency",
            data: coreRequiredProficiencies,
          },
          {
            name: "Current Proficiency",
            data: coreCurrentProficiencies,
          },
        ],
        options: {
          ...prevData.options,
          xaxis: {
            categories: coreSkillNames,
          },
        },
      }));
      // Extract Technical Skills
      const techSkills = skillGaps?.TechnicalSkills;
      const techSkillNames = [];
      const techRequiredProficiencies = [];
      const techCurrentProficiencies = [];

      techSkills.forEach((skill) => {
        const currentProficiency = getCoreSkillMap(skill, "tech") || 0;
        const requiredProficiency = skill.skillProficiencies;

        techSkillNames.push(skill.skillName);
        techRequiredProficiencies.push(Number(requiredProficiency) || 0);
        techCurrentProficiencies.push(Number(currentProficiency) || 0);
      });

      const numberOfTechSkills = techSkills.length; // Number of technical skills
      setTechChartHeight(numberOfTechSkills * 40 + 100); // Calculate height based on skills

      setTechSkillsChartData((prevData) => ({
        ...prevData,
        series: [
          {
            name: "Required Proficiency",
            data: techRequiredProficiencies,
          },
          {
            name: "Current Proficiency",
            data: techCurrentProficiencies,
          },
        ],
        options: {
          ...prevData.options,
          xaxis: {
            categories: techSkillNames,
          },
        },
      }));
    }
  }, [skillGaps, currentSkills]);

  const getCoreSkillMap = (skill, type) => {
    if (type === "ccs") {
      let coreSkills = currentSkills?.CoreSkills;
      let skillProf = coreSkills?.find(
        (it) => it?.skillName?.toLowerCase() === skill?.skillName?.toLowerCase()
      )?.skillProficiencies;

      return skillProf;
    } else {
      let techSkills = currentSkills?.TechnicalSkills;
      let skillProf = techSkills?.find(
        (it) => it?.skillName === skill?.skillName
      )?.skillProficiencies;
      return skillProf;
    }
  };

  return (
    <div className="current-skill">
      <Row>
        <Col span={24}>
          <Card>
            <div className="current-skill-box">
              <div className="timelineInfo">
                <div className="skill_info">
                  <h2 className="data-list bhariFont d-flex align-items-center">
                    Skill Gap Analysis
                    {isBeta && (
                      <span className="ms-2 d-flex align-items-center">
                        <span>
                          <img src={StarIcon} alt="icon" />
                        </span>{" "}
                        <span className="px-2 d-flex align-items-center">
                          {" "}
                          <Tooltip
                            title="Validation by domain expert in progress"
                            placement="right"
                          >
                            <InfoCircleFilled
                              style={{ fontSize: "20px", color: "#B4B4B4" }}
                            />
                          </Tooltip>
                        </span>
                      </span>
                    )}
                  </h2>
                </div>
                <div className="skill-list technical-skill-list mt-4">
                  <h3 className="h3-subtitle d-flex align-items-center">
                    Core Skills{" "}
                  </h3>
                  {/* start current  */}
                  <ReactApexChart
                    options={coreSkillsChartData.options}
                    series={coreSkillsChartData.series}
                    type="bar"
                    height={coreChartHeight}
                  />
                </div>
                <div className="skill-list technical-skill-list mt-4">
                  <h3 className="h3-subtitle d-flex align-items-center">
                    Technical Skills{" "}
                  </h3>
                  {/* start current  */}
                  <ReactApexChart
                    options={techSkillsChartData.options}
                    series={techSkillsChartData.series}
                    type="bar"
                    height={techChartHeight}
                  />
                </div>

                {/* end current  */}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default UserSkill;
