import { Button, Table } from "react-bootstrap";
import {
  jobRoleList,
  jobTableColumn,
  dashboardSortFilterOption,
} from "../../../../constant";
import SearchBar from "../../common/searchbar";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  storeAllDomains,
  storeRoleCount,
  storeSelValues,
  storeTestStatus,
} from "../../../../store/domainExpert";
import { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

const DashboardJobListTable = ({ rolesBackup, tabStatus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sorting, setSorting] = useState("");

  const { allMainJobRole } = useSelector((state) => state?.domainExpert);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.trim().toLowerCase();
    const searchData = rolesBackup?.filter(
      (data) =>
        data?.name?.toLowerCase().includes(searchTerm) ||
        data?.Sector?.toLowerCase().includes(searchTerm) ||
        data?.Track?.toLowerCase().includes(searchTerm)
    );
    dispatch(storeAllDomains({ type: "main-role", data: searchData }));
    // if(sorting){
    //   console.log(sorting,"sorting")
    //   handleSort(sorting);
    // }
    // const countCertified = searchData?.reduce((count, item) => {
    //   if (item?.status?.toLowerCase() === "certified") {
    //     return count + 1;
    //   }
    //   return count;
    // }, 0);
    // dispatch(storeRoleCount({ total: searchData?.length, certified: countCertified, non_certified: searchData?.length-countCertified }));
  };

  const handleSort = (selectedOption) => {
    setSorting(selectedOption);
    let sortedData = [];
    switch (selectedOption.value) {
      case "Name":
        sortedData = [...allMainJobRole].sort((a, b) =>
          a?.name?.localeCompare(b?.name)
        );
        break;
      case "Sector":
        sortedData = [...allMainJobRole].sort((a, b) =>
          a?.Sector?.localeCompare(b?.Sector)
        );
        break;
      case "Track":
        sortedData = [...allMainJobRole].sort((a, b) =>
          a?.Track?.localeCompare(b?.Track)
        );
        break;
      default:
        break;
    }

    dispatch(storeAllDomains({ type: "main-role", data: sortedData }));
  };

  const handleStart = (type, val) => {
    dispatch(storeTestStatus("steps"));
    dispatch(
      storeSelValues({
        type: "role",
        value: { label: val?.target_role_name, value: val?.target_role_name },
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="job-list-wrapper">
        <div className="job-list-heading">
          <div className="head">
            <h4>All Job Roles</h4>
            <p>Certified & In-Progress Job Roles</p>
          </div>
          <div className="job-search d-flex align-items-center">
            <SearchBar handleSearch={handleSearch} />
            <div className="sort-filter-field ms-3 d-flex align-items-center">
              <span>Sort By:</span>
              <Select
                className="filter-option-list"
                classNamePrefix="select"
                // defaultValue={dashboardSortFilterOption}
                options={dashboardSortFilterOption}
                onChange={handleSort}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        {allMainJobRole?.length > 0 ? (
          <div className="job-list-table">
            <Table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Job Role</th>
                  <th>Sector</th>
                  <th>Track</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {allMainJobRole?.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {" "}
                      <Tooltip title={data?.name} placement="right">
                        {data?.name?.length > 36
                          ? data?.name?.substr(0, 36) + "..."
                          : data?.name}
                      </Tooltip>
                    </td>
                    <td>{data.Sector || ""}</td>
                    <td>{data.Track || ""}</td>
                    <td>
                      <Button variant="secondary"> View</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <h3 style={{ textAlignLast: "center", marginTop: 50 }}>
            No job role found
          </h3>
        )}
      </div>
    </>
  );
};
export default DashboardJobListTable;
