import { Button, Table } from "react-bootstrap";
import {
  jobRoleList,
  jobTableColumn,
  sortFilterOption,
} from "../../../../constant";
import SearchBar from "../../common/searchbar";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  storeAllDomains,
  storeRoleCount,
  storeSelValues,
  storeTestStatus,
} from "../../../../store/domainExpert";
import { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

const JobListTable = ({ rolesBackup, tabStatus }) => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [sorting, setSorting] = useState("");

  const { allJobRole } = useSelector((state) => state?.domainExpert);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.trim().toLowerCase();
    const searchData = rolesBackup?.filter((data) =>
      data?.target_role_name?.toLowerCase().includes(searchTerm)
    );
    dispatch(storeAllDomains({ type: "role", data: searchData }));
    // if(sorting){
    //   console.log(sorting,"sorting")
    //   handleSort(sorting);
    // }
    // const countCertified = searchData?.reduce((count, item) => {
    //   if (item?.status?.toLowerCase() === "certified") {
    //     return count + 1;
    //   }
    //   return count;
    // }, 0);
    // dispatch(storeRoleCount({ total: searchData?.length, certified: countCertified, non_certified: searchData?.length-countCertified }));
  };

  const handleSort = (selectedOption) => {
    setSorting(selectedOption);
    let sortedData = [];
    switch (selectedOption.value) {
      case "Alphabetic":
        sortedData = [...allJobRole].sort((a, b) =>
          a?.target_role_name?.localeCompare(b?.target_role_name)
        );
        break;
      case "Date":
        sortedData = [...allJobRole].sort((a, b) => {
          // Convert dates to Date objects
          const dateA = a?.date
            ? new Date(a.date.split("/").reverse().join("/"))
            : null;
          const dateB = b?.date
            ? new Date(b.date.split("/").reverse().join("/"))
            : null;

          if (!dateA && !dateB) {
            return 0;
          } else if (!dateA) {
            return 1;
          } else if (!dateB) {
            return -1;
          } else {
            return dateB - dateA;
          }
        });
        break;
      default:
        break;
    }
    dispatch(storeAllDomains({ type: "role", data: sortedData }));
  };

  const handleStart = (type, val) => {

    dispatch(storeTestStatus("steps"));    
    dispatch(
      storeSelValues({
        type: "role",
        value: { label: val?.target_role_name, value: val?.target_role_name },
      })
    ); 
  };
  
  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="job-list-wrapper">
        <div className="job-list-heading">
          <div className="head">
            <h4>All Job Roles</h4>
            <p>Certified & In-Progress Job Roles</p>
          </div>
          <div className="job-search d-flex align-items-center">
            <SearchBar handleSearch={handleSearch} />
            <div className="sort-filter-field ms-3 d-flex align-items-center">
              <span>Sort By:</span>
              <Select
                className="filter-option-list"
                classNamePrefix="select"
                // defaultValue={sortFilterOption}
                options={sortFilterOption}
                onChange={handleSort}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        {allJobRole?.length > 0 ? (
          <div className="job-list-table">
            <Table>
              <thead>
                <tr>
                  {jobTableColumn.map((column, i) => (
                    <th key={i} style={{ width: column.width }}>
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {allJobRole?.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {" "}
                      <Tooltip title={data?.target_role_name} placement="right">
                        {data?.target_role_name?.length > 36
                          ? data?.target_role_name?.substr(0, 36) + "..."
                          : data?.target_role_name}
                      </Tooltip>
                    </td>
                    <td>{data.total_question || 0}</td>
                    <td>{data.date || "--"}</td>
                    <td>
                      <span className="status">
                        {data?.status || "In_Progress"}
                      </span>
                    </td>
                    <td>
                      {data?.status?.toLowerCase() === "certified" ? (
                        <Button
                          onClick={() => handleStart("continue", data)}
                          className="primary-btn"
                        >
                          Continue
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleStart("start", data)}
                          className="primary-btn"
                        >
                          Start
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <h3 style={{ textAlignLast: "center", marginTop: 50 }}>
            No job role found
          </h3>
        )}
      </div>
    </>
  );
};
export default JobListTable;
