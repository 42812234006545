import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Table,
  InputNumber,
  Button,
  Input,
  message,
  Switch,
} from "antd";
import { useParams } from "react-router-dom";
import apiFetch, { BASE_URL } from "../../api";
import Loader from "../../components/loader";
import { Card } from "react-bootstrap";

const { Option } = Select;

const AssessmentCreate = () => {
  const { id } = useParams();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const [form] = Form.useForm();
  const [assessmentName, setAssessmentName] = useState("");
  const [domains, setDomains] = useState([]);
  const [defaultDomain, setDefaultDomain] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [defaultJobs, setDefaultJobs] = useState([]);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");

  let user = JSON?.parse(localStorage.getItem("user"));

  const validateWhitespace = (_, value) => {
    if (value && /^\s+$/.test(value)) {
      return Promise.reject(new Error("Input cannot contain only spaces"));
    }
    return Promise.resolve();
  };

  const handleJobChange = (selectedJobs) => {
    setDefaultJobs(selectedJobs);
    GetSkillsOfTargetRoles(selectedJobs);
  };

  const GetSkillsOfTargetRoles = async (selectedJobs) => {
    try {
      const data = await apiFetch(
        `/api/get-roles-skills/`,
        "POST",
        { roles: selectedJobs.map((job) => job) },
        { Authorization: `Token ${user?.token}` }
      );
      if (data.success) {
        setSkills(data.data);
      } else {
        console.error("Failed to retrieve skills:", data.message);
      }
    } catch (error) {
      console.error("Error fetching skills:", error.message);
    }
  };

  const domainOptions = domains.map((domain) => (
    <Option key={domain.id} value={domain.id}>
      {domain.name}
    </Option>
  ));

  const jobOptions = jobs.map((job) => (
    <Option key={job.id} value={job.id}>
      {job.name}
    </Option>
  ));

  useEffect(() => {
    getDomains();
  }, []);

  useEffect(() => {
    if (id) {
      if (domains.length > 0) {
        fetchAssessmentData(id);
      }
    }
  }, [id, domains]);

  useEffect(() => {
    // Reset form and state when no id is present (navigating to create new assessment)
    if (!id) {
      setAssessmentName("");
      setDefaultDomain(null);
      setDefaultJobs([]);
      setJobs([]);
      setSkills([]);
      form.resetFields();
    }
  }, [id, form]);

  useEffect(() => {
    if (defaultDomain) {
      form.setFieldsValue({ domain: defaultDomain.id });
    }
    form.setFieldsValue({ jobs: defaultJobs });
  }, [defaultDomain, defaultJobs, form]);

  const getDomains = async () => {
    try {
      const data = await apiFetch(`/api/get-expert-domains/`, "GET", null, {
        Authorization: `Token ${user?.token}`,
      });
      if (data.success) {
        setDomains(data.data);
      } else {
        console.error("Failed to retrieve domains:", data.message);
      }
    } catch (error) {
      console.error("Error fetching domains:", error.message);
    }
  };

  const fetchAssessmentData = async (assessmentId) => {
    setLoading(true);
    setLoadingFor("Getting Assesment Data");
    try {
      const data = await apiFetch(
        `/api/assessment/${assessmentId}/`,
        "GET",
        null,
        { Authorization: `Token ${user?.token}` }
      );
      if (data.success) {
        const assessment = data.data;
        setAssessmentName(assessment.name);
        const myDomain = domains.find(
          (domain) => domain.id === assessment.domain
        );
        if (myDomain) {
          setDefaultDomain(myDomain);
          await getDomainJobs(myDomain.name);
          setDefaultJobs(assessment.target_roles);
        }
        const updatedSkills = assessment.skills.map(skill => ({
          ...skill, // Spread the existing skill properties
          proficiency: skill.assesment_proficiency 
        }));
        
        // Set the updated skills
        setSkills(updatedSkills);

        form.setFieldsValue({
          name: assessment.name,
          domain: assessment.domain.id,
          jobs: assessment.jobs,
          defaultJobs: defaultJobs,
        });
      } else {
        console.error("Failed to retrieve assessment:", data.message);
      }
    } catch (error) {
      console.error("Error fetching assessment data:", error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    if (window) {
      window.scrollTo({ top: 200, behavior: "smooth" });
    }
  };

  const handleDomainChange = (selectedOption) => {
    setDefaultJobs([]);
    setSkills([]);
    const selectedDomain = domains.find(
      (domain) => domain.id === selectedOption
    );
    if (selectedDomain) {
      setDefaultDomain(selectedDomain);
      getDomainJobs(selectedDomain.name);
    } else {
      console.error("Selected domain not found.");
    }
  };

  const getDomainJobs = async (sector) => {
    try {
      const data = await apiFetch(
        `/api/get-sector-roles/`,
        "POST",
        { sector },
        { Authorization: `Token ${user?.token}` }
      );
      if (data.success) {
        setJobs(data.data);
        return data.data;
      } else {
        console.error("Failed to retrieve roles:", data.message);
      }
    } catch (error) {
      console.error("Error fetching roles:", error.message);
    }
  };

  const submitForm = async () => {
    setLoading(true);
    setLoadingFor("Saving Assesment");
    try {
      const values = await form.validateFields();
      const assessmentData = {
        name: assessmentName,
        domain: defaultDomain.id,
        jobs: defaultJobs,
        skills: skills.map((skill) => ({
          id: skill.id,
          proficiency: skill.proficiency,
          status: skill.status,
        })),
      };

      const url = id
        ? `/api/update-assessment/${id}/`
        : `/api/save-assessment/`;
      const method = id ? `PUT` : `POST`;

      const data = await apiFetch(
        url,
        method,
        assessmentData,
        { Authorization: `Token ${user?.token}` }
      );
      if (data.success) {
        message.success("Assessment saved successfully");
      } else {
        message.error("Failed to save assessment:" + data.message);
      }
    } catch (error) {
      message.error("Failed to save assessment:" + error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  const handleProficiencyChange = (value, skillId) => {
    setSkills((prevSkills) =>
      prevSkills.map((skill) =>
        skill.id === skillId ? { ...skill, proficiency: value } : skill
      )
    );
  };

  const handleStatusChange = (checked, id) => {
    const updatedData = skills.map((skill) => {
      if (skill.id === id) {
        return { ...skill, status: checked };
      }
      return skill;
    });
    setSkills(updatedData);
  };

  const proficiencyLabels = {
    1: "Basic",
    2: "Intermediate",
    3: "Advanced",
  };

  const columns = [
    {
      title: "Status",
      key: "status",
      render: (text, record) => (
        <Switch
          checked={record.status}
          onChange={(checked) => handleStatusChange(checked, record.id)}
        />
      ),
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: "Skill Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Skill Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Proficiency",
      key: "proficiency",
      render: (text, record) =>
        record.type === "ccs" ? (
          <div>
            <InputNumber
              min={1}
              max={3}
              value={record.proficiency}
              onChange={(value) => handleProficiencyChange(value, record.id)}
              formatter={(value) => proficiencyLabels[value]}
              parser={(value) => {
                if (value === "Basic") return 1;
                if (value === "Intermediate") return 2;
                if (value === "Advanced") return 3;
                return value;
              }}
            />
          </div>
        ) : (
          <InputNumber
            min={1}
            max={record.max_proficiency}
            value={record.proficiency}
            onChange={(value) => handleProficiencyChange(value, record.id)}
          />
        ),
      sorter: (a, b) => a.proficiency - b.proficiency,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
    },
  ];

  return (
    <>
      
        <Card className="mb-0">
          <div className="card-header">
            {loading ? <Loader text={loadingFor} /> : ""}
            <div className="assessment-form">
              <div className="headingbar d-flex justify-content-between">
                <div className="title d-flex justify-content-between align-items-end w-100">
                  <h5 className="card-title mb-0">{id ? "Update Assessment" : "Create Assessment"}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <Form
              // {...formItemLayout}
              form={form}
              id="customform"
              onFinish={submitForm}
              onFinishFailed={onFinishFailed}
              variant="filled"
              layout="vertical"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Assessment Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please input assessment name!" },
                      { validator: validateWhitespace },
                    ]}
                  >
                    <Input
                      value={assessmentName}
                      onChange={(e) => setAssessmentName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <span>
                        Domain <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="domain"
                    rules={[
                      { required: true, message: "Please select the domain!" },
                      { validator: validateWhitespace },
                    ]}
                  >
                    <Select
                      showSearch={true}
                      value={defaultDomain ? defaultDomain.id : undefined}
                      onChange={handleDomainChange}
                      placeholder="Select Domain"
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {domainOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <span>
                        Jobs <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="jobs"
                    rules={[
                      { required: true, message: "Please select the jobs!" },
                      { validator: validateWhitespace },
                    ]}
                  >
                    <Select
                      showSearch={true}
                      mode="tags"
                      defaultValue={defaultJobs}
                      onChange={handleJobChange}
                      placeholder="Select Jobs"
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {jobOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              
              <Table
                columns={columns}
                dataSource={skills}
                rowKey={(record) => record.id}
                pagination={{
                  pageSizeOptions: ['10', '20', '50', '100'],
                  defaultPageSize: 50,
                  showSizeChanger: true,
                  total: skills.length, 
                  showTotal: (total) => `Total ${total} Skills`,
                }}
              />
              <Row justify="end" style={{ marginTop: 20 }}>
                <Button type="secondary" htmlType="submit">
                  {id ? "Update Assessment" : "Save Assessment"}
                </Button>
              </Row>
            </Form>
          </div>
        </Card>
    
    </>
  );
};

export default AssessmentCreate;
