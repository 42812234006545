import React, { useEffect, useState } from "react";
import apiFetch, { BASE_URL } from "../api";
import { Row, Col, Input, Select, Button } from "antd";
import { uniqueSkillName } from "../dashboard/utils";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import Loader from "./loader";

const UserTalentProfile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [talentProfileData, setTalentProfileData] = useState([]);
  const [interest, setInterest] = useState([]);
  const [summary, setSummary] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const [allProfSkills, setAllProfSkills] = useState([]);
  const [allCurrentSkills, setAllCurrentSkills] = useState([]);
  const [totalExp, setTotalExp] = useState(null);
  const [previousjobs, setPreviousjobs] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { Option } = Select;

  useEffect(() => {
    if (previousjobs?.length > 0) {
      // console.log(previousjobs, `years ASDHKASGDAS months`);
      let exp = getTotalMonths(previousjobs);

      let totalYears = Math.floor(exp / 12);
      let remainingMonths = exp % 12;

      totalYears = isNaN(totalYears) ? 0 : totalYears;
      remainingMonths = isNaN(remainingMonths) ? 0 : remainingMonths;

      setTotalExp(`${totalYears} years and ${remainingMonths} months`);
    }
  }, [previousjobs]);

  useEffect(() => {
    if (experiences?.length > 0) {
      let updatedData = experiences?.map((it) => ({
        ...it,
        iscurrentjob: it.iscurrentjob || !it.ends_at ? true : false,
        starts_at: convertNaNToNull(it.starts_at),
        ends_at: convertNaNToNull(it.ends_at),
      }));
      setPreviousjobs(updatedData);
    }
  }, [experiences]);

  const convertNaNToNull = (date) => {
    if (isNaN(date?.year) || isNaN(date?.month) || isNaN(date?.day)) {
      return null;
    }
    return date;
  };

  useEffect(() => {
    getPreferencesData();
    getProfileData();
  }, []);

  const getPreferencesData = async () => {
    try {
      setIsLoading(true);
      const data = await apiFetch("/api/get-all-cities/");

      if (data.success) {
        console.log("cities data", data);
        setCities(data.cities); // Adjust according to your API response
      } else {
        //message.error(data.message || "Failed to fetch cities.");
      }
    } catch (error) {
      //message.error("An error occurred while fetching cities.");
      console.error("Error fetching cities:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const getProfileData = async () => {
    try {   
      setIsLoading(true);
      const data = await apiFetch(`/api/user-talent-profile/`, "GET", null, {
        Authorization: `Token ${user?.token}`,
      });
      console.log(data);
      setTalentProfileData(data);
      setInterest(data?.interest ? data?.interest : []);
      setSummary(data.summary ? data?.summary : "");
      setExperiences(data?.experiences ? data?.experiences : []);
      setSkills(data.skills ? data.skills : []);
      getSkillRelatedData(data?.skills);
      setAllCurrentSkills(data?.skills);
      setSelectedCities(data?.preferences);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const getSkillRelatedData = async (allSkills) => {
    if (allSkills?.length > 0) {
      const tested =
        allSkills?.map((it) => ({
          skill: it?.skill_name || it || "",
          type:
            it?.skill_type?.toLowerCase() === "coreskills" ||
            it?.skill_type === "ccs"
              ? "ccs"
              : it?.skill_type?.toLowerCase() === "technicalskills" ||
                it?.skill_type === "tsc"
              ? "tsc"
              : "",
          proficiency: it?.current_proficiency || "",
        })) || [];

      const currentPre =
        allCurrentSkills?.map((it) => ({
          skill: it?.skill_name || "",
          type:
            it?.skill_type?.toLowerCase() === "coreskills" ||
            it?.skill_type === "ccs"
              ? "ccs"
              : "tsc",
          proficiency: it?.current_proficiency || "",
        })) || [];

      let merged = [...currentPre, ...tested, ...allProfSkills];
      let data = uniqueSkillName(merged);
      setAllProfSkills(data);
    }
  };

  const fVal = (eve) => {
    console.log("Type of eve:", typeof eve, "Value of eve:", eve);

    if (Array.isArray(eve) && eve.length > 0) {
      return eve.filter((value) => value.trim() !== "");
    }
    return eve;
  };

  const getTotalMonths = (array) => {
    console.log(array, ` ,ASDHKASGDAS array`);
    let totalMonths = 0;
    const currentDate = new Date(); // Get current date
    if (array?.length > 0) {
      array.forEach((item) => {
        const startDate = item?.starts_at
          ? new Date(
              item?.starts_at?.year,
              item?.starts_at?.month - 1,
              item?.starts_at?.day
            )
          : currentDate;
        const endDate = item?.ends_at
          ? new Date(
              item?.ends_at?.year,
              item?.ends_at?.month - 1,
              item?.ends_at?.day
            )
          : currentDate;

        const monthsDifference =
          (endDate.getFullYear() - startDate.getFullYear()) * 12 +
          (endDate.getMonth() - startDate.getMonth());

        totalMonths += monthsDifference;
      });
    }

    return totalMonths;
  };

  return (
    <Card>
      {isLoading && <Loader />}
      <div className="card-header">
        <h5 className="card-title mb-0">My Talent Profile</h5>
      </div>
      <div className="card-body">
        <div className="form-data custom-large profile-summary">
          {talentProfileData?.summary?.length ? (
            <>
              <div className="form-data ant-form">
                <Row>
                  <Col span={24}>
                    <label htmlFor="addsummary">Summary</label>
                  </Col>
                  <Col span={24}>
                    <Input.TextArea
                      id="addsummary"
                      name="summary"
                      disabled
                      value={summary}
                      maxLength={2000000}
                      className="read-only"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <label htmlFor="interest">Interest</label>
                  </Col>
                  <Col span={24}>
                    <div className="remove-arrow">
                      <Select
                        mode="tags"
                        id="interest"
                        disabled
                        value={fVal(interest)}
                        style={{ width: "100%" }}
                        placeholder="Please enter your interest"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <label htmlFor="skills">Skills</label>
                  </Col>
                  <Col span={24}>
                    <div className="remove-arrow">
                      <Select
                        mode="tags"
                        id="skills"
                        disabled
                        value={fVal(skills)}
                        style={{ width: "100%", height: "150px" }}
                        placeholder="Please enter your interest"
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                  <Col span={24}>
                    <label htmlFor="totalexprience">Total experience</label>
                  </Col>
                  <Col span={24}>
                    <Input
                      name="totalexprience"
                      value={totalExp}
                      placeholder="Enter your total exprience"
                      disabled
                    />
                  </Col>
                </Row>

                {previousjobs?.map((element, index) => (
                  <div key={index}>
                    <Row style={{ marginBottom: "20px" }}>
                      <Col span={24}>
                        {previousjobs.length > 0 && (
                          <Input
                            value={element?.pervjobtitle || element.title}
                            placeholder="Enter your previous job"
                            disabled
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}

                <Row>
                  <Col span={24}>
                    <label htmlFor="previous_job">Education</label>
                  </Col>
                  <Col span={24}>
                    <div className="remove-arrow">
                      <Select
                        mode="tags"
                        id="education"
                        disabled
                        value={fVal(talentProfileData?.education)}
                        style={{ width: "100%" }}
                        placeholder="Education"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <label htmlFor="previous_job">Certficates</label>
                  </Col>
                  <Col span={24}>
                    <div className="remove-arrow">
                      <Select
                        mode="tags"
                        id="certificates"
                        disabled
                        value={fVal(talentProfileData?.certificates)}
                        style={{ width: "100%" }}
                        placeholder="Certficates"
                      />
                    </div>
                  </Col>
                </Row>

                <Col span={24}>
                  <label htmlFor="previous_job">Preferences</label>
                </Col>
                <Col span={24}>
                  <div className="remove-arrow">
                    <Select
                      mode="multiple"
                      placeholder="Select your cities"
                      value={selectedCities}
                      style={{ width: "100%" }}
                      disabled
                    >
                      {cities?.map((city) => (
                        <Option key={city.id} value={city.id}>
                          {city.full_name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </div>
            </>
          ) : (
            <div className="comming-soon">
              <div className="comming-btn">
                <Link
                  to={"/dashboard/talent-profile/create"}
                  className="btn btn-primary"
                >
                  Create Talent Profile{" "}
                </Link>
                <p>You can Create and manage your talent profile.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
    
  );
};

export default UserTalentProfile;
