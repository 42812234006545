import React from "react";
import { Select } from "antd";
const { Option } = Select;

const MultiSelect = ({
  expertiseList,
  selectedExpertise,
  onChange,
  enabled,
  placeholder,
}) => {
  // Convert expertiseList to options format required by Ant Design Select
  const options = expertiseList.map((expertise) => (
    <Option key={expertise.id} value={expertise.id}>
      {expertise.name}
    </Option>
  ));

  // Convert selectedExpertise to an array of ids
  const defaultValue = selectedExpertise.map((expertise) => expertise.id);

  // Handle change event
  const handleOnChange = (selectedValues) => {
    // Convert selected values to array of expertise objects
    const selectedExpertise = selectedValues.map((value) => {
      const expertise = expertiseList.find(
        (expertise) => expertise.id === value
      );
      return { id: expertise.id, name: expertise.name };
    });
    // Call onChange function with updated selected expertise
    onChange(selectedExpertise);
  };

  return (
    <>
      <Select
        mode="tags"
        disabled={enabled}
        defaultValue={defaultValue}
        onChange={handleOnChange}
        placeholder={placeholder}
        style={{ width: "100%" }}
      >
        {options}
      </Select>
    </>
  );
};

export default MultiSelect;
