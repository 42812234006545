const FilterIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
      >
        <path
          d="M2.59993 1.30762H11.3999C12.1333 1.30762 12.7333 1.96797 12.7333 2.77508V4.38928C12.7333 4.97627 12.3999 5.71 12.0666 6.07686L9.19993 8.86504C8.79993 9.2319 8.53327 9.96563 8.53327 10.5526V13.7077C8.53327 14.1479 8.2666 14.7349 7.93327 14.955L6.99994 15.6154C6.13327 16.2023 4.93327 15.542 4.93327 14.368V10.4792C4.93327 9.96563 4.6666 9.30527 4.39994 8.93841L1.8666 6.00349C1.53327 5.63662 1.2666 4.97627 1.2666 4.53603V2.84845C1.2666 1.96797 1.8666 1.30762 2.59993 1.30762Z"
          stroke="#787486"
          stroke-width="1.3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
export default FilterIcon;
