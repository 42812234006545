const SearchBar = ({handleSearch}) => {
    return(
        <>
           <div className="search-field d-flex align-items-center">
            <div className="input-group">
              <i className="fa-solid fa-magnifying-glass"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Search Job Role"
                onChange={handleSearch}
              />
            </div>
          </div>
        </>
    )
}
export default SearchBar;