import React, {useState, useEffect, useRef} from "react";
import { Card } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Tabs } from 'antd';
import {
    Form,
    Button,
    Select,
    Row,
    Col,   
    Input,
    Table,
    Checkbox
  } from "antd";
import TextArea from "antd/es/input/TextArea";
  const { Option } = Select;
const SkillMatrixPage = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [activeKey, setActiveKey] = useState("1");
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const handleSelectAllChange = (e) => {
        const checked = e.target.checked;
        setIsAllChecked(checked);
        if (checked) {
        setSelectedRowKeys(dataSource.map(item => item.key));
        } else {
        setSelectedRowKeys([]);
        }
    };

    const handleRowSelectionChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setIsAllChecked(newSelectedRowKeys.length === dataSource.length);
    };
    const dataSource = [
        {
          key: '1',
          jobRole: 'Software Engineer',
          skillname: 'JavaScript',
          questions: 'What is JavaScript?',
          profiencyLevel: '40%',
          questionStatus: 'AI Generated',
          status: 'Level Certified',
          action: '',
        },
        {
          key: '2',
          jobRole: 'Data Scientist',
          skillname: 'Python',
          questions: 'What is Python?',
          profiencyLevel: '50%',
          questionStatus: 'AI Generated',
          status: 'Level not Certified',
          action: '',
        },
        {
          key: '3',
          jobRole: 'Product Manager',
          skillname: 'Agile',
          questions: 'What is Agile methodology?',
          profiencyLevel: '80%',
          questionStatus: 'Modified',
          status: 'Level Certified',
          action: '',
        },
        {
            key: '3',
            jobRole: 'Product Manager',
            skillname: 'Agile',
            questions: 'What is Agile methodology?',
            profiencyLevel: '80%',
            questionStatus: 'AI Generated',
            status: 'Level Certified',
            action: '',
        },
        {
            key: '3',
            jobRole: 'Product Manager',
            skillname: 'Agile',
            questions: 'What is Agile methodology?',
            profiencyLevel: '80%',
            questionStatus: 'AI Generated',
            status: 'Level Certified',
            action: '',
        },
        {
            key: '3',
            jobRole: 'Product Manager',
            skillname: 'Agile',
            questions: 'What is Agile methodology?',
            profiencyLevel: '80%',
            questionStatus: 'AI Generated',
            status: 'Level Certified',
            action: '',
        },
        {
            key: '3',
            jobRole: 'Product Manager',
            skillname: 'Agile',
            questions: 'What is Agile methodology?',
            profiencyLevel: '80%',
            questionStatus: 'AI Generated',
            status: 'Level Certified',
            action: '',
        },
        {
            key: '3',
            jobRole: 'Product Manager',
            skillname: 'Agile',
            questions: 'What is Agile methodology?',
            profiencyLevel: '80%',
            questionStatus: 'AI Generated',
            status: 'Level Certified',
            action: '',
        },
        {
            key: '3',
            jobRole: 'Product Manager',
            skillname: 'Agile',
            questions: 'What is Agile methodology?',
            profiencyLevel: '80%',
            questionStatus: 'Modified',
            status: 'Level Certified',
            action: '',
        },
        {
            key: '3',
            jobRole: 'Product Manager',
            skillname: 'Agile',
            questions: 'What is Agile methodology?',
            profiencyLevel: '80%',
            questionStatus: 'Modified',
            status: 'Level Certified',
            action: '',
        },
        {
            key: '3',
            jobRole: 'Product Manager',
            skillname: 'Agile',
            questions: 'What is Agile methodology?',
            profiencyLevel: '80%',
            questionStatus: 'AI Generated',
            status: 'Level Certified',
            action: '',
        },
    ];
    const columns = [        
        {
          title: "Sr. No",
          key: "srNo",  
          render: (text, record) => record.srNo,       
        },
        {
            title: "Proficiency Level",
            key: "proficiencyLevel", 
            render: (text, record) => record.proficiencyLevel,        
        },
        {
            title: "Question Type",
            key: "questionType", 
            render: (text, record) => (
                <span className="question-type">{record.questionType}</span>
            )       
        },
        {
          title: "Question",
          key: "question",  
          render: (text, record) =>  (
            <div>
              <div>{record.question}</div>
                {record.options && (
                    <ul className="question-options">
                        {record.options.map((option, index) => (
                        <li key={index}>{option}</li>
                        ))}
                    </ul>
                )}
                {record.questionType === 'Audio' && record.audio && (
                    <>
                        <audio controls className="mt-3">
                            <source src={record.audio} type="audio/mpeg" />
                        </audio>                        
                    </>
                )}
                {record.questionType === 'Video' && record.video && (
                    <div>
                        <Button type="primary" variant="primary" onClick={handleShow} className="mt-3">Play Video</Button>
                        <Modal show={show} onHide={handleClose} size="" centered style={{backdropFilter: 'blur(8px)'}}>                    
                            <Modal.Body>
                                <video width="100%" height="240" controls autoplay>
                                    <source src={record.video} type="video/mp4" />
                                </video>
                            </Modal.Body>                       
                        </Modal>                        
                    </div>
                )}
            </div>
            )
             
        },
    ];
    const questionGenerated = [
        {
            key: '1',
            srNo: '1',
            proficiencyLevel: 'Basic',
            questionType: 'Multi Choice',
            question: 'How can organizations encourage employees to speak up about ethical concerns?', 
            options: ['Increased creativity', 'Broader perspectives', 'Enhanced problem-solving', 'Improved employee engagement'],           
        },
        {
            key: '1',
            srNo: '2',
            proficiencyLevel: 'Intermediate',
            questionType: 'Open Ended',
            question: 'What is the role of ethics training in fostering an ethical culture?',    
            options: '',
            audio:'',
            video: '',
        },
        {
            key: '1',
            srNo: '3',
            proficiencyLevel: 'Advance',
            questionType: 'Audio',
            question: 'What legal concept allows individuals to transfer their personal assets into a separate legal entity?',            
            options: '',
            audio: 'https://orarum.com/agestas/music/song5.mp3',
            video: '',
        },
        {
            key: '1',
            srNo: '4',
            proficiencyLevel: 'Basic',
            questionType: 'Video',
            question: 'How can organizations encourage employees to speak up about ethical concerns?',
            options: '', 
            audio:'',
            video: 'https://www.w3schools.com/html/mov_bbb.mp4',           
        },
        {
            key: '1',
            srNo: '5',
            proficiencyLevel: 'Basic',
            questionType: 'Multi Choice',
            question: 'How can organizations encourage employees to speak up about ethical concerns?', 
            options: ['Increased creativity', 'Broader perspectives', 'Enhanced problem-solving', 'Improved employee engagement'],           
        },
        {
            key: '1',
            srNo: '6',
            proficiencyLevel: 'Intermediate',
            questionType: 'Open Ended',
            question: 'What is the role of ethics training in fostering an ethical culture?',    
            options: '',
            audio:'',
            video: '',
        },
        {
            key: '1',
            srNo: '7',
            proficiencyLevel: 'Advance',
            questionType: 'Audio',
            question: 'What legal concept allows individuals to transfer their personal assets into a separate legal entity?',            
            options: '',
            audio: 'https://orarum.com/agestas/music/song5.mp3',
            video: '',
        },
        {
            key: '1',
            srNo: '8',
            proficiencyLevel: 'Basic',
            questionType: 'Video',
            question: 'How can organizations encourage employees to speak up about ethical concerns?',
            options: '', 
            audio:'',
            video: 'https://www.w3schools.com/html/mov_bbb.mp4',           
        },
    ];
    const questions = [
        {
          title: (
            <Checkbox checked={isAllChecked} onChange={handleSelectAllChange}>
             
            </Checkbox>
          ),
          key: "select",
          render: (_, record) => (
            <Checkbox
              checked={selectedRowKeys.includes(record.key)}
              onChange={(e) => {
                const newSelectedKeys = e.target.checked
                  ? [...selectedRowKeys, record.key]
                  : selectedRowKeys.filter(key => key !== record.key);
                handleRowSelectionChange(newSelectedKeys);
              }}
            />
          ),
        },        
        {
          title: "Job role Name",
          key: "jobRole",
          render: (text, record) => record.jobRole,
        },
        {
          title: "Skill Name",
          key: "skillname",
          render: (text, record) => record.skillname,
        },
        {
          title: "Question",
          key: "questions",
          render: (text, record) => record.questions,
        },
        {
            title: "Proficiency Level",
            key: "profiencyLevel",
            render: (text, record) => record.profiencyLevel,
        },
        {
            title: "Question Status",
            key: "questionStatus",
            render: (text, record) => record.questionStatus,
        },
        {
            title: "Status",
            key: "status",            
            render: (text, record) => {
              const statusClass =
                record.status === "Certified" ? "active" :
                record.status === "Level not Certified" ? "inactive" :
                "status";
          
              return <span className={statusClass}>{record.status}</span>;
            },
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <span>
                <span variant="primary" onClick={handleShow2} style={{cursor: 'pointer'}}><i class="fa fa-edit fs-5" aria-hidden="true"></i></span>
                <Modal show={show2} onHide={handleClose2} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h4>Edit Questions</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form layout="vertical">
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        className="custom-label"
                                        label="Question"
                                        name="question"                    
                                    >
                                        <TextArea />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={8}>
                                    <Form.Item
                                        className="custom-label"
                                        label="Job role Name"
                                        name="jobRoleName"                    
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        className="custom-label"
                                        label="Skill Name"
                                        name="skillName"                    
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>                            
                                <Col span={8}>
                                    <Form.Item
                                        className="custom-label"
                                        label="Status"
                                        name="status"                    
                                    >
                                        <Select
                                            style={{ width: "100%" }}
                                            >
                                            <Option value="">Level Certified</Option>
                                            <Option value="">Level Not Certified</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="secondary" onClick={handleClose2}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </span>
          ),
        },
      ];
    // Tabs
    
    const [verifiedTabs, setVerifiedTabs] = useState([]);
    const handleVerifyClick = () => {
        setVerifiedTabs((prevVerifiedTabs) =>
            prevVerifiedTabs.includes(activeKey) ? prevVerifiedTabs : [...prevVerifiedTabs, activeKey]
          );
    
        const nextKey = (parseInt(activeKey) + 1).toString();
        if (nextKey <= "6") {
          setActiveKey(nextKey);
        }
      };
      useEffect(() => {
        const tabElements = document.querySelectorAll(".ant-tabs-tab");
        tabElements.forEach((tab, index) => {
          const tabKey = (index + 1).toString();
          if (verifiedTabs.includes(tabKey)) {
            tab.classList.add("verified-tab");
          } else {
            tab.classList.remove("verified-tab");
          }
        });
      }, [verifiedTabs, activeKey]);
      const items = [
        { key: "1", label: "Level 1" },
        { key: "2", label: "Level 2" },
        { key: "3", label: "Level 3" },
        { key: "4", label: "Level 4" },
        { key: "5", label: "Level 5" },
        { key: "6", label: "Level 6" },
      ].map((tab) => ({
        key: tab.key,
        label: (
            <div className="d-flex align-items-center gap-2">
                <span className="step-level">              
                <span className="level-text">{`Level `}</span>
                <span className="level-number"> {tab.label.split(' ')[1]}</span>              
                </span>
                <span className="verified-icon"><i class="fa fa-check-circle" aria-hidden="true"></i></span>
            </div>
        ),
        className: verifiedTabs.includes(tab.key) ? "verified-tab" : "",
        children: (
          <div>
            <Table
              columns={questions}
              dataSource={dataSource}
              rowKey="id"
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],                
              }}
              style={{ marginBottom: "16px", width: "100%" }}
            />
            <div className="d-flex justify-content-end gap-3">
              {tab.key === "6" ? (
                <>
                  <Button type="secondary">Save</Button>
                  <Button type="primary" variant="primary" onClick={handleShow}>Save & Exit</Button>
                  <Modal show={show} onHide={handleClose} size="lg" centered>                    
                    <Modal.Body>
                        <h4 className="text-center mb-0 p-3">Skill matrix is generating questions. Once the questions are generated, you will receive an email.</h4>
                    </Modal.Body>                       
                  </Modal>
                </>
              ) : (
                <Button type="secondary" onClick={handleVerifyClick}>
                  Verify
                </Button>
              )}
            </div>
          </div>
        ),
      }));
    return (
        <>
        <Card className="mb-4">
            <div className="card-header">
                <div className="title d-flex justify-content-between align-items-end w-100">
                  <h5 className="card-title mb-0">Skill Matrix</h5>
                </div>
            </div>
            <div className="card-body">
                <Form
                layout="vertical"
                style={{ marginTop: "24px" }}
                >
                <Row className="mb-3" gutter={10}>
                    <Col span={8}>
                        <Form.Item
                            className="custom-label"
                            label="Skill Name"
                            name="skillName"
                            
                        >
                            <Select
                                style={{ width: "100%" }}
                                >
                                <Option value="tsc">Technical Skill</Option>
                                <Option value="ccs">Core Skill</Option>
                                <Option value="ssc">Soft Skill</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="custom-label"
                            label="No. of Question"
                            name="skillName"                    
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="custom-label"
                            label="Proficiency Level"
                            name="proficiencyLevel"
                            
                        >
                            <Select
                                style={{ width: "100%" }}
                                >
                                <Option value="">Basic</Option>
                                <Option value="">Intermediate</Option>
                                <Option value="">Advance</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    
                </Row>
                
                <div className="d-flex justify-content-end">
                    <Button
                        type="primary"
                        className="outline-primary"
                        variant="primary" onClick={handleShow1}
                    >
                        Start
                    </Button>
                    <Modal show={show1} 
                        onHide={handleClose1} 
                        size="xl" 
                        centered
                        scrollable
                        dialogClassName="custom-centered-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h4>Generated Questions</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Table
                            columns={columns}
                            dataSource={questionGenerated}
                            rowKey="id"
                            pagination={{
                                pageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["10", "20", "50", "100"],                
                            }}
                            style={{ marginBottom: "16px", marginTop: "20px" }}
                        />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="primary" onClick={handleClose1}>
                                Refresh
                            </Button>
                            <Button type="secondary" onClick={handleClose1}>
                                Continue
                            </Button>                        
                        </Modal.Footer>
                    </Modal>
                </div>
                </Form>                
            </div>
        </Card>
        <Card>
            <div className="card-header">
                <div className="title d-flex justify-content-between align-items-end w-100">
                  <h5 className="card-title mb-0">Skill Matrix Questions</h5>
                </div>
            </div>
            <div className="card-body">
                <Tabs
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                    items={items}
                    className="skill-matrix"
                />
            </div>
        </Card>
    </>
    )
};

export default SkillMatrixPage;