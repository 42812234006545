import { Button, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../components/dropdown";
import { jobListOption } from "../../constant";
import { useDispatch } from "react-redux";
import { storeSelValues } from "../../store/skillRenewal";

const RelevantSkillTestModal = ({allJobRole, show, handleClose,handleOk }) => {
  const dispatch = useDispatch();

  const handleStateChangeInSkill = (e) => {
    dispatch(storeSelValues({type:"role",value:e}));
  };

  return (
    <div>
      <Modal
        visible={show}
        okText={"Proceed"}
        onOk={handleOk}
        onCancel={handleClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        className="skill-qs-modal"
        width={600}
      >
        <div className="test-question-modal">
          <h2 className="title">
            Choose your desired job role to explore relevant skill tests
          </h2>
          <div className="job-role-list step-form">
            <CustomDropdown
              type="role"
              placeholder="Select job title"
              allRoles={allJobRole}
              onStateChange={handleStateChangeInSkill}
              title="What job title are you aiming for?"
              subTitle="If your job isn't in the dropdown, type it in the box and hit enter to proceed."
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RelevantSkillTestModal;
