import { createSlice } from "@reduxjs/toolkit";

export const organization = createSlice({
  name: "organization",
  initialState: {
    jobRolesList: [],
    activeJobRole: "",
    TalentPoolList: [],
    activeTalentPool: "",
  },
  reducers: {
    storeAllJobRoles: (state, { payload }) => {
        state.jobRolesList = payload
    },
    setActiveJobRole: (state, { payload }) => {
        state.activeJobRole = payload
    },
    storeAllTalentPools: (state, { payload }) => {
      state.TalentPoolList = payload
    },
    setActiveTalentPool: (state, { payload }) => {
      state.activeTalentPool = payload
  },

  },
});

export const {
  storeAllJobRoles,
  setActiveJobRole,
  storeAllTalentPools,
  setActiveTalentPool,
} = organization.actions;

export default organization.reducer;
