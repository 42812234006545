import React, { useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import { Button } from "antd";

export function BarChart({ skillData, type }) {
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );

  const [page, setPage] = useState(0);
  const itemsPerPage = 10;

  const handleNextPage = () => {
    if ((page + 1) * itemsPerPage < skillData?.length) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const currentSkillData = skillData?.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const labels = currentSkillData?.map((it) => it?.name) || [];

  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Expected Preference",
        borderColor: "rgba(252, 170, 11, 1)",
        backgroundColor: "rgba(252, 170, 11, 1)",
        borderWidth: 2,
        fill: false,
        data: currentSkillData?.map((it) => it?.expetedProf) || [],
      },
      {
        type: "bar",
        label: "Current Preference",
        backgroundColor: "rgba(81, 78, 255, 1)",
        borderRadius: 10,
        data: currentSkillData?.map((it) => it?.currentProf) || [],
        borderColor: "transparent",
        backgroundColor: "#2655E4",
        width: 10,
        borderWidth: 12,
      },
    ],
  };

  const getBasicProf = (l) => {
    let valNumber = l < 2 ? 0 : l == 2 ? 1 : 2;
    let val = ["Basic", "Intermediate", "Advanced"];
    return val[valNumber];
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            const datasetLabel = context[0]?.dataset?.label;
            if (datasetLabel === "Current Preference") {
              return "Current Proficiency";
            } else if (datasetLabel === "Expected Preference") {
              return "Expected Proficiency";
            }
            return datasetLabel;
          },
          label: function (context) {
            const skillName = context.label;
            const proficiency = context.raw;
            if (context?.dataset?.label == "Current Preference") {
              return `${skillName}: ${
                type == "tsc"
                  ? proficiency - 0.2
                  : getBasicProf(proficiency - 0.1)
              }`;
            } else {
              return `${skillName}: ${
                type == "tsc" ? proficiency : getBasicProf(proficiency)
              }`;
            }
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          color: "#383838",
          boxWidth: 10,
          boxHeight: 10,
          display: "block",
          borderRadius: 10,
          font: {
            size: 12,
            weight: 400,
            family: `"Spline Sans", sans-serif`,
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#787486",
          callback: function (value, index, values) {
            let label = this.getLabelForValue(value);
            return label.length > 10 ? label?.substr(0, 10) + "..." : label;
          },
          font: {
            size: 12,
            weight: 400,
            family: `"Spline Sans", sans-serif`,
          },
        },
      },
      y: {
        min: 0,
        max: type == "tsc" ? 6.5 : 3.5,
        ticks: {
          stepSize: 0.5,
          color: "#787486",
          callback: function (value) {
            const labels =
              type == "tsc"
                ? [0, 1, 2, 3, 4, 5, 6]
                : ["", "Basic", "Intermediate", "Advanced"];
            return labels[value] || "";
          },
          font: {
            size: 12,
            weight: 400,
            family: `"Spline Sans", sans-serif`,
          },
        },
        title: {
          display: true,
          color: "#787486",
          font: {
            size: 12,
            weight: 400,
            family: `"Spline Sans", sans-serif`,
          },
        },
      },
    },
  };

  return (
    <div className="chart-container chart__box">
      <div className="chart-wrapper">
        <Chart type="bar" data={data} options={options} />
      </div>
      <div className="btn-chart chart__btn">
        {skillData?.length>10 && <div className="next-chart-btn-group">
          <Button
            onClick={handlePrevPage}
            className="prev-chart-btn"
            disabled={page === 0}
          >
            <MdOutlineKeyboardArrowLeft />
          </Button>
          <Button
            className="next-chart-btn"
            onClick={handleNextPage}
            disabled={(page + 1) * itemsPerPage >= skillData?.length}
          >
            <MdKeyboardArrowRight />
          </Button>
        </div>}
      </div>
    </div>
  );
}
