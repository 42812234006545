
import DashboardHead from "../../common/dashboard-head";

import JobRoleCalculationList from "./job-role-calculation-value";
const CertifySkill = ({rolesBackup}) => {
  return (
    <>
      <DashboardHead pageTitle="Certify Skill Proficiency" subPageTitle="Domain Expert" showMenu={true}/>
      <JobRoleCalculationList/>
      
    </>
  );
};
export default CertifySkill;
