import { Button, Flex } from "antd";
import DashboardHead from "../common/dashboard-head";
import { Tabs, TabsProps } from "antd";
import ProgressTableList from "./progress-table-list";
import { coreSkillList, technicalSkillList } from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { storeAllSkillTests, storeSelValues, storeTabStatus } from "../../../store/skillRenewal";
import { useEffect, useState } from "react";
import { storeGlobalRole } from "../../../store/slice";
import { capitalizeFirstLetter, toMessage } from "../../utils";
import { callGetApi } from "../../../services/roadmap_api";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { Card } from "react-bootstrap";

const { TabPane } = Tabs;

const RevewalProgressList = ({allSkillTests, getData,selectedRole ,skillData,getAllSkills}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const {
    selectedVal,
  } = useSelector((state) => state?.skillRenewal);
  const [conSkill,setConSkill]=useState("")
  const [active,setActive]=useState("1")
  const [getConId,setGetConId]=useState("")
  const [continueLoading, setContinueLoading] = useState(false);

  const onChange = (key) => {
    setActive(key)
    let type=key==1?"CoreSkills":"TechnicalSkills" 
    dispatch(storeSelValues({ type: "type", value:type }));
    getAllSkills(type);
    localStorage.removeItem("is_continue")
    localStorage.removeItem("next_skill")
    localStorage.removeItem("mcq_skills")
  };

  const handleGraph=()=>{
    let val={label:getData?.target_role_name,value:getData?.target_role_name}
    dispatch(storeGlobalRole(selectedRole || val));
    navigate("skills_graph/?role=true")
  }

  const handleContinue = async () => {
    const type = selectedVal?.type === "TechnicalSkills" ? "tsc" : "ccs";
    const role = selectedVal?.role?.value;
  
    const cache = {};
  
    const questionPromises = skillData?.map(async (it) => {
      const cacheKey = `${role}-${it?.skill_name}-${type}`;
      if (!cache[cacheKey]) {
        cache[cacheKey] = checkingMcqsForContinue(role, it?.skill_name, type);
      }
      const isQuestion = await cache[cacheKey];
      if (isQuestion && it?.action !== "completed") {
        return it;
      }
      return null;
    });
  
    const results = await Promise.all(questionPromises);
    const filteredResults = results.filter((it) => it !== null);
  
    console.log(filteredResults, "res,");
  
    if (filteredResults.length > 0) {
      setConSkill(filteredResults[0]);
    } else {
      toMessage("No questions found for remaining skills.", "warning");
    }
  };
  
  const checkingMcqsForContinue = async (role, name, type) => {
    setContinueLoading(true);
    const res = await callGetApi(`/api/user/test-questions/?target_role_name=${encodeURIComponent(role)}&skill_name=${name}&skill_type=${type}`);
    setContinueLoading(false);
    return res && res?.questions?.length > 30;
  };
  

  const getFilteredLink = (allLinks, roadmapres) => {
    let result = {
      Reminder: roadmapres?.Roadmap?.Reminder,
    };
    let roadmap = roadmapres?.Roadmap;
    for (let key in roadmap) {
      if (Array.isArray(roadmap[key])) {
        let data = roadmap[key]?.map((it) => {
          for (let key2 in allLinks) {
            if (key2.includes(it.Topic)) {
              return { ...it, links: allLinks[key2] };
            }
          }
          return it;
        });
        result[key] = data;
      }
    }
    let replcedData = { ...roadmapres, Roadmap: result };
    console.log(replcedData, "roadmapres filteredLinks");
    return replcedData;
  };

  const getRoadMap = async (id) => {
    const res = await callGetApi(`/api/db-roadmap/get-reg-user/${id || "33"}`);
    let striRes = {
      ...res,
      content_plan: JSON.parse(res?.content_plan[0]),
      roadmap: JSON.parse(res?.roadmap[0]),
      mentor: JSON.parse(res?.mentor[0]),
    };
    let planss = getFilteredLink(striRes?.content_plan, striRes?.roadmap);
    // let data={CurrentSkills:"",Mentorships:"",Roadmap:"",SkillGapAnalysis:""}
    let updatePlan = { ...planss, Mentorships: striRes?.mentor };
    
    console.log(updatePlan, "roadmapres resresresresres");
    localStorage.setItem("showlinks", true);
    localStorage.setItem("roadmap_id", id);
    localStorage.setItem("analyserData", JSON?.stringify(updatePlan));
    navigate("/dashboard/analyser");
  };

  const showGenerate=(role)=>{
    const isValid=allSkillTests?.filter(it=>it?.target_role_name?.toLowerCase()==role?.toLowerCase() && it?.is_content);
    console.log(role,isValid,allSkillTests," isValid generated")
    if(isValid?.length>0){
      setGetConId(isValid[0]?.roadmap_id)
    }
  }

  useEffect(()=>{
    showGenerate(getData?.target_role_name || selectedRole);
  },[allSkillTests,selectedRole,getData])
  
const handleBack=()=>{
  dispatch(storeTabStatus("list"));
}

  return (
    <Card>
      <div className="card-header">
        <div className="progress-head-bar d-flex justify-content-between gap-5">
          <div className="d-flex  gap-3">
            <Button className="primary-border-btn" onClick={handleBack}><span className="me-1"><DoubleLeftOutlined /></span>Back</Button>
            <DashboardHead
              pageTitle="Skill Renewal Progress"
              subPageTitle={capitalizeFirstLetter(getData?.target_role_name || selectedRole || "Software Engineers")}
            />
          </div>
          <div className="cnt-btn">
            <Flex gap="small">
              <Button className="primary-btn" style={{display:getConId?"":"none"}} onClick={()=>getRoadMap(getConId)} >Switch To Generic Plan</Button>
              <Button className="primary-btn" disabled={continueLoading} onClick={handleContinue} >{continueLoading?"Processing...":"Continue"}</Button>
              <Button className="primary-border-btn" onClick={handleGraph}>See Graph</Button>
            </Flex>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="skill-tab-wrap">
          <Tabs activeKey={active} onChange={onChange}>
            <TabPane tab="Core Skills" key="1">
              <ProgressTableList skillType="core" dataSource={skillData} 
              conSkill={conSkill}
              setConSkill={setConSkill}
              />
            </TabPane>
            <TabPane tab="Technical Skills" key="2">
              <ProgressTableList
                skillType="technical"
                dataSource={skillData}
                conSkill={conSkill}
                setConSkill={setConSkill}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Card>
  );
};
export default RevewalProgressList;
