import React, { useEffect, useState } from "react";
import { Form, Row, Col, Select, Input, Button, message, Switch } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import apiFetch, { BASE_URL } from "../../api";
import Loader from "../../components/loader";
import { Card } from "react-bootstrap";

const { Option } = Select;

const TalentPool = () => {
  const { id } = useParams();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("False");
  const [matrix, setMatrix] = useState("Skill Matrix");
  const [mode, setMode] = useState("SERP"); // New state for mode
  const [allCompanies, setAllCompanies] = useState([]);
  const [allSectors, setAllSectors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");
  const navigate = useNavigate();

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);

  let user = JSON?.parse(localStorage.getItem("user"));

  const validateWhitespace = (_, value) => {
    if (value && /^\s+$/.test(value)) {
      return Promise.reject(new Error("Input cannot contain only spaces"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (id && allCompanies) {
      getTalentPoolDetails(id);
    } else {
      loadAllSectors();
      form.resetFields();
    }
  }, [id, allCompanies]);

  const getCompanies = async () => {
    try {
      const data = await apiFetch(`/api/get-all-companies/`, "GET", null, {
        Authorization: `Token ${user?.token}`,
      });
      if (data.success) {
        setAllCompanies(data.data);
      } else {
        console.error("Failed to retrieve companies:", data.message);
      }
    } catch (error) {
      console.error("Error fetching companies:", error.message);
    }
  };

  const getTalentPoolDetails = async (poolid) => {
    setLoading(true);
    setLoadingFor("Getting Talent Pool Data");
    try {
      const data = await apiFetch(`/api/talent-pool/${poolid}/`, "GET", null, {
        Authorization: `Token ${user?.token}`,
      });
      if (data.success) {
        const talentpool = data.data;
        setName(talentpool.name);
        const companyIds = talentpool.companies
          ? talentpool.companies.map((company) => company.id)
          : [];
        setSelectedCompanies(companyIds || []);

        setMatrix(talentpool.matrix || "Skill Matrix");
        setStatus(talentpool.status ? "True" : "False");
        setMode(talentpool.mode || "SERP"); // Set mode value
        console.log("talentpool", talentpool);
        const sectorsArray = JSON.parse(talentpool.sector.replace(/'/g, '"'));
        setSelectedSectors(sectorsArray || []);

        form.setFieldsValue({
          name: talentpool.name,
          companies: companyIds,
          sectors: sectorsArray,
          matrix: talentpool.matrix,
          mode: talentpool.mode || "SERP", // Set form field for mode
          status: talentpool.status ? "True" : "False",
        });
      } else {
        console.error("Failed to retrieve Talent Pool:", data.message);
      }
    } catch (error) {
      console.error("Error fetching Talent Pool data:", error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    if (window) {
      window.scrollTo({ top: 200, behavior: "smooth" });
    }
  };

  const submitForm = async () => {
    setLoading(true);
    setLoadingFor("Saving Talent Pool");
    try {
      const values = await form.validateFields();
      const talentpoolData = {
        name,
        companies: selectedCompanies,
        sectors: selectedSectors,
        matrix,
        status: status === "True",
        mode,
      };

      console.log("Submitted data", talentpoolData);

      const url = id
        ? `/api/update-talentpool/${id}/`
        : `/api/save-talentpool/`;
      const method = id ? "PUT" : "POST";
      const data = await apiFetch(url, method, talentpoolData, {
        Authorization: `Token ${user?.token}`,
      });
      if (data.success) {
        message.success("Talent Pool saved successfully");
        setTimeout(() => {
          navigate(`/admin-dashboard/talent-pools`);
        }, 1000); // 1000 milliseconds = 1 second
      } else {
        message.error("Failed to save Talent Pool:" + data.message);
      }
    } catch (error) {
      message.error("Failed to save Talent Pool:" + error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  const handleSectorChange = (sectors) => {
    console.log("Selected Sectors: ", sectors); // Debugging
    setSelectedSectors(sectors);
  };

  const handleCompanyChange = (companies) => {
    setSelectedCompanies(companies);
  };

  const loadAllSectors = async () => {
    //get-all-sectors
    try {
      const data = await apiFetch(`/api/get-all-sectors/`, "GET", null, {
        Authorization: `Token ${user?.token}`,
      });
      if (data.success) {
        setAllSectors(data.data);
      } else {
        console.error("Failed to retrieve sectors:", data.message);
      }
    } catch (error) {
      console.error("Error fetching sectors:", error.message);
    }
  };

  return (
    <Card>
      {loading && <Loader text={loadingFor} />}
      <div className="card-header">
        <div className="headingbar d-flex justify-content-between">
          <div className="title d-flex justify-content-between align-items-end w-100">
            <h5 className="card-title mb-0">
              {id ? "Update Talent Pool" : "Create Talent Pool"}
            </h5>
          </div>
        </div>
      </div>
      <div className="card-body assessment-form">
        <Form
          //{...formItemLayout}
          form={form}
          id="customform"
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          //variant="filled"
          layout="vertical"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                className="custom-label"
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please input Talent Pool name!" },
                  { validator: validateWhitespace },
                ]}
              >
                <Input value={name} onChange={(e) => setName(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
          {/* Mode selection */}
          <Row gutter={16} className="mb-2">
            <Col span={12}>
              <Form.Item label="Mode" name="mode">
                <Select value={mode} onChange={setMode}>
                  <Option value="SERP">Internet</Option>
                  <Option value="RAG">RAG</Option>
                  <Option value="BOTH">BOTH</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Matrix" name="matrix">
                <Input
                  value={matrix}
                  onChange={(e) => setMatrix(e.target.value)}
                  placeholder="Skill Matrix"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="mb-2">
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    Companies<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="companies"
                rules={[
                  { required: true, message: "Please select the companies!" },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  value={selectedCompanies || null} // Ensure selectedCompanies contains the correct company IDs
                  onChange={(value) => {
                    handleCompanyChange(value);
                  }}
                  placeholder="Select Companies"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {allCompanies.map((company) => (
                    <Option key={company.id} value={company.id}>
                      {company.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    Sectors <span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="sectors"
                rules={[
                  { required: true, message: "Please select the sectors!" },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  value={selectedSectors || null}
                  onChange={(value) => {
                    handleSectorChange(value);
                  }}
                  placeholder="Select Sectors"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {allSectors.map((sector, index) => (
                    <Option key={index} value={sector}>
                      {sector}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="mb-2">
            <Col span={12}>
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  { required: true, message: "Please select the status!" },
                ]}
              >
                {/* <Select
                  value={status}
                  onChange={setStatus}
                  placeholder="Select Status"
                  style={{ width: "100%" }}
                >
                  <Option value="True">Active</Option>
                  <Option value="False">Inactive</Option>
                </Select> */}
                <Switch
                  checked={status === "True"}
                  onChange={(checked) => setStatus(checked ? "True" : "False")}
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{ span: 24 }} className="text-end">
            <Button
              type="secondary"
              htmlType="submit"
              loading={loading}
              className="mt-4"
            >
              {id ? "Update" : "Create"} Talent Pool
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
};

export default TalentPool;
