import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { GoogleTranslateProvider } from "../GoogleTranslateProvider";

function Layout({ isLoggedIn, setIsLoggedIn, current_type, setCurrent_type }) {
     return (
        <GoogleTranslateProvider>
            <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} current_type={current_type} setCurrent_type={setCurrent_type} />
            <main>
                <Outlet />    
            </main>
            <Footer />
        </GoogleTranslateProvider>
    );
}

export default Layout;
