import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footer">
        <div className="row line-gap">
          <hr />
          <div className="col-md-12">
            <div className="Copyright">
              <p>
                Copyright © 2024 - {currentYear}{" "}
                <Link to="/">Skillmotion.ai</Link>. All Rights Reserved. To
                report contact{" "}
                <a href="mailto:Skill Motion <info@skillmotion.ai>">
                  info@skillmotion.ai
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
