// src/features/counterSlice.js

import { createSlice } from "@reduxjs/toolkit";

export const skillSlice = createSlice({
  name: "skill_pathway",
  initialState: {
    pathway:[]
  },
  reducers: {
    storeAllSkillPathway: (state, action) => {
      state.pathway = action?.payload;
    }
  },
});

export const {
  storeAllSkillPathway
} = skillSlice.actions;

export default skillSlice.reducer;
