import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  message,
  Switch,
  Modal,
} from "antd";
import apiFetch from "../../api";
import Loader from "../../components/loader";
import { Card } from "react-bootstrap";

const { Option } = Select;

const AssignEmployesstalentPool = ({ current_company, setCurrent_company }) => {
  const [talentPools, setTalentPools] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const [poolId, setPoolId] = useState("");
  const [user_ip, setUserIp] = useState("");
  const [roles, setRoles] = useState([]);
  const previousCompanyRef = useRef(current_company);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([
    {
      id: 1,
      emp_id: "",
      first_name: "",
      last_name: "",
      email: "",
      linkedin_url: "",
      roles: [],
    },
  ]);
  const [hasChanges, setHasChanges] = useState(false);
  const user = JSON?.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (current_company  && (hasChanges==false)) {
      loadTalentPools();
      loadEmployees();
    }
  }, [current_company, hasChanges]);

  useEffect(() => {
    if (poolId) {
      loadRoles();
    } else {
      setRoles([]);
    }
  }, [poolId]);

  useEffect(() => {
    if (current_company && poolId) {
      fetchAssignedEmployees();
    }
  }, [current_company, poolId]);

  useEffect(() => {
    // Fetch user IP from an external API
    const fetchUserIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setUserIp(data.ip); // Set the current IP in the state
      } catch (error) {
        console.error("Error fetching user IP:", error);
      }
    };

    fetchUserIp();
  }, []);

  const loadEmployees = async () => {
    try {
      setIsLoading(true);
      const data = await apiFetch(
        `/api/admin-company-employees/`,
        "POST",
        {
          company_id: current_company?.id,
        },
        {
          Authorization: `Token ${user?.token}`,
        }
      );
      setIsLoading(false);
      setEmployees(data.employees);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const loadTalentPools = async () => {
    try {
      setIsLoading(true);      
      const data = await apiFetch(
        `/api/admin-compnay-pools/`,
        "POST",
        {
          company_id: current_company?.id,
        },
        {
          Authorization: `Token ${user?.token}`,
        }
      );
      setIsLoading(false);
      setTalentPools(data.talent_pools);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const fetchAssignedEmployees = async () => {
    try {
      setRows([
        {
          id: 1,
          emp_id: "",
          first_name: "",
          last_name: "",
          email: "",
          linkedin_url: "",
          roles: [],
        },
      ]);
      setAssignedEmployees([]);
      setIsLoading(true);
      const data = await apiFetch(
        `/api/load-assigned-employees/`,
        "POST",
        {
          company_id: current_company?.id,
          pool_id: poolId,
        },
        {
          Authorization: `Token ${user?.token}`,
        }
      );
      setIsLoading(false);
      setAssignedEmployees(data.data);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const loadRoles = async () => {
    // Reset roles before loading new ones
    setRoles([]);
    try {
      setIsLoading(true);
      const data = await apiFetch(
        `/api/talent-pool-roles/`,
        "POST",
        {
          talentpool_id: poolId,
        },
        {
          Authorization: `Token ${user?.token}`,
        }
      );
      setIsLoading(false);
      if (data.success) {
        setRoles(data.data); // Assuming the roles are returned in 'data.roles'
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        emp_id: "",
        first_name: "",
        last_name: "",
        email: "",
        linkedin_url: "",
        roles: [],
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const handleSubmit = async (e) => {
    const employeesData = rows.map((row) => ({
      emp_id: row.emp_id,
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      linkedin_url: row.linkedin_url,
      roles: row.roles, // Assuming roles contain an array and you're selecting the first
      need_verify: row.need_verify,
    }));

    console.log("assign emp", {
      company_id: current_company?.id,
      talent_pool_id: poolId,
      user_ip: user_ip,
      employees: employeesData,
    });
    try {
      setIsLoading(true);
      const data = await apiFetch(
        `/api/assign-employees/`,
        "POST",
        {
          company_id: current_company?.id,
          talent_pool_id: poolId,
          user_ip: user_ip,
          employees: employeesData,
        },
        {
          Authorization: `Token ${user?.token}`,
        }
      );
      setIsLoading(false);
      if (data.success) {
        message.success("Employees successfully assigned to the talent pool.");
        setHasChanges(false); // Reset changes flag after saving
        fetchAssignedEmployees(); // Reload the assigned employees
      } else {
        message.error("Failed to assign employees.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error assigning employees:", error);
      message.error("An error occurred while assigning employees.");
    }
  };


  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "LinkedIn URL",
      dataIndex: "linkedin_url",
      key: "linkedin_url",
      render: (url) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "Target Role",
      dataIndex: "target_role_name",
      key: "target_role_name",
    },
    {
      title: "Talent Pool",
      dataIndex: "talent_pool_name",
      key: "talent_pool_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span>{status ? "Active" : "Inactive"}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          className="btn btn-outline-danger"
          onClick={() =>
            unAssignEmployee(
              record.talent_pool_id,
              record.id,
              record.target_role_id
            )
          }
        >
          Unassign
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (current_company) {
      if (current_company.id !== previousCompanyRef.current?.id) {
        // Handle the case when the company changes
        handleCompanyChange(current_company);
      } else {
        // Handle the case when the modal is confirmed or canceled
        loadTalentPools();
        loadEmployees();
      }
    }
  }, [current_company]);

  const handleCompanyChange = (newCompany) => {
    if (hasChanges) {
      Modal.confirm({
        title: "Unsaved Changes",
        content:
          "You have unsaved changes. Do you want to change the company without saving?",
        onOk: () => {
          setPoolId("");
          setAssignedEmployees([]);
          setHasChanges(false);
          previousCompanyRef.current = newCompany; // Update previous company
          // No need to setCurrent_company(newCompany); it's already set
        },
        onCancel: () => {
          setCurrent_company(previousCompanyRef.current); // Revert to previous company
        },
      });
    } else {
      setPoolId("");
      setAssignedEmployees([]);
      previousCompanyRef.current = newCompany; // Update previous company
      // No need to setCurrent_company(newCompany); it's already set
    }
  };

  const handleRowChange = (index, field, value) => {
    setHasChanges(true);
    const updatedRows = rows.map((row, i) => {
      if (i === index) {
        // If the selected field is emp_id, check if it is a new employee or existing employee
        if (field === "emp_id") {
          const selectedEmployee = employees.find((emp) => emp.id === value);
          if (selectedEmployee) {
            // Set employee details and mark as not new
            return {
              ...row,
              emp_id: value,
              first_name: selectedEmployee.first_name,
              last_name: selectedEmployee.last_name,
              email: selectedEmployee.email,
              linkedin_url: selectedEmployee.linkedin_url,
              is_new_employee: false, // Existing employee
            };
          } else {
            // New employee, reset fields and mark as new
            return {
              ...row,
              emp_id: "",
              first_name: "",
              last_name: "",
              email: "",
              linkedin_url: "",
              is_new_employee: true,
            };
          }
        } else {
          // Handle other fields normally
          return { ...row, [field]: value };
        }
      }
      return row;
    });
    setRows(updatedRows);
  };

  const unAssignEmployee = async (talentPoolId, employeeId, targetRoleId) => {
    Modal.confirm({
      title: "Are you sure you want to unassign this employee?",
      content: "This action cannot be undone.",
      onOk: async () => {
        try {
          setIsLoading(true);
          const data = await apiFetch(
            `/api/unassign-employee/`, // Your Unassign API endpoint
            "POST",
            {
              poolid: talentPoolId,
              empid: employeeId,
              roleid: targetRoleId,
            },
            {
              Authorization: `Token ${user?.token}`,
            }
          );
          setIsLoading(false);
  
          if (data.success) {
            message.success("Employee successfully unassigned.");
            fetchAssignedEmployees(); // Refresh the assigned employees list
          } else {
            message.error("Failed to unassign employee.");
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Error unassigning employee:", error);
          message.error("An error occurred while unassigning the employee.");
        }
      },
      onCancel() {
        console.log("Unassign canceled");
      },
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Card className="mb-4">
        <div className="card-header">
          <h5 className="card-title mb-0">
            Assign Employees To Talent Pool
          </h5>
        </div>
        <div className="card-body">
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Select Talent Pool">
                  <Select
                    placeholder="-- Select Talent Pool --"
                    value={poolId}
                    onChange={(value) => setPoolId(value)}
                  >
                    {talentPools.map((pool) => (
                      <Option key={pool.id} value={pool.id}>
                        {pool.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {rows.map((row, index) => (
              <Row gutter={16} key={row.id}>
                <Col span={4}>
                  <Form.Item label="Employee">
                    <Select
                      placeholder="-- Select Employee --"
                      name={`emp_id[${index}]`}
                      value={row.emp_id}
                      onChange={(value) =>
                        handleRowChange(index, "emp_id", value)
                      }
                    >
                      <Option key="" value="">
                        New Employee
                      </Option>
                      {employees.map((emp) => (
                        <Option key={emp.id} value={emp.id}>
                          {emp.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="First Name">
                    <Input
                      name={`first_name[${index}]`}
                      value={row.first_name}
                      onChange={(e) =>
                        handleRowChange(index, "first_name", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="Last Name">
                    <Input
                      name={`last_name[${index}]`}
                      value={row.last_name}
                      onChange={(e) =>
                        handleRowChange(index, "last_name", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Email">
                    <Input
                      name={`email[${index}]`}
                      value={row.email}
                      onChange={(e) =>
                        handleRowChange(index, "email", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="LinkedIn URL">
                    <Input
                      name={`linkedin_url[${index}]`}
                      value={row.linkedin_url}
                      onChange={(e) =>
                        handleRowChange(index, "linkedin_url", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="Roles">
                    <Select
                      name={`roles[${index}]`}
                      value={row.roles}
                      onChange={(value) =>
                        handleRowChange(index, "roles", value)
                      }
                      placeholder="Select Role"
                      mode="multiple" // Added to allow multi-select
                    >
                      {roles.map((role) => (
                        <Option key={role.id} value={role.id}>
                          {role.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Item label="Need Verify">
                      <Switch
                        name={`need_verify[${index}]`}
                        checked={row.need_verify}
                        onChange={(checked) =>
                          handleRowChange(index, "need_verify", checked)
                        }
                      />
                    </Form.Item>
                    <button
                      className="btn btn-danger bg-danger"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            ))}

            <Row>
              <Col span={24} className="d-flex justify-content-between">
                <Button type="primary" onClick={handleAddRow}>
                  <i className="fa fa-plus"></i> Add Employee
                </Button>
                <Button
                  type="secondary"
                  onClick={handleSubmit}
                  style={{ marginLeft: "10px" }}
                >
                  Assign Talent Pool
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <Card>
        <div className="card-header">
          <h5 className="card-title">Assigned Employees</h5>
        </div>
        <div className="card-body">
          <Table
            columns={columns}
            dataSource={assignedEmployees}
            rowKey="id"
            pagination={{ pageSize: 10 }}
          />
        </div>
      </Card>
    </>
  );
};

export default AssignEmployesstalentPool;
