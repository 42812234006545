import React, { useState, useEffect, useRef } from "react";
import { Collapse, Divider, Button, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import UserSkill from "./usercurrentskill";
import SkillAscent from "./SkillAscent";
import SkillSprintsnolinks from "./SkillSprintsnolink";
import { useNavigate } from "react-router-dom";
import {
  callGetApi,
  callPostApi,
  callPutApi,
} from "../../services/roadmap_api";
import LearninPath from "../modal/learningPath";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import apiFetch, { BASE_URL } from "../../api";
import EnrolledModal from "../../components/EnrolledModal";
import {
  removeDuplicateNameSkills,
  removeDuplicateSkills,
  toMessage,
} from "../../dashboard/utils";
import {
  storeReloadRoles,
  storeSelValues,
  storeTabStatus,
} from "../../store/skillRenewal";
import PdfGenerator from "../../dashboard/component/common/pdfGenerator";

import {
  getCurrentTecRadarSkill,
  getCurrentCoreRadarSkill,
  getStatus,
  getProfNum0,
} from "../../dashboard/utils";

import { RadarChart } from "../../dashboard/component/common/radarChart";

import SkillChart from "../../dashboard/component/common/SkillChart";
import { coreSkillList } from "../../constant";
import { Card } from "react-bootstrap";

const Collepsetimeline = ({
  target,
  roadmapres,
  setRoadmapres,
  setLoadingFor,
  current_company,
}) => {
  const { isLoggedIn } = useSelector((state) => state?.counterSlice);
  const { allRolesDes, userPreDetils, userAllPreDetils } = useSelector(
    (state) => state?.skillRenewal
  );
  const [topSkillsData, setTopSkillsData] = useState([]);
  const [bottomSkillsData, setBottomSkillsData] = useState([]);
  const [topGapsData, setTopGapsData] = useState([]);
  const [bottomGapsData, setBottomGapsData] = useState([]);

  const renewData = JSON?.parse(localStorage?.getItem("renewal-data"));

  const renewVal = JSON?.parse(localStorage?.getItem("renewal-val"));
  let isBeta = localStorage.getItem("is_beta");
  const isUser = localStorage.getItem("user");
  let roadmapId = localStorage.getItem("roadmap_id");
  const [finalRoadmapId, setFinalRoadmapId] = useState(roadmapId);
  const [newRoadmapId, setNewRoadmapId] = useState("");

  const userLo = localStorage.getItem("user");
  const user = userLo ? JSON.parse(localStorage.getItem("user")) : null;

  const dispatch = useDispatch();

  const [showLinks, setShowLinks] = useState(false);
  const [existingSkills, setExistingSkills] = useState("");
  const [skillGapAnalysis, setSkillGapAnalysis] = useState("");
  const [skillAscent, setSkillAscent] = useState([]);
  const [contentPlanData, setContentPlanData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedPath, setSelectedPath] = useState("");
  const [skillTotalCount, setSkillTotalCount] = useState("0");

  const [show, setShow] = useState(false);
  const [url, setUrl] = useState(false);
  const [journeyTitle, setJourneyTitle] = useState(false);
  const [message, setMessage] = useState("");
  const [enrollCase, setEnrollCase] = useState("");
  const [journey_ID, setJourney_ID] = useState("");
  const [updateCurrentSkill, setUpdateCurrentSkill] = useState(false);
  const [selected, setSelectedRole] = useState();
  const [technicalSkills, settechnicalSkills] = useState([]);
  const [coreSkills, setCoreSkills] = useState([]);
  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  const chartRef = useRef(null);
  const chartRef_ccs = useRef(null);
  const skillGapRef = useRef(null);

  useEffect(() => {
    setExistingSkills([
      roadmapres?.CurrentSkills,
      roadmapres?.SkillGapAnalysis,
    ]);
    const transformobjtoarr = Object.keys(roadmapres?.Roadmap).map((key) => {
      return { [key]: roadmapres?.Roadmap[key] };
    });
    setSkillGapAnalysis(transformobjtoarr);
    setSkillAscent(roadmapres?.Mentorships);
    //console.log(transformobjtoarr);
  }, [roadmapres]);

  let isView = localStorage.getItem("showlinks");
  useEffect(() => {
    if (isView) {
      setShowLinks(true);
    }

    return () => {
      localStorage.removeItem("analyserData");
      localStorage.removeItem("showlinks");
    };
  }, []);

  useEffect(() => {
    setSelectedRole(renewData?.target_role_name || renewVal?.label);
    setDescription(renewData?.description);
    if (selected) {
      getAllSkills();
    }
  }, [selected]);

  useEffect(() => {
    let data = localStorage.getItem("returnURL");
    if (data && data == "run_plans") {
      if (
        !renewData ||
        renewData?.status?.toLowerCase() == "yet to start" ||
        renewData?.status == ""
      ) {
        //setOpenModal(true);
        generatingPlans();
      } else {
        generatingPlans();
      }
    }
  }, []);

  const handlePopup = async () => {
    if (!selectedPath) {
      toMessage("Please select a learning path", "warning");
      return;
    }
    if (selectedPath == "personal") {
      let val = {
        label: renewData?.target_role_name || renewVal?.label,
        value:
          renewData?.target_role_name?.toLowerCase() ||
          renewVal?.label?.toLowerCase(),
      };
      dispatch(storeSelValues({ type: "role", value: val }));
      if (renewData) {
        dispatch(storeTabStatus("skills"));
      }
      setOpenModal(false);
      await generatingPlans(true);
    } else {
      setOpenModal(false);
      await generatingPlans();
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          Skill Compass &nbsp;
          <Tooltip
            title="The model is currently in beta phase and is under finetuning."
            placement="right"
            color={"#E0E0F0"}
            key={"#E0E0F0"}
          >
            <InfoCircleFilled style={{ fontSize: "20px", color: "#B4B4B4" }} />
          </Tooltip>
        </>
      ),
      children: (
        <UserSkill
          existingSkills={existingSkills}
          userAllPreDetils={userAllPreDetils}
          updateCurrentSkill={updateCurrentSkill}
        />
      ),
    },
  ];

  const items1 = [
    {
      key: "1",
      label: "Skill Sprints",
      children: (
        <SkillSprintsnolinks
          skillGapAnalysis={skillGapAnalysis}
          showLinks={showLinks}
          roadmapId={finalRoadmapId}
        />
      ),
    },
  ];

  const items2 = [
    {
      key: "1",
      label: "Skill Ascent",
      children: <SkillAscent skillAscent={skillAscent} showLinks={showLinks} />,
    },
  ];

  const getFilteredLink = (allLinks) => {
    let result = {
      Reminder: roadmapres?.Roadmap?.Reminder,
    };
    let roadmap = roadmapres?.Roadmap;
    for (let key in roadmap) {
      if (Array.isArray(roadmap[key])) {
        let data = roadmap[key]?.map((it) => {
          console.log(it, "it filteredLinks");

          for (let key2 in allLinks) {
            console.log(key2, " key2 filteredLinks");
            if (key2.includes(it.Topic)) {
              return { ...it, links: allLinks[key2] };
            }
          }
          return it;
        });
        result[key] = data;
      }
    }

    let replcedData = { ...roadmapres, Roadmap: result };
    console.log(replcedData, "filteredLinks");
    setRoadmapres(replcedData);
  };

  const updateNewRoadmap = async () => {
    try {
      setLoadingFor(
        "Updating your roadmap on the basis of your updated skill proficiency levels..."
      );

      let { SkillGapAnalysis } = roadmapres;
      let existing = [""];
      if (skillGapAnalysis && skillGapAnalysis?.length !== 0) {
        let corReq = SkillGapAnalysis?.[0]?.CoreSkills?.map(
          (it) => it?.skillName
        );
        corReq = removeDuplicateNameSkills(corReq);

        let tecReq = SkillGapAnalysis?.[0]?.TechnicalSkills?.map(
          (it) => it?.skillName
        );

        tecReq = removeDuplicateNameSkills(tecReq);
        existing = [...corReq, ...tecReq];
      }

      let payload = {
        summary: userPreDetils?.summary || "",
        interest: userPreDetils?.interest || [""],
        existing_skills: userPreDetils?.existing_skills || [""],
        total_experience:
          userPreDetils?.total_experience || "0 years and 0 months",
        education: userPreDetils?.education || [""],
        certificates: userPreDetils?.certificates || [""],
        target_role: renewData?.target_role_name || "",
        previous_job: userPreDetils?.previous_job || [""],
        current_job: userPreDetils?.current_job || "",
        required_skillset: existing || [""],
      };

      let response = await callPostApi(`/api/new-roadmap/`, payload);

      if (response == undefined) {
        setLoadingFor("");
        return;
      }
      setUpdateCurrentSkill(true);
      setLoadingFor("");
      const roadmap = response?.roadmap || [];
      return roadmap;
    } catch (error) {
      setLoadingFor("");
      setUpdateCurrentSkill(false);
      console.error("Error generating roadmap:", error);
    }
  };

  const updateGeneratingPlans = async () => {
    let updatedRoadmap = await updateNewRoadmap();

    let { ["Mentorships"]: removedKey, ...response } = roadmapres;
    setLoadingFor("Updating your content plan...");

    const plans = await callPostApi(`/api/new-roadmap/content-plan`, {
      roadmap: {
        roadmap: updatedRoadmap,
      },
    });

    let dataPlan = { plan: plans?.topics, roadmap: response };
    setContentPlanData(plans?.topics || {});
    getFilteredLink(plans?.topics);
    const storedRoadmap = await callPutApi(
      `/api/db-roadmap/update-roadmap/${roadmapId || renewData?.roadmap_id}`,
      {
        roadmap: [JSON.stringify(dataPlan?.roadmap)],
        content_plan: [JSON.stringify(dataPlan?.plan)],
        mentor: [JSON.stringify(roadmapres?.Mentorships || "")],
      }
    );

    // if(storedRoadmap){
    setShowLinks(true);
    setLoadingFor("");

    // }
  };

  const generatingPlans = async (type) => {
    // try {
    if (type) {
      setLoadingFor(
        "Please wait. We are preparing a personalised assessment for you..."
      );
      dispatch(storeReloadRoles(true));
      localStorage.setItem("is_realod_tests", true);
    } else {
      setLoadingFor("Creating your content plans...");
    }
    let { CurrentSkills, SkillGapAnalysis, Mentorships, ...response } =
      roadmapres;
    const plans = await callPostApi(`/api/new-roadmap/update-content-plan`, {
      roadmap: {
        roadmap: response,
      },
    });
    let { ["Mentorships"]: removedKey, ...response2 } = roadmapres;

    // if (plans?.status === 200) {
    let dataPlan = { plan: plans?.topics, roadmap: response2 };
    setContentPlanData(plans?.topics || {});
    getFilteredLink(plans?.topics);
    localStorage.removeItem("returnURL");
    localStorage.removeItem("analyserData");
    localStorage.removeItem("analyserDataCopy");
    setLoadingFor("");
    await roadmapStore(dataPlan, type);
    // }
    setShowLinks(true);
    setLoadingFor("");

    // } catch (error) {
    //   console.error("Error generating content plans:", error);
    //   setLoadingFor("");
    // }
  };

  const getTotalSkills = async (role) => {
    const resSkills = await callGetApi(
      `/api/target-role/skill-count/?target_role_name=${encodeURIComponent(
        role || "Audit Manager"
      )}`
    );
    if (resSkills) {
      let total =
        Number(resSkills?.skills?.technical_skill_count) +
        Number(resSkills?.skills?.soft_skill_count);
      setSkillTotalCount(total?.toString());
      return total?.toString();
    }
    return "0";
  };

  const roadmapStore = async (dataPlan, type) => {
    // try {
    // console.log("tar tar", target);
    let userDetailsIp = JSON.parse(localStorage.getItem("ipUserData"));

    const storedRoadmap = await callPostApi(
      `/api/db-roadmap/reg-user/${user?.user_id || 1}`,
      {
        user_id: user?.user_id,
        target_role: target?.tar?.label || target?.skill?.Job_Role || "",
        roadmap: [JSON.stringify(dataPlan?.roadmap)],
        content_plan: [JSON.stringify(dataPlan?.plan)],
        mentor: [JSON.stringify(roadmapres?.Mentorships || "")],
      }
    );

    const resmode = await apiFetch(`/api/get-mode-for-role/`,
      "POST",
      {
        company_id: current_company.id,
        role_name: target?.tar?.label || target?.skill?.Job_Role || "",
      },
      {
        Authorization: user?.token
      }
    )
    let mode = "RAG";
    if (resmode.success) {
      mode = resmode.mode;
    }

    console.log(renewData, target, "renewData ,target");
    if (storedRoadmap) {
      let Coursepayload = {
        company_id: current_company.id,
        candidate_id: user?.id,
        mode: mode,
        target_job_role: target?.tar?.label || target?.skill?.Job_Role || "",
      };

      let newPlanResponse = await callPostApi(
        `/api/candidate_course_panel/get_relevant_courses`,
        Coursepayload
      );

      console.log("newPlanResponse", newPlanResponse);
      console.log("mode", mode);
      console.log(`Authorization`, `Token ${user?.token}`);

      const saveNewContentPlan = await apiFetch(
        `/api/save-new-content-plan/`,
        "POST",
        {
          roadmap_id: storedRoadmap?.id?.toString() || "",
          new_content_plan: newPlanResponse,
          "mode": mode,
        },
        {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,          
        }
      );

      setNewRoadmapId(storedRoadmap?.id?.toString() || "");

      if (
        renewData
        // &&
        // (renewData?.target_role_name?.toLowerCase() ==
        //   target?.tar?.label?.toLowerCase() ||
        //   renewData?.target_role_name?.toLowerCase() ==
        //     target?.skill?.Job_Role?.toLowerCase())
      ) {
        const res2 = await callPutApi(
          `/api/user/skill-renewal-dashboard/${renewData?.id || "1"}`,
          {
            is_content: type ? true : false,
            is_roadmap: true,
            is_beta: isBeta ? true : false,
            roadmap_id: storedRoadmap?.id?.toString() || "",
            user_id: user?.user_id || "1111",
          }
        );
        console.log(res2, "res2 content planss");
        if (res2 && res2 !== undefined) {
          localStorage.setItem("renewal-data", JSON.stringify(res2));
          if (type) {
            navigate("/dashboard/renewal");
          }
        }
      } else if (target?.tar?.label || target?.skill?.Job_Role) {
        let count = await getTotalSkills(
          target?.tar?.label || target?.skill?.Job_Role
        );

        let payload = {
          id: 0,
          user_id: user?.user_id || "1111",
          target_role_name: target?.tar?.label || target?.skill?.Job_Role || "",
          skill_ids: [""],
          is_beta: isBeta ? true : false,
          is_content: type ? true : false,
          is_roadmap: true,
          description: target?.tar?.label || target?.skill?.Job_Role || "",
          status: "yet to start",
          roadmap_id: storedRoadmap?.id?.toString() || "",
          total_count: count || skillTotalCount || "0",
          reached_count: "0",
        };
        let res = await callPostApi(
          `/api/user/skill-renewal-dashboard/`,
          payload
        );
        if (res) {
          if (type) {
            navigate("/dashboard/renewal");
          }
        }
      }
      if (type) {
        navigate("/dashboard/renewal");
      }
      localStorage.removeItem("ipUserData");
    }
  };


  useEffect(() => {
    if (newRoadmapId && newRoadmapId !== "") {
      setFinalRoadmapId(newRoadmapId);
    } else {
      setFinalRoadmapId(roadmapId);
    }
  }, [newRoadmapId, roadmapId]);

  const showAllLinks = async () => {
    if (!isLoggedIn) {
      // get the skill analyser journey id and send to login/joureny_id
      const data = await apiFetch("/api/get-journey-skillanalyser/");

      if (data.success) {
        
        localStorage.setItem("analyserData", JSON.stringify(roadmapres));
        localStorage.setItem("analyserDataCopy", JSON.stringify(roadmapres));
        localStorage.setItem("returnURL", "/skill-analyser");

        // show modal and goto login/register
        setJourneyTitle("Enroll Skill Analyser");
        setMessage(
          "Login if you already have an account, or register if you don't."
        );
        setEnrollCase("1");
        setJourney_ID(data.journey_ID);
        setShow(true);
      }
    } else {
      const jsonData = localStorage.getItem("user");
      let userdata = JSON.parse(jsonData);
      // check if user not enrolled to journey send to register/joureny_id else generatingPlans

      const data = await apiFetch(
        "/api/is-enrolled-skillanalyser/",
        "POST",
        {
          token_key: userdata?.token,
        },
        { Authorization: `Token ${userdata?.token}` }
      );

      // if enrolled then
      if (data.success) {
        if (
          !renewData ||
          renewData?.status?.toLowerCase() == "yet to start" ||
          renewData?.status == ""
        ) {
          //setOpenModal(true);
          generatingPlans();
        } else {
          generatingPlans();
        }
      } else {
        setJourneyTitle("Enroll The Journey");
        setMessage("You are not enrolled in Skill Analyser.Please Enroll");
        setEnrollCase("2"); // Fixed typo here
        setJourney_ID(data.journey_ID);
        setShow(true);
      }
    }
  };

  const handleDashboard = () => {
    localStorage.removeItem("analyserData");
    localStorage.removeItem("analyserDataCopy");
    localStorage.removeItem("renewal-data");
    localStorage?.removeItem("isMap");
    navigate("/dashboard/");
  };

  console.log(
    roadmapres?.SkillGapAnalysis?.[0],
    roadmapres?.CurrentSkills?.[0],
    "roadmapres?.SkillGapAnalysis?.[0]"
  );

  const getAllSkills = async () => {
    //alert(selected);
    let payload1 = {
      name: selected,
      type: "tsc",
    };
    const resTecSkills = await callPostApi(
      `/api/domain-expert/sector/target-roles/skills/`,
      payload1
    );
    const userSkills = await callGetApi(
      `/api/user/skill-proficiency-graph/?target_role_name=${encodeURIComponent(
        selected
      )}&user_id=${user?.user_id || "1111"}`
    );

    let reverseUserSkills =
      userSkills?.length > 1 ? [...userSkills]?.reverse() : userSkills;
    const resultTec = (resTecSkills || []).map((it) => ({
      name: it.name,
      expetedProf: it.proficiency,
      currentProf: getCurrentTecRadarSkill(it.name, reverseUserSkills),
      status: getStatus(it.name, reverseUserSkills),
      type: "tsc",
    }));
    settechnicalSkills(resultTec);
    TopBottomData(resultTec);
    console.log("tech skills", resultTec);

    let payload2 = {
      name: selected,
      type: "ccs",
    };
    const resCoreSkills = await callPostApi(
      `/api/domain-expert/sector/target-roles/skills/`,
      payload2
    );

    const resultCore = (resCoreSkills || []).map((it) => ({
      name: it.name,
      expetedProf: getProfNum0(it.proficiency),
      currentProf: getCurrentCoreRadarSkill(it.name, reverseUserSkills),
      status: getStatus(it.name, reverseUserSkills),
      type: "ccs",
    }));

    console.log("core skills", resultCore);
    setCoreSkills(resultCore);
  };

  const calculateProficiency = (skill) =>
    Math.round((skill.currentProf / skill.expetedProf) * 100);

  const calculateGapProficiency = (skill) =>
    Math.round(
      ((skill.expetedProf - skill.currentProf) / skill.expetedProf) * 100
    );

  const TopBottomData = (resultTec) => {
    const enhancedSkillsData_top = resultTec.map((skill) => ({
      ...skill,
      value: calculateProficiency(skill),
    }));

    const enhancedSkillsData_bottom = resultTec.map((skill) => ({
      ...skill,
      value: calculateGapProficiency(skill),
    }));

    const sortedSkills = [...enhancedSkillsData_top].sort(
      (a, b) => b.value - a.value
    );

    const topSkills = sortedSkills.slice(0, 5);
    const bottomSkills = sortedSkills.slice(-5).reverse(); // reverse to get the least proficient skills first

    const sortedGaps = [...enhancedSkillsData_bottom].sort(
      (a, b) => b.value - a.value
    );
    const topGaps = sortedGaps.slice(0, 5);
    const bottomGaps = sortedGaps.slice(-5).reverse();

    setTopSkillsData(topSkills);
    setBottomSkillsData(bottomSkills);
    setTopGapsData(topGaps);
    setBottomGapsData(bottomGaps);
    console.log("bottomSkills", bottomSkills);
  };

  return (
    <>
      <div className="openClose">
        {isLoggedIn && (
          <>
            <div className="d-flex justify-content-end align-items-center">
              {renewData?.status?.toLowerCase() != "yet to start" && (
                <Button
                  className="update-roadmap-btn mb-0"
                  onClick={updateGeneratingPlans}
                >
                  Update Roadmap
                </Button>
              )}
              <PdfGenerator
                chartRef={chartRef}
                chartRef_ccs={chartRef_ccs}
                skillGapRef={skillGapRef}
                userName={user?.first_name}
                jobRole={
                  target?.tar?.label ||
                  target?.skill?.Job_Role ||
                  renewData?.target_role_name
                }
                description={description || ""}
                showLinks={showLinks}
                mentorships={roadmapres?.Mentorships}
                roadmap={roadmapres?.Roadmap}
                skillGapAnalysis={
                  roadmapres?.SkillGapAnalysis && {
                    CoreSkills: removeDuplicateSkills(
                      roadmapres?.SkillGapAnalysis?.[0]?.CoreSkills
                    ),
                    TechnicalSkills: removeDuplicateSkills(
                      roadmapres?.SkillGapAnalysis?.[0]?.TechnicalSkills
                    ),
                  }
                }
                currentSkills={
                  roadmapres?.CurrentSkills && {
                    CoreSkills: removeDuplicateSkills(
                      roadmapres?.CurrentSkills?.[0]?.CoreSkills
                    ),
                    TechnicalSkills: removeDuplicateSkills(
                      roadmapres?.CurrentSkills?.[0]?.TechnicalSkills
                    ),
                  }
                }
              />
            </div>
          </>
        )}
        <Card className="mb-4">
          <div className="card-body">
            <Collapse
              accordion={true}
              items={items}
              defaultActiveKey={["0"]}
              expandIconPosition="end"
              className="current-skill"
            />
            <Divider />
            <Collapse
              items={items1}
              defaultActiveKey={["0"]}
              expandIconPosition="end"
              className="roadmap-skill"
            />
            <Divider />
            <Collapse
              items={items2}
              defaultActiveKey={["0"]}
              expandIconPosition="end"
              className="current-skill"
            />
          </div>
        </Card>
        <div className="my-3">
          {!showLinks ? (
            <div className="next-btn  generate-btn">
              <Button
                className="next-btn"
                type="primary"
                onClick={showAllLinks}
              >
                Generate Content Plan
              </Button>
            </div>
          ) : (
            <div className="next-btn  generate-btn">
              <Button
                className="next-btn"
                type="primary"
                onClick={handleDashboard}
              >
                Go To Dashboard
              </Button>
            </div>
          )}
        </div>
        {/* <div className="next-btn pos-down generate-btn">
            <Button className="next-btn" type="primary" onClick={showAllLinks}>
              Generate Content Plan
            </Button>
          </div> */}
      </div>
      {/* <LearninPath
        show={openModal}
        handleClose={() => setOpenModal(false)}
        selectedPath={selectedPath}
        setSelectedPath={setSelectedPath}
        handlePopup={handlePopup}
      /> */}

      <EnrolledModal
        show={show}
        handleClose={() => setShow(false)}
        url={url}
        modalTitle={journeyTitle}
        enrollCase={enrollCase}
        message={message}
        journeyID={journey_ID}
      />

      <div>
        {/* <div className="row">
        <div className="col-lg-7 col-md-12">
          <div className="graph-side">
            <div className="canvas-graph-side text-center p-5">
              {actualSkills.length > 0 && (
                <RadarChart countSkills={actualSkills} type={type} />
              )}
            </div>
          </div>
        </div>
        </div> */}
        <div className="row" ref={skillGapRef}>
          <div className="col-lg-6 col-md-6">
            <div className="graph-side">
              <div className="text-center">
                <Card className="mb-4">
                  <div className="card-body">
                    {topSkillsData.length > 0 && (
                      <SkillChart
                        chart_label={"Top Skills"}
                        color={"#0000FF"}
                        barData={topSkillsData}
                      />
                    )}
                  </div>
                </Card>
                <Card className="mb-4">
                  <div className="card-body">
                    {bottomSkillsData.length > 0 && (
                      <SkillChart
                        chart_label={"Bottom Skills"}
                        color={"#FFE7D2"}
                        barData={bottomSkillsData}
                      />
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mb-4">
            <div className="graph-side">
              <div className="text-center">
                <Card className="mb-4">
                  <div className="card-body">
                    {topGapsData.length > 0 && (
                      <SkillChart
                        chart_label={"Top Gaps"}
                        color={"#FFE7D2"}
                        barData={topGapsData}
                      />
                    )}
                  </div>
                </Card>
                <Card className="mb-4">
                  <div className="card-body">
                    {bottomGapsData.length > 0 && (
                      <SkillChart
                        chart_label={"Bottom Gaps"}
                        color={"#0000FF"}
                        barData={bottomGapsData}
                      />
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Card className="mb-4">
              <div className="card-header">
                <h5 className="card-title mb-0">Core Skills Spyder</h5>
              </div>
              <div className="card-body">
                {coreSkills.length > 0 && (
                  <div className="col-lg-12 col-md-12">
                    <div className="graph-side">
                      <div className="canvas-graph-side text-center">
                        <div ref={chartRef_ccs}>
                          {coreSkills.length > 0 && (
                            <RadarChart countSkills={coreSkills} type={"ccs"} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card>
              <div className="card-header">
                <h5 className="card-title mb-0">Technical Skills Spyder</h5>
              </div>
              <div className="card-body">
                <div className="graph-side">
                  <div className="canvas-graph-side text-center">
                    <div ref={chartRef}>
                      {technicalSkills.length > 0 && (
                        <RadarChart
                          countSkills={technicalSkills}
                          type={"tsc"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collepsetimeline;
