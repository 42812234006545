import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "./sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import "../../assets/css/dashboard-style.css";
import { useSelector } from "react-redux";
//import Sidebar from './common/sidebar';
import { GoogleTranslateProvider } from "../../GoogleTranslateProvider";

const DashboardRoutes = ({
  current_company,
  setCurrent_company,
  profileImage,
  setProfileImage,
  userType,
  current_type,
  setCurrent_type,
}) => {
  const navigate = useNavigate();

  const [sideBarMenu, setSideBarMenu] = useState(false);
  const handleShowSidebarMenu = () => {
    setSideBarMenu(true);
  };
  const handleHideSidebarMenu = () => {
    setSideBarMenu(false);
  };
  const { isLoggedIn } = useSelector((state) => state?.counterSlice);

  useEffect(() => {
    console.log("isloggedin", isLoggedIn);
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);
  // Toggle sidebar
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  return (
    <>
      <GoogleTranslateProvider>
        <div className="dashboard">
          {/* <Sidebar /> */}
          {isSidebarVisible && (
            <Sidebar
              isLoggedIn={isLoggedIn}
              sideBarMenu={sideBarMenu}
              handleHideSidebarMenu={handleHideSidebarMenu}
              current_company={current_company}
              setCurrent_company={setCurrent_company}
              profileImage={profileImage}
              setProfileImage={setProfileImage}
              isOrganization={window.location.pathname.includes(
                "/organization"
              )}
              current_type={current_type}
              setCurrent_type={setCurrent_type}
            />
          )}
          {/* <Sidebar
          isLoggedIn={isLoggedIn}
          sideBarMenu={sideBarMenu}
          handleHideSidebarMenu={handleHideSidebarMenu}
          current_company={current_company}
          setCurrent_company={setCurrent_company}
          profileImage={profileImage}
          setProfileImage={setProfileImage}
          isOrganization={window.location.pathname.includes("/organization")}
          userType={userType}          
        /> */}
          <div
            className={`dashboard-main ${
              isSidebarVisible ? "content-with-sidebar" : "content"
            }`}
          >
            <Header
              profileImage={profileImage}
              setProfileImage={setProfileImage}
              showSidebarmenu={handleShowSidebarMenu}
              toggleSidebar={toggleSidebar}
              current_type={current_type}
              setCurrent_type = {setCurrent_type}
            />

            <div className="main-content">
              {/* Main content */}
              <Outlet></Outlet>
            </div>

            <Footer />
          </div>
        </div>
      </GoogleTranslateProvider>
    </>
  );
};

export default DashboardRoutes;
