import React, { useEffect } from "react";
import { Tabs } from "antd";
import { Typography } from "antd";
import UploadCv from "./UploadCv";
import LinkedinUrl from "./LinkedinUrl";
import ProfileForm from "./ProfileForm";
import AddUrl from "../assets/images/dashboard/add-url.png";
import UploadDocument from "../assets/images/dashboard/upload-document.png";
import EditFile from "../assets/images/dashboard/edit-file.png";

const { Text } = Typography;

const ProfileTabs = ({
  setNextDisabled,
  setShowNext,
  showNext,
  setLinkorfile,
  activeTab,
  setActiveTab,
  setAddformdata,
  setPerviousjobs,
  setAddpervjobsfields,
  addformdata,
  previousjobs,
  addpervjobsfields,
  target,
  setRoadmapres,
  setLoader,
  uniqueSkills,
  loader,
  setRoadmapLoader,
  setLoadingFor,
  mentorsData,
  cities,
  setCities,
}) => {

  const handleLinkedinUrlChange = (isValid) => {
    setNextDisabled(!isValid);
  };


  useEffect(() => {
    if (localStorage.getItem('analyserDataCopy') !== null) {
      //setCurrentStep(3);
      
      const jsonData = localStorage.getItem('analyserDataCopy');

      if (jsonData) {        
        setActiveTab("3");
        setShowNext(false);
        setLinkorfile(null);        
      }
    }
    
  }, []);
    
  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === "1") {
      setLinkorfile(null);
      setShowNext(true);
    }
    if (key === "3") {
      setShowNext(false);
      setLinkorfile(null);
    }
    if (key === "2") {
      setLinkorfile(null);
      setShowNext(true);
    }
  };

  return (
    <div className="talent-profile bottom-20">
      <Text className="Profile-label">
        {"Save Your Talent Profile "} <b>{"(use any one of the options below)"}</b>
      </Text>
      <Tabs
        defaultActiveKey="0"
        centered
        activeKey={activeTab}
        onChange={handleTabChange}
        items={[
          {
            key: "1",
            label: (
              <div className="tab-icon d-flex gap-2 align-items-center">
                <img loading="lazy" src={AddUrl} alt="linkedin url" width={25} height={25} />
                <span>Add Linkedin URL</span>
              </div>
            ),
            //label: "Add Linkedin URL",
            children: (
              <LinkedinUrl
                onUrlChange={handleLinkedinUrlChange}
                setShowNext={setShowNext}
                setLinkorfile={setLinkorfile}
              />
            ),
          },
          {
            key: "2",
            label: (
              <div className="tab-icon d-flex gap-2 align-items-center">
                <img loading="lazy" src={UploadDocument} alt="Upload Documents" width={25} height={25} />
                <span>Upload Documents</span>
              </div>
            ),
            //label: "Upload Documents",
            children: (
              <UploadCv
                setLinkorfile={setLinkorfile}
                setNextDisabled={setNextDisabled}
              />
            ),
          },
          {
            key: "3",
            label: (
              <div className="tab-icon d-flex gap-2 align-items-center">
                <img loading="lazy" src={EditFile} alt="Add / Edit Details Manually" width={25} height={25} />
                <span>Add / Edit Details Manually</span>
              </div>
            ),
            //label: "Add / Edit Details Manually",
            children: (
              <ProfileForm
                setAddformdata={setAddformdata}
                setPerviousjobs={setPerviousjobs}
                setAddpervjobsfields={setAddpervjobsfields}
                addformdata={addformdata}
                previousjobs={previousjobs}
                addpervjobsfields={addpervjobsfields}
                target={target}
                loader={loader}
                setLoader={setLoader}
                uniqueSkills={uniqueSkills}
                setRoadmapLoader={setRoadmapLoader}
                setLoadingFor={setLoadingFor}
                mentorsData={mentorsData}
                cities={cities}
                setCities ={setCities}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default ProfileTabs;
