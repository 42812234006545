import { Button, Checkbox, List, Tooltip } from "antd";
import { useEffect, useState } from "react";
import EditIcon from "../../../../assets/images/edit-icon.png";
import { questionData } from "../../../../constant";
import EditQuestionAnswer from "../../../modal/edit-question-answer";
import { callPostApi, callTestApi } from "../../../../services/roadmap_api";
import { useDispatch, useSelector } from "react-redux";
import {
  storeAllDomains,
  storeGetList,
  storeSelValues,
  storeSelectedDomain,
  storeSkillOptions,
  storeTestStatus,
} from "../../../../store/domainExpert";
import { toast } from "react-toastify";
import { isObjectsEqual, toMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";

const QuestionAllList = ({
  selectedValues,
  allQuestion,
  generateQueAns,
  sector,
  count,
}) => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [openModal, setOpenModal] = useState({
    show: false,
    data: "",
    key: -1,
  });
  const [selectedOptions, setSelectedOptions] = useState({});
  const [loading, setloading] = useState({ is: false, type: "" });
  const [selectedBac, setSelectedBac] = useState("");
  const [isSelect, setIsSelect] = useState(false);

  useEffect(() => {
    setSelectedBac(selectedValues);
    setSelectedOptions({});
    setIsSelect(false);
  }, [allQuestion]);

  const onChange = (key, it) => {
   
    let lenthOfSelcted = 0;
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = { ...prevSelectedOptions };
      if (updatedOptions[key] && updatedOptions[key] === it) {
        delete updatedOptions[key];
      } else {
        updatedOptions[key] = it;
      }
      lenthOfSelcted = Object.keys(updatedOptions).length;
      return updatedOptions;
    });
    console.log(lenthOfSelcted, "it");

    if (lenthOfSelcted === 20 || lenthOfSelcted===allQuestion?.length) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  };

  const allSelect = (e) => {
    const updatedOptions = {};
    if (e?.target?.checked) {
      let data = allQuestion?.map((it, key) => {
        updatedOptions[key] = it;
      });
    }
    setSelectedOptions(updatedOptions);
    setIsSelect(!isSelect);
  };

  const isChecked = (key) => {
    let data = Object.keys(selectedOptions).find((it) => it == key);
    return data ? true : false;
  };

  const dataTosend = () => {
    let ques = Object.entries(selectedOptions).map(([key, value]) => {
      let all = {
        id: 0,
        question: value.question,
        answer: value.correct_answer,
      };
      let opn = value.options.map((option, i) => {
        return { [`option${i + 1}`]: option };
      });

      let mergedOptions = opn?.reduce((acc, val) => Object.assign(acc, val), {});

      console.log(all, mergedOptions, "ques selectedValues");

      let mergedObject = Object.assign(all, mergedOptions);

      return mergedObject;
    });

    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0];

    let data = {
      skill_questions: {
        id: 0,
        target_role_name: selectedValues?.role?.value,
        skill_name: selectedValues?.name,
        skill_type: selectedValues?.type == "CoreSkills" ? "ccs" : "tsc",
        proficiency: selectedValues?.level,
        question_id: [""],
      },
      questions: ques,
      domain_expert: {
        id: 0,
        sector: sector?.label,
        target_role_name: selectedValues?.role?.value,
        total_question: Object.keys(selectedOptions)?.length || 0,
        date: formattedDate,
        status: "certified",
        skill_question_id: [""],
      },
    };

    return data;
  };

  const handleSave = async (type) => {
    // if (!isObjectsEqual(selectedValues, selectedBac)) {
    //   toast.warning(
    //     "The skills have been updated. Please regenerate the MCQs."
    //   );
    //   return;
    // }
    if (Object?.keys(selectedOptions)?.length == 0) {
      toMessage("Please select at least 1 question to proceed", "warning");
      return;
    }
    setloading({ is: true, type });
    let data = dataTosend();
    console.log(data, " data to send");

    const res = await callPostApi(`/api/question/store/`, data);
    if (res) {
      toMessage("Selected MCQs are saved successfully", "success");
      if (type == "exit") {
        dispatch(storeTestStatus("list"));
        dispatch(storeGetList(true));
        navigate('/domain-expert-dashboard')
        // dispatch(storeSelValues({type:"all"}));
        // dispatch(storeSkillOptions([]));
        // dispatch(storeAllDomains({type:"question",data:[]}))
      } else {
        setTimeout(() => {
          generateQueAns().then(() => {
          }).catch(error => {
            console.error("Error generating question and answer:", error);
          });
        }, 2000);
      }
      setSelectedOptions({});
      setIsSelect(false);
    }
    setloading({ is: false, type: "" });
  };

  const Arr = ["A", "B", "C", "D"];

  const rPre = (o) => {
    if(o){
      return o?.replace(/^[^.]*[.)]\s*/, '') || o;
    }
    return "";
  };
  // const rPre = (o) => {
  //   if (o?.split(")")?.length > 1) {
  //     return o?.split(")" || ".")[1];
  //   }
  //   return o?.split(")" || ".")[0];
  // };

  console.log(allQuestion, "allQuestion");
  return (
    <>
      <div>
        <div className="question-list-wrapper">
          <div className="question-heading">
            <div className="question-title">
              <p className="title">
                No. of Certified Questions:{" "}
                <span className="value">{count || 0}</span>
              </p>
              <div className="answer-box">
                <span className="correct-answer">correct answer</span>
                <span className="wrong-answer">Wrong answer</span>
              </div>
            </div>
            <div className="select-checkbox">
              <Checkbox disabled={allQuestion?.length==0} checked={isSelect} onChange={(e) => allSelect(e)}>
                Select All Questions
              </Checkbox>
            </div>
          </div>
          {allQuestion?.length > 0 ? (
            <>
              <div className="question-list-wrap">
                {allQuestion?.map((it, key) => (
                  <div key={key} className="question-box">
                    <div className="qs-box">
                      <Checkbox
                        checked={isChecked(key)}
                        onChange={() => onChange(key, it)}
                      >
                        {`${key+1}. ${it.question}`}
                      </Checkbox>
                      <div className="qs-list">
                        <List>
                          {it?.options?.map((option, i) => (
                            <List.Item
                              key={i}
                              style={{
                                color:
                                  option?.charAt(0) ==
                                  it?.correct_answer?.charAt(0)
                                    ? "blue"
                                    : "",
                              }}
                            >
                              <List.Item.Meta
                                avatar={
                                  <span
                                    style={{
                                      backgroundColor:
                                        option?.charAt(0) ==
                                        it?.correct_answer?.charAt(0)
                                          ? "blue"
                                          : "",
                                    }}
                                  >
                                    {Arr[i]}
                                  </span>
                                }
                                title={rPre(option)}
                              />
                              {/* {option} */}
                            </List.Item>
                          ))}
                        </List>
                      </div>
                    </div>
                    <div className="edit-qs-field">
                      <Tooltip
                        placement="leftTop"
                        title="Amend Question and Answer"
                      >
                        <Button
                          onClick={() =>
                            setOpenModal({ show: true, data: it, key: key })
                          }
                        >
                          <img src={EditIcon} alt="icon" />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-4 d-flex align-items-center justify-content-end gnt-btn">
                <Button
                  className="primary-btn me-3"
                  onClick={() => handleSave("more")}
                  disabled={loading?.is}
                >
                  <span style={{ color: loading?.is ? "black" : "" }}>
                    {loading?.is && loading?.type == "more"
                      ? "processing ..."
                      : "Save & Generate More"}
                  </span>
                </Button>
                <Button
                  disabled={loading?.is}
                  className="exit-btn"
                  onClick={() => handleSave("exit")}
                >
                  <span style={{ color: loading?.is ? "black" : "" }}>
                    {loading?.is && loading?.type == "exit"
                      ? "processing ..."
                      : "Save & Exit"}
                  </span>
                </Button>
              </div>
            </>
          ) : (
            <div style={{ textAlignLast: "center", marginTop: 50 }}>
              <h3>No MCQs Found </h3>
              <p>
                <b>Note :</b> If you are changing the{" "}
                <b> skill name, skill type or skill proficiency level </b> then
                please regenerate MCQs.
              </p>
            </div>
          )}
        </div>
        <EditQuestionAnswer
          show={openModal?.show}
          data={openModal?.data}
          keyValue={openModal?.key}
          allData={allQuestion}
          handleClose={() => setOpenModal({ show: false, data: "", key: -1 })}
        />
      </div>
    </>
  );
};
export default QuestionAllList;
