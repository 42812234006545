import CertifiedIcon from "../assets/images/domain-expert/certified-icon.png";
import JobRoleIcon from "../assets/images/domain-expert/job-role-icon.png";
import ProgressIcon from "../assets/images/domain-expert/progress-icon.png";
export const defaultOptions = [
  "Audit Associate / Audit Assistant Associate",
  "Audit Manager",
  "Audit Partner / Audit Director",
  "Audit Senior",
  "Business Valuation Associate / Business Valuation Executive",
  "Business Valuation Manager",
  "Business Valuation Partner / Business Valuation Director",
  "Business Valuation Senior / Business Valuation Senior Executive",
  "Chief Risk Officer / Risk Partner / Head of Risk / Enterprise Risk Management Director",
  "Enterprise Risk Management Associate / Enterprise Risk Management Executive",
  "Enterprise Risk Management Manager",
  "Enterprise Risk Management Senior / Enterprise Risk Management Senior Executive",
  "Accountant / Senior Accounts Executive",
  "Accounts Executive / Accounts Assistant",
  "Finance Manager",
  "Financial Controller",
  "Head of Treasury",
  "Treasury Manager",
  "Chief Financial Officer",
  "Financial Forensics Associate / Financial Forensics Executive",
  "Financial Forensics Director / Financial Forensics Partner",
  "Financial Forensics Manager",
  "Financial Forensics Senior / Financial Forensics Senior Executive",
  "Chief Audit Executive / Head of Internal Audit",
  "Internal Audit Assistant Manager",
  "Internal Audit Senior Manager / Internal Audit Manager",
  "Senior Internal Auditor / Internal Auditor",
  "Accounting Executive",
  "Business Controller / Finance Director",
  "Financial Planning and Analysis Manager",
  "Management Accountant / Financial Planning and Analysis Analyst / Business Analyst",
  "Mergers and Acquisitions Associate / Mergers and Acquisitions Executive",
  "Mergers and Acquisitions Manager",
  "Mergers and Acquisitions Partner / Mergers and Acquisitions Director",
  "Mergers and Acquisitions Senior / Mergers and Acquisitions Senior Executive",
  "Restructuring and Insolvency Associate / Restructuring and Insolvency Executive",
  "Restructuring and Insolvency Manager",
  "Restructuring and Insolvency Partner / Restructuring and Insolvency Director",
  "Restructuring and Insolvency Senior / Restructuring and Insolvency Senior Executive",
  "Tax Associate / Tax Executive",
  "Tax Manager",
  "Tax Partner / Tax Director / Tax Vice President / Head of Tax",
  "Tax Senior / Tax Senior Executive",
  "Engineering Service Engineer / Technical Service Engineer (Aircraft Engine / Component Maintenance)",
  "General Manager / Managing Director / Vice President (Aircraft Engine / Component Maintenance)",
  "General Worker / Operator",
  "NDT Level 3 Engineer (Aircraft Engine / Component Maintenance)",
  "Operations Manager (Aircraft Engine / Component Maintenance)",
  "Planning Assistant (Aircraft Engine / Component Maintenance)",
  "Planning Executive (Aircraft Engine / Component Maintenance)",
  "Planning Manager (Aircraft Engine / Component Maintenance)",
  "Planning Supervisor (Aircraft Engine / Component Maintenance)",
  "Quality Engineer (Aircraft Engine / Component Maintenance)",
  "Quality Manager (Aircraft Engine / Component Maintenance)",
  "Repair Engineer / Process Engineer",
  "Senior Engineering Service Engineer / Senior Technical Service Engineer (Aircraft Engine / Component Maintenance)",
  "Senior NDT Level 3 Engineer (Aircraft Engine / Component Maintenance)",
  "Senior Operations Manager / Senior Technical Manager",
  "Senior Planning Executive (Aircraft Engine / Component Maintenance)",
  "Senior Quality Engineer (Aircraft Engine / Component Maintenance)",
  "Senior Repair Engineer / Senior Process Engineer",
  "Senior Technician (Component Repair & Overhaul - Avionics)",
  "Senior Technician (Component Repair & Overhaul - Mechanical)",
  "Senior Technician (Engine / Engine Component Repair & Overhaul)",
  "Store Assistant",
  "Technical Service Manager (Aircraft Engine / Component Maintenance)",
  "Technician (Component Repair & Overhaul - Avionics)",
  "Technician (Component Repair & Overhaul - Mechanical)",
  "Technician (Engine / Engine Component Repair & Overhaul)",
  "Trainee Maintenance Technician",
  "Aircraft Maintenance Engineer Apprentice",
  "Assistant Foreman / Lead Maintenance Engineer / Superintendent",
  "Cat A Certifying Technician",
  "Engineering Service Engineer / Technical Service Engineer (Aircraft Maintenance)",
  "Engineering Service Manager / Technical Service Manager",
  "Foreman / Operations Manager / Programme Manager",
  "General Manager / Managing Director / Vice President (Aircraft Maintenance)",
  "Licensed Aircraft Engineer - Cat B1 (Mechanical)",
  "Licensed Aircraft Engineer - Cat B2 (Avionics)",
  "Licensed Aircraft Engineer - Cat C",
  "NDT Level 3 Engineer (Aircraft Maintenance)",
  "Planning Assistant (Aircraft Maintenance)",
  "Planning Executive (Aircraft Maintenance)",
  "Planning Manager (Aircraft Maintenance)",
  "Planning Supervisor (Aircraft Maintenance)",
  "Programme Director / Senior Operations Manager",
  "Quality Engineer (Aircraft Maintenance)",
  "Quality Manager (Aircraft Maintenance)",
  "Senior Engineering Service Engineer / Senior Technical Service Engineer (Aircraft Maintenance)",
  "Senior NDT Level 3 Engineer (Aircraft Maintenance)",
  "Senior Planning Executive (Aircraft Maintenance)",
  "Senior Quality Engineer (Aircraft Maintenance)",
  "Senior Technician (Avionics)",
  "Senior Technician (Mechanical)",
  "Senior Workshop Engineer",
  "Technician (Avionics)",
  "Technician (Mechanical)",
  "Trainee Aircraft Maintenance Technician",
  "Workshop Engineer",
  "General Manager / Managing Director / Vice President (Fleet Management)",
  "Lead Maintenance Engineer",
  "Maintenance Controller / Technical Operations Representative",
  "Operations Manager (Fleet Management)",
  "Planning Assistant (Fleet Management)",
  "Planning Executive (Fleet Management)",
  "Planning Manager (Fleet Management)",
  "Planning Supervisor (Fleet Management)",
  "Programme Director",
  "Quality Engineer (Fleet Management)",
  "Quality Manager (Fleet Management)",
  "Senior Planning Executive (Fleet Management)",
  "Senior Quality Engineer (Fleet Management)",
  "Senior Technical Service Engineer (Fleet Management)",
  "Technical Service Engineer (Fleet Management)",
  "Technical Service Manager (Fleet Management)",
  "Design Engineer",
  "Design Section Manager",
  "General Manager / Managing Director / Vice President (Manufacturing)",
  "Manufacturing Engineer / Production Engineer (Assembly)",
  "Manufacturing Engineer / Production Engineer (Manufacturing)",
  "Manufacturing Manager",
  "Manufacturing Planning Assistant",
  "Manufacturing Planning Executive",
  "Manufacturing Planning Manager",
  "Manufacturing Planning Supervisor",
  "NDT Level 3 Engineer (Manufacturing)",
  "Operations Manager / Production Manager",
  "Quality Engineer (Manufacturing)",
  "Quality Manager (Manufacturing)",
  "Senior Design Engineer",
  "Senior Manufacturing Engineer / Senior Production Engineer (Assembly)",
  "Senior Manufacturing Engineer / Senior Production Engineer (Manufacturing)",
  "Senior Manufacturing Planning Executive",
  "Senior NDT Level 3 Engineer (Manufacturing)",
  "Senior Operations Manager / Senior Production Manager / Senior Manufacturing Manager",
  "Senior Quality Engineer (Manufacturing)",
  "Senior Technician (Assembly)",
  "Senior Technician (Manufacturing)",
  "Technician (Assembly)",
  "Technician (Manufacturing)",
  "Trainee Technician",
  "Agriculture Technician",
  "Agriculturist",
  "Farm Worker",
  "Aquaculture Technician",
  "Aquaculturist",
  "Farm Manager",
  "Airport Emergency Assistant Manager",
  "Airport Emergency Manager",
  "Airport Emergency Officer",
  "Airport Emergency Officer (Specialist)",
  "Vice President",
  "Assistant Manager (Operations & Maintenance / Specialised Systems / Projects)",
  "Associate (Operations & Maintenance / Specialised Systems / Projects)",
  "Manager (Operations & Maintenance / Specialised Systems / Projects)",
  "Senior Associate (Operations & Maintenance / Specialised Systems / Projects)",
  "Airport Operations Centre Manager",
  "Duty Terminal Manager",
  "Terminal Manager",
  "Airside Duty Manager",
  "Airside Officer",
  "Manager (Airside Operations)",
  "Baggage Services Assistant",
  "Baggage Services Officer",
  "Executive (Baggage Services)",
  "Manager (Baggage Services)",
  "Supervisor (Baggage Services)",
  "Cabin Crew",
  "Cabin Crew Executive",
  "Cabin Crew Manager",
  "Cabin Crew Team Lead / Cabin Crew Auditor",
  "Crew-in-Charge",
  "Cargo Assistant",
  "Cargo Officer",
  "Executive (Cargo)",
  "Manager (Cargo)",
  "Supervisor (Cargo)",
  "Catering Assistant",
  "Catering Officer",
  "Executive (Production / Catering-Cabin)",
  "Manager (Production / Catering-Cabin)",
  "Supervisor (Production / Catering-Cabin)",
  "Customer Services Officer (Call Centre) / Customer Service Officer (Reservations & Ticketing) / Passenger Relations Officer",
  "Customer Services Supervisor (Call Centre) / Customer Service Supervisor (Reservations & Ticketing) / Premium Services Supervisor",
  "Executive (Contact Centre Operations) / Executive (Service Centres) / Executive (Premium Passenger Services)",
  "Manager (Contact Centre Operations) / Manager (Service Centres) / Manager (Premium Passenger Services)",
  "Senior Customer Services Officer (Call Centre) / Senior Customer Service Officer (Reservations & Ticketing) / Senior Passenger Relations Officer",
  "Executive (Flight Operations)",
  "Flight Operations Assistant",
  "Flight Operations Officer",
  "Manager (Flight Operations)",
  "Supervisor (Flight Operations)",
  "Flight Controller",
  "Officer (Flight Control)",
  "Senior Officer (Flight Control) / Senior Officer (Crew Scheduling)",
  "Supervisor (Flight Control) / Supervisor (Crew Scheduling)",
  "Executive (Ground Services)",
  "Ground Services Officer",
  "Manager (Ground Services)",
  "Senior Ground Services Officer",
  "Supervisor (Ground Services)",
  "Executive (Load Control)",
  "Load Control Assistant",
  "Load Control Officer",
  "Manager (Load Control)",
  "Supervisor (Load Control)",
  "Assistant Manager (Network Planning)",
  "Manager (Network Planning)",
  "Network Planning Analyst",
  "Customer Services Agent",
  "Customer Services Officer",
  "Executive (Passenger Services)",
  "Manager (Passenger Services)",
  "Supervisor (Passenger Services)",
  "Captain",
  "Chief Pilot (Vice President)",
  "Deputy Chief Pilot / Management Pilot",
  "First Officer",
  "Executive (Ramp / Technical Ramp)",
  "Manager (Ramp / Technical Ramp)",
  "Ramp Officer",
  "Ramp Operator",
  "Supervisor (Ramp / Technical Ramp)",
  "Technical Ramp Officer",
  "Executive (Technical Services)",
  "Maintenance Technician",
  "Manager (Technical Services)",
  "Supervisor (Technical Services)",
  "Technical Services Officer",
  "Engineering and Maintenance Director",
  "Engineering and Maintenance Engineer",
  "Engineering and Maintenance Manager",
  "Engineering and Maintenance Principal / Senior Engineer",
  "Engineering and Maintenance Senior Technician",
  "Engineering and Maintenance Supervisor",
  "Engineering and Maintenance Technician",
  "Site Director / Head",
  "Process Development / MS&T Director",
  "Process Development / MS&T Engineer",
  "Process Development / MS&T Manager",
  "Process Development / MS&T Senior Engineer",
  "Production Director",
  "Production Engineer / Biotechnologist",
  "Production Executive",
  "Production Manager",
  "Production Senior Technician / Production Technician / Assistant Biotechnologist",
  "Production Team Supervisor",
  "Quality Assurance and Quality Control Director",
  "Quality Assurance Assistant",
  "Quality Assurance Manager",
  "Quality Assurance Senior Specialist",
  "Quality Assurance Specialist",
  "Quality Control Assistant Laboratory Analyst",
  "Quality Control Laboratory Analyst / Chemist / Microbiologist",
  "Quality Control Manager",
  "Quality Control Senior Laboratory Analyst / Senior Chemist / Senior Microbiologist",
  "Architect",
  "Architectural Assistant",
  "Architectural Associate",
  "Architectural Executive",
  "Associate Director (Architecture) / Principal Architectural Executive",
  "Senior Architect",
  "Senior Architectural Executive",
  "Assistant Project Manager (Construction) / Construction Manager",
  "Engineer / Assistant Engineer",
  "Project Director (Construction)",
  "Senior Project Manager (Construction) / Project Manager (Construction)",
  "Site Supervisor / Trade Supervisor / Project Coordinator",
  "Factory Manager",
  "Production Manager / Assistant Production Manager",
  "Production Supervisor",
  "Quality Assurance Manager / Quality Control Manager / Quality Assurance Engineer / Quality Control Engineer / Planner",
  "Quality Assurance Supervisor / Quality Control Supervisor",
  "Senior Production Manager",
  "Senior Quality Assurance Manager / Senior Quality Control Manager / Senior Planner",
  "Assistant Specialist (Digital Delivery)",
  "Chief Digital Officer",
  "Lead (Digital Delivery)",
  "Specialist (Digital Delivery)",
  "Assistant Civil and Structural Engineer / Technical Executive (Civil and Structural Engineering)",
  "Assistant Mechanical Engineer / Assistant Electrical Engineer / Technical Executive (Mechanical Engineering) / Technical Executive (Electrical Engineering)",
  "Associate Director (Civil and Structural Engineering) / Principal Civil and Structural Engineer",
  "Associate Director (Mechanical Engineering / Electrical Engineering) / Principal Mechanical Engineer / Principal Electrical Engineer",
  "Civil and Structural Engineer",
  "Mechanical Engineer / Electrical Engineer",
  "Senior Civil and Structural Engineer",
  "Senior Mechanical Engineer / Senior Electrical Engineer",
  "Associate Director (Facilities Management)",
  "Building Executive / Facilities Executive / Property Executive",
  "Building Officer / Facilities Officer / Property Officer",
  "Building Supervisor",
  "Facilities Manager / Facilities Engineer",
  "Facilities Technician",
  "Senior Facilities Manager",
  "Technical Executive",
  "Technical Officer",
  "Director / Managing Director / Chief Executive / General Manager",
  "Assistant Project Manager / Project Management Executive",
  "Project Director (Project Management)",
  "Project Manager",
  "Senior Project Manager",
  "Assistant Quantity Surveyor / Assistant Cost Manager",
  "Associate Director (Quantity Surveying) / Contracts Director",
  "Quantity Surveyor / Contracts Manager / Cost Manager",
  "Senior Quantity Surveyor / Senior Contracts Manager / Senior Cost Manager",
  "Design Coach / Design Accelerator",
  "Design Facilitator",
  "Design Production Manager",
  "Design Project Manager",
  "Head of Design Production",
  "Head of Projects / Head of Products",
  "Head of Transformation",
  "Product Manager",
  "Chief Design Officer / Chief Innovation Officer / Chief Experience Officer / Chief Strategy Officer",
  "Content Strategist",
  "Content Writer",
  "Designer",
  "Lead Designer",
  "Lead Visualiser / Lead Storyteller",
  "Principal Designer",
  "Visualiser / Storyteller",
  "Design Researcher",
  "Head of Strategy",
  "Lead Design Researcher",
  "Lead Service Designer / Lead Experience Designer",
  "Lead Strategist / Lead Business Designer",
  "Principal Design Researcher",
  "Principal Service Designer / Principal Experience Designer",
  "Strategist / Business Designer",
  "Creative Technologist",
  "Beginning Early Years Educator",
  "Beginning Infant Educator",
  "Beginning Preschool Educator",
  "Centre Leader",
  "Curriculum / Pedagogy Specialist",
  "Deputy Centre Leader",
  "Early Years Educators",
  "Infant Educator",
  "Lead Early Years Educators",
  "Lead Preschool Educator",
  "Preschool Educator",
  "Senior Centre Leader",
  "Senior Early Years Educators",
  "Senior Infant Educator",
  "Senior Preschool Educator",
  "Beginning Early Intervention Educator",
  "Centre Manager",
  "Director",
  "Early Intervention Educator",
  "Lead Early Intervention Educator",
  "Senior Early Intervention Educator",
  "Senior Lead Early Intervention Educator",
  "Cluster Manager",
  "Lead Learning Support Educator",
  "Learning Support Educator",
  "Senior Lead Learning Support Educator",
  "Senior Learning Support Educator",
  "Head of Department",
  "Manager / Senior Manager",
  "Managing Director / General Manager / Vice-President",
  "Senior Superintendent",
  "Superintendent",
  "Assistant Equipment Engineer",
  "Assistant Facilities Engineer",
  "Assistant Integration Engineer",
  "Assistant Process Engineer",
  "Assistant Product Engineer",
  "Assistant Quality Engineer",
  "Equipment Engineer",
  "Facilities Engineer",
  "Fellow / Senior Fellow",
  "Integration Engineer",
  "Operator",
  "Principal Engineer",
  "Process Engineer",
  "Product Engineer",
  "Quality Engineer",
  "Senior Equipment Engineer",
  "Senior Facilities Engineer",
  "Senior Integration Engineer",
  "Senior Process Engineer",
  "Senior Product Engineer",
  "Senior Quality Engineer",
  "Staff Engineer / Senior Staff Engineer",
  "Technician",
  "Discipline Engineer",
  "Engineering Manager",
  "Junior Maintenance Technician",
  "Maintenance Manager",
  "Maintenance Planner",
  "Maintenance Supervisor",
  "Principal Engineer (Engineering)",
  "Senior Discipline Engineer",
  "Senior Maintenance Technician",
  "HSE Manager",
  "HSE Specialist",
  "Senior HSE Specialist",
  "Feedstock Supply Trader",
  "Junior Process Technician",
  "Learning and Development Specialist",
  "Learning and Development Superintendent",
  "Operation Specialist",
  "Principal Engineer (Technology)",
  "Process Engineering Manager",
  "Process Safety Engineer",
  "Process Technician",
  "Production Planner",
  "Senior Process Safety Engineer",
  "Senior Process Technician",
  "Supply Chain / Commercial Manager",
  "Chief Engineer / Fellow",
  "General Manager / Site Director / Vice President",
  "Laboratory Assistant",
  "Laboratory Chemist",
  "Laboratory Supervisor",
  "Laboratory Technician / Technologist",
  "QA Engineer",
  "QA&QC Manager",
  "Senior Laboratory Chemist",
  "Senior Laboratory Technician / Technologist",
  "Senior QA Engineer",
  "Chief Chemist / Fellow",
  "Principal Chemist",
  "R&D Chemist",
  "R&D Manager",
  "Research Technologist",
  "Senior R&D Chemist",
  "Application Chemist",
  "Senior Application Chemist",
  "Senior Technical Service Engineer",
  "Technical Service Engineer",
  "Technical Service Manager",
  "ESS Project Development Engineer",
  "Solar PV and ESS Asset Maintenance Engineer",
  "Solar PV and ESS Asset Maintenance Senior Technical Officer",
  "Solar PV and ESS Business Development and Project Financing Manager",
  "Solar PV and ESS Business Development Senior Executive",
  "Solar PV and ESS Project Development and Asset Maintenance Project Manager",
  "Solar PV and ESS Project Financing Senior Executive",
  "Solar PV Project Development Engineer",
  "Solar PV Project Development Site Supervisor",
  "Chief Executive Officer / Chief Operating Officer / Managing Director / General Manager / President",
  "Electricity Transmission and Distribution Department Manager",
  "Network Development Engineer",
  "Network Development Manager",
  "Network Development Principal Engineer",
  "Network Development Senior Technical Officer",
  "Network Development Technical Officer",
  "Network Development Technician",
  "Network Planning and Strategy Engineer",
  "Network Planning and Strategy Manager",
  "Network Planning and Strategy Principal Engineer",
  "Network Planning and Strategy Senior Technical Officer",
  "Network Planning and Strategy Technical Officer",
  "Operations and Maintenance Engineer",
  "Operations and Maintenance Manager",
  "Operations and Maintenance Principal Engineer",
  "Operations and Maintenance Senior Technical Officer",
  "Operations and Maintenance Technical Officer",
  "Operations and Maintenance Technician",
  "Billing and Settlement Executive",
  "Billing and Settlement Senior Executive",
  "Business-to-Business Sales and Key Accounts Executive",
  "Business-to-Business Sales and Key Accounts Manager",
  "Business-to-Business Sales and Key Accounts Senior Executive",
  "Business-to-Customer Sales Executive",
  "Business-to-Customer Sales Manager",
  "Business-to-Customer Sales Senior Executive",
  "Customer Service Executive",
  "Customer Service Senior Executive",
  "Customer Service, Billing and Settlement Department Manager",
  "Customer Service, Billing and Settlement Manager",
  "Demand Management Data Analyst",
  "Demand Management Manager",
  "Demand Management, Business-to-Business Sales and Key Accounts, Business-to-Customer Sales and Marketing Department Manager",
  "Marketing and Channel Management Senior Executive",
  "Marketing Manager",
  "Product and Pricing Senior Executive",
  "Energy Trading and Portfolio Management Department Manager",
  "Energy Trading Manager",
  "Energy Trading Trader",
  "Portfolio Management Analyst",
  "Portfolio Management Manager",
  "Gas Systems Operations Engineer",
  "Gas Systems Operations Manager",
  "Gas Systems Operations Principal Engineer",
  "Gas Systems Operations Senior Technical Officer",
  "Gas Systems Operations Technical Officer",
  "Gas Transmission and Distribution Department Manager",
  "LNG Research Analyst",
  "LNG Trading and Research Head",
  "LNG Trading Trader",
  "Engineering and Maintenance Department Manager",
  "Engineering and Maintenance Principal Engineer",
  "Engineering and Maintenance Senior Technical Officer",
  "Engineering and Maintenance Technical Officer",
  "Multi-Utility Operations and Water Quality Control Department Manager",
  "Multi-Utility Operations Engineer",
  "Multi-Utility Operations Senior Technical Officer",
  "Multi-Utility Operations Shift Manager",
  "Multi-Utility Operations Team Leader",
  "Multi-Utility Operations Technical Officer",
  "Multi-Utility Operations Technician",
  "Water Quality Control Chemist",
  "Water Quality Control Manager",
  "Water Quality Control Principal Chemist",
  "Water Quality Control Senior Technical Officer",
  "Water Quality Control Technical Officer",
  "Water Quality Control Technician",
  "Fuel System Operations Executive",
  "Fuel System Operations Manager / Fuel System Operations Senior Executive",
  "Terminal Operations and Fuel System Operations Department Manager",
  "Terminal Operations Manager",
  "Terminal Operations Senior Technical Officer",
  "Terminal Operations Technical Officer",
  "Terminal Operations Technician",
  "Town Gas Plant Maintenance Engineer",
  "Town Gas Plant Maintenance Manager",
  "Town Gas Plant Maintenance Senior Technical Officer",
  "Town Gas Plant Maintenance Technical Officer",
  "Town Gas Plant Maintenance Technician",
  "Town Gas Production Engineer",
  "Town Gas Production Manager",
  "Town Gas Production Senior Technical Officer",
  "Town Gas Production Technical Officer",
  "Town Gas Production Technician",
  "Installation, Inspection and Servicing Engineer",
  "Installation, Inspection and Servicing Manager",
  "Installation, Inspection and Servicing Senior Technical Officer",
  "Installation, Inspection and Servicing Technical Officer",
  "Project Coordination Engineer",
  "Project Coordination Manager",
  "Project Coordination Senior Technical Officer / Project Coordination Technical Officer",
  "Town Gas Technical Services Department Manager",
  "Assistant Engineer / Senior Technician (Commissioning)",
  "Assistant Engineer / Senior Technician (Engineering Construction)",
  "Engineer / Supervisor (Commissioning)",
  "Engineer / Supervisor (Engineering Construction)",
  "Principal Engineer / Manager (Commissioning)",
  "Principal Engineer / Manager (Engineering Construction)",
  "Senior Engineer / Assistant Manager (Commissioning)",
  "Senior Engineer / Assistant Manager (Engineering Construction)",
  "Technician / Coordinator (Commissioning)",
  "Technician / Coordinator (Engineering Construction)",
  "Director (Engineering)",
  "Chief Executive Officer / Managing Director / General Manager / President",
  "Designer (Engineering Design)",
  "Engineer (Engineering Design)",
  "Junior Designer (Engineering Design)",
  "Principal Engineer / Manager (Engineering Design)",
  "Senior Engineer (Engineering Design)",
  "Assistant Engineer / Officer (Engineering Procurement)",
  "Engineer (Engineering Procurement)",
  "Manager (Engineering Procurement)",
  "Senior Engineer (Engineering Procurement)",
  "Assistant Engineer / Senior Technician (Operations and Maintenance)",
  "Director (Operations and Maintenance)",
  "Engineer / Supervisor (Operations and Maintenance)",
  "Principal Engineer / Manager (Operations and Maintenance)",
  "Senior Engineer / Assistant Manager (Operations and Maintenance)",
  "Technician / Coordinator (Operations and Maintenance)",
  "Assistant Engineer / Officer (Project Development)",
  "Assistant Manager / Senior Engineer (Project Development)",
  "Engineer (Project Development)",
  "Manager (Project Development)",
  "Director (Project Financing)",
  "Executive (Project Financing)",
  "Manager (Project Financing)",
  "Senior Executive (Project Financing)",
  "Cleaning Operations Executive",
  "Cleaning Operations Manager",
  "Cleaning Supervisor",
  "General Cleaner",
  "Multi-Skilled Cleaner / Team Leader",
  "Operations Director / General Manager",
  "Environment, Health and Safety Manager",
  "Environment, Health and Safety Specialist",
  "Waste Recycling Executive / Waste Recovery Executive",
  "Waste Recycling Machine Operator",
  "Waste Recycling Manager / Waste Recovery Manager",
  "Waste Recycling Sorter",
  "Waste Recycling Supervisor / Waste Recovery Supervisor",
  "Pest Control Manager",
  "Pest Control Supervisor",
  "Pest Control Technician",
  "Pest Control Worker",
  "Waste Treatment Executive / Waste Disposal Executive",
  "Waste Treatment Plant Manager",
  "Waste Treatment Supervisor / Waste Disposal Supervisor",
  "Waste Treatment Worker / Waste Disposal Worker",
  "Mechanical Operator / Waste and Recyclables Collection Truck Driver",
  "Waste and Recyclables Collection Attendant",
  "Waste and Recyclables Collection Executive",
  "Waste and Recyclables Collection Manager",
  "Waste and Recyclables Collection Supervisor",
  "Weighbridge Operator",
  "Weighbridge Supervisor",
  "Business Process Improvement Executive",
  "Customer Experience Executive / User Experience Executive",
  "Customer Experience Manager / User Experience Manager",
  "Data Analyst",
  "Data Engineer",
  "Data Scientist",
  "Digital Transformation Executive",
  "Digital Transformation Manager",
  "Head of Customer Experience / Head of User Experience",
  "Head of Data Analytics",
  "Head of Digital Transformation",
  "Head of Innovation and Process Excellence",
  "Innovation and Process Excellence Manager",
  "Innovation Executive",
  "Family Office Executive",
  "Family Office Management Professional",
  "Account Operations Analyst",
  "Account Operations Manager",
  "Claims Appraiser / Claims Examiner / Claims Executive",
  "Claims Manager",
  "Client Investment Performance and Reporting Analyst",
  "Credit and Lending Operations Analyst",
  "Credit and Lending Operations Manager",
  "Head of Claims",
  "Head of Client Investment Performance and Reporting",
  "Head of Know Your Customer / Customer Due Diligence / Head of Client Lifecycle",
  "Head of Operations",
  "Head of Operations Risk and Control",
  "Head of Underwriting",
  "Know Your Customer / Customer Due Diligence Analyst",
  "Know Your Customer / Customer Due Diligence Manager",
  "Operations Risk and Control Analyst",
  "Operations Risk and Control Manager",
  "Transaction Banking Operations Analyst",
  "Transaction Banking Operations Manager",
  "Treasury Operations Analyst / Market Operations Analyst",
  "Treasury Operations Manager / Market Operations Manager",
  "Underwriting Executive",
  "Underwriting Manager",
  "Assistant Wealth Planner",
  "Client Portfolio Analyst",
  "Client Portfolio Manager",
  "Economist",
  "Head of Portfolio Management",
  "Head of Product Management",
  "Head of Product Marketing",
  "Head of Reserving and Pricing Actuarial",
  "Head of Segments",
  "Head of Strategy / Head of Investment Strategy",
  "Head of Trust Administration",
  "Head of Wealth Planning / Director of Wealth Planning",
  "Portfolio Analyst / Investment Analyst / Fund Management Assistant",
  "Portfolio Manager / Investment Manager / Fund Manager",
  "Pricing Actuarial Executive",
  "Product Analyst",
  "Product Development Analyst",
  "Product Development Manager",
  "Product Marketing Executive",
  "Product Marketing Manager",
  "Research Analyst",
  "Reserving Actuarial Executive",
  "Reserving and Pricing Actuarial Manager",
  "Segment Executive",
  "Segment Manager",
  "Senior Economist",
  "Trust Administrator",
  "Trust Manager",
  "Wealth Planner",
  "Compliance Advisory Executive",
  "Compliance Advisory Manager",
  "Compliance Analyst",
  "Credit Risk Analyst",
  "Credit Risk Manager",
  "Financial Crime Compliance Executive",
  "Financial Crime Compliance Manager",
  "Head of Compliance",
  "Head of Credit Risk Management",
  "Head of Financial Crime Compliance",
  "Head of Legal",
  "Head of Market and Liquidity Risk Management",
  "Head of Operational Risk Management",
  "Head of Risk Analytics / Head of Compliance Analytics",
  "Head of Risk Strategy",
  "Head of Technology, Information and Cybersecurity Risk Management",
  "Legal Counsel",
  "Market and Liquidity Risk Analyst",
  "Market and Liquidity Risk Manager",
  "Monitoring, Surveillance and Testing Executive",
  "Monitoring, Surveillance and Testing Manager",
  "Operational Risk Analyst",
  "Operational Risk Assistant",
  "Operational Risk Manager",
  "Paralegal / Legal Executive",
  "Risk Analytics Analyst / Compliance Analytics Analyst",
  "Risk Analytics Manager / Compliance Analytics Manager",
  "Risk Strategy Manager",
  "Technology, Information and Cybersecurity Risk Analyst",
  "Technology, Information and Cybersecurity Risk Manager",
  "Agency Director / Segment Lead",
  "Agency Manager / Team Lead",
  "Assistant Relationship Manager - Private Banking",
  "Assistant Relationship Manager - Retail Banking / Personal Banking Executive",
  "Assistant Relationship Manager / Relationship Associate",
  "Branch Manager",
  "Broker / Business Development Executive - Brokers",
  "Business Development Executive / Distribution Executive / Channel Executive / Partnerships and Affinity Management Executive",
  "Business Development Manager - Brokers",
  "Business Development Manager / Distribution Manager / Channel Manager / Partnerships and Affinity Management Manager",
  "Business Manager",
  "Claims Liaison Executive",
  "Claims Liaison Manager",
  "Client Executive / Account Management Executive",
  "Client Implementation Analyst",
  "Client Implementation Manager",
  "Client Service Manager / Client Support Service Manager",
  "Client Service Officer / Client Support Service Officer",
  "Client Support Manager / Account Manager",
  "Customer Service Manager",
  "Customer Service Officer / Bank Teller",
  "Family Office Advisor - Private Banking",
  "Financial Planner / Insurance Agent / Bancassurance Specialist",
  "Head of Branch Management",
  "Head of Business Development - Brokers",
  "Head of Business Development / Head of Distribution / Head of Channel / Head of Partnerships and Affinity Management",
  "Head of Business Management",
  "Head of Claims Liaison",
  "Head of Client Implementation",
  "Head of Client Management / Head of Account Management",
  "Head of Client Service / Client Support Services Director",
  "Head of Customer Service",
  "Head of Placement",
  "Head of Product Desk / Head of Product Sales",
  "Head of Product Origination and Structuring",
  "Head of Sales and Distribution / Head of Coverage",
  "Investment Counsellor",
  "Investment Counsellor Assistant",
  "Investment Counsellor Team Lead",
  "Placement Executive",
  "Placement Manager",
  "Product Origination and Structuring Analyst",
  "Product Origination and Structuring Manager",
  "Product Specialist / Product Sales Specialist",
  "Relationship Management Director - Commercial",
  "Relationship Management Director - Corporate and Large Multi-National Companies",
  "Relationship Management Director - Financial Institutions and Non-Bank Financial Institutions",
  "Relationship Management Director - Private Banking / Team Leader",
  "Relationship Management Director - Retail / Head of Personal Banking",
  "Relationship Management Director - Small and Medium Enterprises",
  "Relationship Manager - Commercial",
  "Relationship Manager - Corporate and Large Multi-National Companies",
  "Relationship Manager - Financial Institutions and Non-Bank Financial Institutions",
  "Relationship Manager - Private Banking",
  "Relationship Manager - Retail Banking / Personal Banking Manager",
  "Relationship Manager - Small and Medium Enterprises",
  "Sales and Distribution Specialist / Coverage Officer",
  "Execution Trader",
  "Head of Trading Desk / Head of Trading Floor / Head of Dealing / Head of Execution",
  "Quantitative Trader",
  "Trader",
  "Assistant Market Development Manager / Market Development Executive",
  "General Manager",
  "Marketing Development Manager",
  "Regional Manager / Export Manager",
  "Chief Executive / Managing Director",
  "Assistant Engineer / Assistant Maintenance Manager",
  "Director of Operations / Plant Manager",
  "Engineer / Engineering Manager / Maintenance Manager",
  "Process Technician / Maintenance Technician",
  "Production Operator",
  "Section Head / Team Leader / Line Operator",
  "Supervisor / Production Planner",
  "Quality Assurance and Quality Control Manager",
  "Quality Assurance and Quality Control Specialist / Food Safety Specialist / Laboratory Manager",
  "Quality Assurance and Quality Control Supervisor / Quality Assurance and Quality Control Executive / Laboratory Supervisor",
  "Quality Control Technician / Quality Control Assistant / Laboratory Technician",
  "Assistant Food Technologist",
  "Chief Research and Development Officer",
  "Food Technologist",
  "Product Technologist / Innovation Manager",
  "Research and Development Chef",
  "Barista",
  "Barista Supervisor / Senior Barista",
  "Bartender",
  "Bartender Supervisor",
  "Group Beverage Manager",
  "Head Barista",
  "Head Bartender",
  "Head Sommelier / Sommelier",
  "Operations Director / Operations Manager",
  "Wine Server / Wine Waiter / Commis Sommelier",
  "Wine Specialist / Demi Sommelier",
  "Assistant Head Chef",
  "Cook / Kitchen Assistant",
  "Executive Chef",
  "Executive Sous Chef",
  "Head Chef",
  "Senior Cook / Station Chef",
  "Executive / Service Supervisor",
  "Host / Hostess / Crew Leader",
  "Multi-Outlet Manager",
  "Outlet Manager / Assistant Outlet Manager",
  "Server / Service Crew / Runner",
  "Assistant Pastry Chef",
  "Assistant Pastry Cook / Assistant Baker / Kitchen Assistant",
  "Baker",
  "Chief Executive Officer / Managing Director",
  "Executive Pastry Chef",
  "Head Baker",
  "Pastry Chef",
  "Pastry Cook",
  "Genetic Counselling Associate",
  "Assistant Director of Nursing (Clinical)",
  "Assistant Director of Nursing (Education)",
  "Assistant Director of Nursing (Management)",
  "Assistant Nurse Clinician",
  "Chief Nurse",
  "Deputy Director of Nursing (Clinical)",
  "Deputy Director of Nursing (Education)",
  "Deputy Director of Nursing (Management)",
  "Director of Nursing (Clinical)",
  "Director of Nursing (Education)",
  "Director of Nursing (Management)",
  "Enrolled Nurse",
  "Healthcare Assistant / Basic Care Assistant / Nursing Aide",
  "Nurse Clinician",
  "Nurse Educator",
  "Nurse Manager",
  "Principal Enrolled Nurse",
  "Senior Enrolled Nurse",
  "Senior Healthcare Assistant / Basic Care Assistant / Nursing Aide",
  "Senior Nurse Clinician",
  "Senior Nurse Educator",
  "Senior Nurse Manager",
  "Senior Staff Nurse",
  "Staff Nurse",
  "Occupational Therapist",
  "Principal Occupational Therapist (Clinical)",
  "Principal Occupational Therapy Educator",
  "Principal Occupational Therapy Manager",
  "Principal Occupational Therapy Researcher",
  "Senior Occupational Therapist",
  "Senior Principal Occupational Therapist (Clinical)",
  "Senior Principal Occupational Therapy Educator",
  "Senior Principal Occupational Therapy Manager",
  "Senior Principal Occupational Therapy Researcher",
  "Patient Service Assistant Supervisor",
  "Patient Service Associate",
  "Patient Service Executive",
  "Patient Service Senior Associate",
  "Patient Service Supervisor",
  "Pharmacy Technician",
  "Pharmacy Technician Executive (Drug Compounding and Quality Management)",
  "Pharmacy Technician Executive (Patient Care Services)",
  "Pharmacy Technician Executive (Pharmacy IT and Supply Chain)",
  "Senior Pharmacy Technician",
  "Senior Pharmacy Technician Executive (Drug Compounding and Quality Management)",
  "Senior Pharmacy Technician Executive (Patient Care Services)",
  "Senior Pharmacy Technician Executive (Pharmacy IT and Supply Chain)",
  "Physiotherapist",
  "Principal Physiotherapist (Clinical)",
  "Principal Physiotherapy Educator",
  "Principal Physiotherapy Manager",
  "Principal Physiotherapy Researcher",
  "Senior Physiotherapist",
  "Senior Principal Physiotherapist (Clinical)",
  "Senior Principal Physiotherapy Educator",
  "Senior Principal Physiotherapy Manager",
  "Senior Principal Physiotherapy Researcher",
  "Emergency Medical Technician",
  "Principal Speech Therapist (Clinical)",
  "Principal Speech Therapy Educator",
  "Principal Speech Therapy Manager",
  "Principal Speech Therapy Researcher",
  "Senior Principal Speech Therapist (Clinical)",
  "Senior Principal Speech Therapy Educator",
  "Senior Principal Speech Therapy Manager",
  "Senior Principal Speech Therapy Researcher",
  "Senior Speech Therapist",
  "Speech Therapist",
  "Senior Therapy Assistant",
  "Therapy Assistant",
  "Therapy Support Associate",
  "Assistant Concierge",
  "Assistant Front Office Manager / Assistant Operations Manager / Duty Manager / Guest Services Manager / Guest Relations Manager",
  "Bell Captain",
  "Bellhop / Bell Attendant",
  "Butler / Club Floor Officer / Club Floor Agent",
  "Butler Supervisor / Club Floor Executive / Club Floor Supervisor",
  "Chef Concierge / Assistant Chef Concierge",
  "Concierge",
  "Front Office Executive / Guest Services Executive / Guest Relations Executive / Front Office Supervisor / Guest Services Supervisor / Guest Relations Supervisor",
  "Front Office Manager / Operations Manager",
  "Front Office Officer / Guest Services Officer / Guest Relations Officer / Front Office Agent / Guest Services Agent / Guest Relations Agent",
  "Head Butler / Butler Manager / Club Floor Manager",
  "Hotel Manager / Resident Manager / Executive Assistant Manager",
  "Rooms Division Manager / Director of Rooms",
  "Assistant Executive Housekeeper",
  "Assistant Housekeeper / Assistant Housekeeping Manager / Housekeeping Supervisor",
  "Executive Housekeeper / Director of Housekeeping",
  "Housekeeping Attendant / Room Attendant / Public Area Attendant",
  "Housekeeping Coordinator",
  "Laundry Manager",
  "Linen Room Attendant / Laundry Valet Attendant",
  "Linen Room Supervisor",
  "Director of Revenue Management",
  "Reservations Executive / Reservations Supervisor",
  "Reservations Manager / Assistant Reservations Manager",
  "Reservations Officer / Reservations Agent",
  "Revenue Manager / Assistant Revenue Manager",
  "Revenue Specialist / Revenue Analyst",
  "Director of Marketing Communications / Director of Public Relations / Assistant Director of Marketing Communications / Assistant Director of Public Relations",
  "Director of Sales / Director of Catering / Assistant Director of Sales / Assistant Director of Catering",
  "Director of Sales and Marketing",
  "Marketing Communications Executive / Digital Marketing Executive / Public Relations Executive",
  "Marketing Communications Manager / Digital Marketing Manager / Public Relations Manager / Assistant Marketing Communications Manager / Assistant Digital Marketing Manager / Assistant Public Relations Manager",
  "Marketing Communications Officer / Digital Marketing Officer / Sales and Marketing Officer",
  "Sales Coordinator / Catering Sales Coordinator",
  "Sales Executive / Catering Sales Executive",
  "Sales Manager / Catering Sales Manager / Assistant Sales Manager / Assistant Catering Sales Manager",
  "Head, Employee Experience and Relations",
  "Manager, Employee Experience and Relations",
  "Head, HR Business Partner",
  "HR Business Partner / HR Manager",
  "Head, Learning and Organisation Development",
  "Manager, Learning and Organisation Development",
  "Head, Operations and Technology",
  "Manager, Operations and Technology",
  "Chief Human Resource Officer",
  "Associate, Performance and Rewards",
  "Executive, Performance and Rewards",
  "Head, Performance and Rewards",
  "Manager, Performance and Rewards",
  "Head, Talent Attraction",
  "Manager, Talent Attraction",
  "Associate, Talent Attraction and Employee Experience and Relations",
  "Executive, Talent Attraction and Employee Experience and Relations",
  "Head, Talent Management",
  "Manager, Talent Management",
  "Associate, Talent Management and Learning and Organisational Development",
  "Executive, Talent Management and Learning and Organisational Development",
  "Associate Security Analyst",
  "Chief Information Security Officer",
  "Cyber Risk Analyst",
  "Cyber Risk Manager",
  "Forensics Investigation Manager",
  "Forensics Investigator",
  "Incident Investigation Manager",
  "Incident Investigator",
  "Security Architect",
  "Security Operations Analyst",
  "Security Operations Manager",
  "Senior Security Engineer / Security Engineer",
  "Threat Analysis Manager",
  "Vulnerability Assessment and Penetration Testing Analyst",
  "Vulnerability Assessment and Penetration Testing Manager",
  "Artificial Intelligence / Machine Learning Engineer",
  "Artificial Intelligence Applied Researcher",
  "Business Intelligence Director",
  "Business Intelligence Manager",
  "Chief Data Officer / Chief Artificial Intelligence Officer",
  "Data Analyst / Associate Data Engineer",
  "Data Architect",
  "Data Scientist / Artificial Intelligence Scientist",
  "Head of Data Science and Artificial Intelligence",
  "Senior Artificial Intelligence / Machine Learning Engineer",
  "Senior Data Engineer",
  "Associate Infrastructure Engineer",
  "Associate Network Engineer",
  "Associate Radio Frequency Engineer",
  "Automation and Orchestration Engineer",
  "Head of Infrastructure",
  "Infrastructure Architect",
  "Infrastructure Engineer",
  "Infrastructure Engineering Manager",
  "Network Engineer",
  "Radio Frequency Engineer",
  "SysOps Engineer",
  "Chief Information Officer",
  "Applications Support Engineer",
  "Associate Applications Support Engineer",
  "Associate Data Centre Operations Engineer",
  "Associate Database Support Engineer",
  "Associate Infrastructure Support Engineer",
  "Associate Operations Centre Support Engineer",
  "Associate Systems Support Engineer",
  "Data Centre Operations Engineer",
  "Database Support Engineer",
  "Head of Operations and Support",
  "Infrastructure Support Engineer",
  "Operations and Support Manager",
  "Operations Centre Support Engineer",
  "Systems Support Engineer",
  "Associate Product Analyst",
  "Associate Product Designer",
  "Associate Product Manager",
  "Back End Developer",
  "Chief Product Officer",
  "Chief Technology Officer",
  "Front End Developer",
  "Full Stack Developer",
  "Head of Product",
  "Head of Product Analytics",
  "Head of Product Design",
  "Head of Quality & Risk",
  "Head of Software Engineering",
  "Lead Product Analyst",
  "Lead Product Designer",
  "Lead Product Manager",
  "Product Designer",
  "Product Risk Analyst",
  "Product Risk Manager",
  "Product Security Engineer",
  "Product Security Engineering Manager",
  "Quality Assurance Engineer",
  "Quality Engineering Manager",
  "Senior Back End Developer",
  "Senior Front End Developer",
  "Senior Full Stack Developer",
  "Site Reliability Engineer",
  "Site Reliability Engineering Manager",
  "Software Architect",
  "Software Engineering Manager",
  "Business Development Manager",
  "Channel Sales Manager",
  "Customer Success Director",
  "Customer Success Manager",
  "Head of Sales",
  "Marketing Director",
  "Marketing Executive",
  "Pre-Sales Consultant",
  "Pre-Sales Director",
  "Sales Account Manager",
  "Sales Director",
  "Sales Executive",
  "Associate Embedded Systems Engineer",
  "Associate Software Engineer",
  "Associate UI Designer",
  "DevOps Engineer",
  "Embedded Systems Engineer",
  "Embedded Systems Engineering Manager",
  "Lead UI Designer",
  "Software Engineer",
  "UI Designer",
  "Associate Business Analyst",
  "Associate UX Designer",
  "Business Analyst / Artificial Intelligence Translator",
  "Business Architect",
  "Data Protection Executive",
  "Data Protection Officer",
  "Enterprise Architect",
  "Group Data Protection Officer",
  "Head of IT Audit",
  "Head of Quality",
  "IT Audit Manager",
  "IT Auditor",
  "Lead UX Designer",
  "Program Director",
  "Program Manager",
  "Project Manager / Scrum Master",
  "Senior Product Manager",
  "Solutions Architect",
  "UX Designer",
  "Partner / Director / Counsel",
  "Senior IP Legal Associate / IP Legal Associate",
  "IP Paralegal / IP Executive",
  "IP Commercialisation Associate / IP Commercialisation Analyst",
  "IP Director / IP Strategist",
  "Senior IP Consultant / Senior IP Commercialisation Manager / IP Consultant / IP Commercialisation Manager",
  "Senior IP Manager / IP Manager",
  "Senior IP Paralegal / IP Management Associate",
  "Partner / Director / Principal",
  "Patent Attorney Trainee / Patent Agent Trainee",
  "Senior Patent Attorney / Senior Patent Agent / Patent Attorney / Patent Agent",
  "Arboriculture Supervisor / Arboriculture Team Leader",
  "Arboriculture Technician",
  "Arborist / Arboricultural Consultant",
  "Landscape Worker (Nursery Work, Arboriculture)",
  "Landscape Manager",
  "Landscape Supervisor / Landscape Assistant Supervisor / Landscape Team Leader",
  "Landscape Technician",
  "Landscape Architect",
  "Landscape Designer",
  "Principal Landscape Architect",
  "Principal Landscape Designer",
  "Managing Director",
  "Landscape Project Coordinator",
  "Landscape Project Manager",
  "Nursery Manager",
  "Nursery Supervisor / Nursery Team Leader",
  "Nursery Technician",
  "Brokerage Supervisor / Freight Supervisor",
  "Freight Inspector / Incoming Quality Inspector",
  "Freight Officer",
  "Import Export Administrative Assistant / Shipping Assistant",
  "Import Export Manager / Freight Allocation Manager / Freight Shipping Manager / Freight Documentation Manager / Freight Pricing Manager",
  "Incoming Quality Coordinator / Tally Assistant",
  "Permit Coordinator / Shipping Coordinator / Custom Clearance Coordinator",
  "Shipping Specialist / Import Export Specialist / Freight Executive",
  "Chief Executive Officer / Chief Operating Officer / Managing Director / General Manager",
  "Business Process Excellence Engineer / Operations Specialist / Industrial Operations Engineer",
  "IT Business Solutions Manager / Digital Services Manager",
  "IT Business Solutions Project Specialist / Digital Services Project Specialist",
  "Logistics Data Management Coordinator / Logistics Data Entry Coordinator",
  "Logistics Data Specialist / Master Data Analyst / Master Data Executive",
  "Logistics Innovation and Process Improvement Manager / Customer and Logistics Systems Manager",
  "Logistics IT Executive / Digital Services Executive / Logistics System Analyst",
  "Operations Integration Specialist / System Integration Engineer / Infrastructure Specialist",
  "Logistics Contracts Analyst / Logistics Operations Analyst",
  "Logistics Contracts Executive / Logistics Operations Executive / Logistics Operations Specialist",
  "Logistics Contracts Manager / Logistics Programme Manager",
  "Logistics Solutions Analyst",
  "Logistics Solutions and Implementation Director / Tailored Supply Chain Director / Channel Operations Director",
  "Logistics Solutions Manager",
  "Logistics Solutions Specialist / Logistics Solutions Engineer",
  "Vertical Product Manager",
  "Vertical Specialist",
  "Business Development Director / Country Route Development Director / Trade Lane Director / Freight Trade Director",
  "Business Development Manager / Sales and Marketing Manager / Vertical Sales Account Manager / Key Account Manager / Project Cargo Sales Manager / Route Development Manager / Trade Lane Manager",
  "Customer Service Officer / Service Quality Analyst",
  "Field Sales Executive / Key Account Executive / Sales Operations Management Specialist",
  "Order Fulfilment Assistant / Sales Operations Management Assistant / Customer Service Assistant",
  "Order Fulfilment Coordinator / Sales Operations Management Coordinator / Customer Service Coordinator",
  "Order Management Executive / Quote Desk Executive / Order Fulfilment Officer / Inside Sales Officer",
  "Senior Customer Service Officer / Customer Service Specialist",
  "Depot Supervisor / Traffic Supervisor / Dispatch Supervisor / Hub Operations Supervisor",
  "Dispatch Operator / Transport Operator / Last Mile Delivery Driver / Container Driver",
  "Lifting Supervisor",
  "Project Engineer",
  "Project Executive",
  "Project Supervisor",
  "Rigger / Signalman",
  "Senior Project Engineer",
  "Traffic Controller / Transport Officer / Line Haul Operations Officer",
  "Traffic Coordinator / Dispatch Coordinator",
  "Transport Assistant Manager / Transport Executive / Line Haul Operations Executive",
  "Transportation Operations Manager",
  "Health, Safety and Environmental Coordinator",
  "Health, Safety and Environmental Manager",
  "Health, Safety and Environmental Officer",
  "Material Handling Equipment (MHE) Operator / Forklift Operator / Warehouse Assistant / Inventory Assistant",
  "Warehouse Officer / Inventory Controller / Quality Control Officer / Warehouse Facilities Management Officer",
  "Warehouse Operations Executive / Inventory Management Executive / Warehouse Assistant Manager",
  "Warehouse Operations Manager / Inventory Management Manager / Capacity Management Manager",
  "Warehouse Storekeeper / Inventory Coordinator / Logistics Coordinator",
  "Warehouse Supervisor / Inventory Control Supervisor / Quality Control Supervisor",
  "Assistant Design Engineer",
  "Design Section Manager / Head of Department",
  "Chief Engineer / Chief Technology Officer",
  "Chief Executive Officer / General Manager / President",
  "Deputy General Manager / Chief Operating Officer",
  "Operations Manager",
  "Assistant Production Engineer",
  "Assistant Quality Assurance / Quality Control Engineer",
  "Master Trade Specialist / Senior Trade Specialist / Trade Specialist",
  "Procurement Coordinator / Procurement Executive",
  "Procurement Manager",
  "Production Engineer",
  "Production Section Manager / Head of Department",
  "Quality Assurance / Quality Control Engineer",
  "Quality Assurance / Quality Control Manager",
  "Senior Procurement Executive",
  "Senior Production Engineer",
  "Senior Quality Assurance / Quality Control Engineer",
  "Supervisor / Chargehand / Foreman",
  "Senior Workplace Safety and Health Officer",
  "Workplace Safety and Health Coordinator",
  "Workplace Safety and Health Manager",
  "Workplace Safety and Health Officer",
  "Post-Production Assistant",
  "Post-Production Supervisor",
  "Sound Editor / Sound Engineer",
  "Supervising Sound Editor (Sound Mixing)",
  "Video Editor (Colour Grading, Online Editing)",
  "Assistant Director",
  "Assistant Producer - Broadcast (Casting)",
  "Assistant Producer - Film (Casting)",
  "Assistant Producer - News / Current Affairs",
  "Chief Editor",
  "Director (Single Camera Production, Multiple Camera Production)",
  "Executive Editor / Executive Producer - News and Current Affairs",
  "Executive Producer - Broadcast",
  "Executive Producer - Film",
  "Head Scriptwriter",
  "Junior Scriptwriter / Junior Writer",
  "Line Producer - Film",
  "Producer - Broadcast (Casting)",
  "Producer - Film (Casting)",
  "Production Assistant",
  "Production Manager (Location Scouting)",
  "Reporter / Correspondent - News",
  "Scriptwriter / Writer",
  "Senior Anchor / Senior Presenter / Anchor / Presenter - News",
  "Senior Producer / Producer - Current Affairs",
  "Senior Producer / Producer - News",
  "Senior Reporter / Senior Correspondent - News (Visual Journalism)",
  "Game Director",
  "Junior Designer",
  "Lead Game Designer",
  "Senior Game Designer",
  "Senior Level Designer",
  "Assistant Producer - Games",
  "Producer - Games",
  "Game Technical Director",
  "Junior Programmer",
  "Lead Game Programmer",
  "Lead Technical Artist",
  "Senior Engine Programmer (Tools Programming)",
  "Senior Game Programmer",
  "Senior Server Programmer",
  "Technical Artist",
  "Analyst - Analytics and Customer Insights",
  "Community Development Executive",
  "Community Development Specialist",
  "Executive - Content Acquisition",
  "Executive - Content Commissioning",
  "Executive - Localisation",
  "Executive - Programme Planning and Scheduling",
  "Executive - Standards and Practices",
  "Head - Analytics and Customer Insights",
  "Head - Content",
  "Head - Content Acquisition",
  "Head - Content Commissioning",
  "Head - Programme Planning and Scheduling",
  "Head of Marketing / Marketing Director",
  "Head of Sales / Sales Director",
  "Manager - Analytics and Customer Insights",
  "Manager - Content Acquisition",
  "Manager - Content Commissioning",
  "Manager - Localisation",
  "Manager - Programme Planning and Scheduling",
  "Manager - Standards and Practices",
  "Sales Manager",
  "Senior Executive - Product Management",
  "Digital Asset Librarian",
  "Digital Asset Manager",
  "Engineer - Linear Media Infrastructure",
  "Executive - On-Demand Media Technology and Operations",
  "Head - Technology and Operations",
  "Manager - Linear Media Infrastructure",
  "Manager - Linear Media Operations",
  "Manager - On-Demand Media Technology and Operations",
  "Operator - Linear Media Operations",
  "Senior Operator - Linear Media Operations",
  "Technician - Linear Media Infrastructure",
  "1st Assistant Cameraman / Focus Puller (Specialty Camera Operation)",
  "2nd Assistant Cameraman / Clapper / Loader (Data Wrangling)",
  "Art Department Assistant / Set Dresser",
  "Art Director",
  "Boom Operator",
  "Camera Operator (Specialty Camera Operation, Electronic News Gathering and Electronic Field Production Operation)",
  "Director of Photography (Specialty Camera Operation)",
  "Floor Manager",
  "Gaffer",
  "Game Sound Designer",
  "Grip / Lighting Technician",
  "Junior Grip / Junior Lighting Technician",
  "Key Grip",
  "Senior Game Sound Designer",
  "Set Designer (Set Decoration, Props Management)",
  "Sound Recordist",
  "Studio Director / Outside Broadcast Director",
  "Studio Technical Director",
  "Technical Support Operator (Video Tape Operations and / or Video Technical Operations, Camera Control Unit Operations, Studio Graphics Operations)",
  "Vision Mixer / Switcher",
  "Lead Quality Assurance Tester",
  "Quality Assurance Tester",
  "2D Artist (Concept Art / Background Art / Character Art, Storyboarding)",
  "3D Artist (Modelling / Rigging / Texturing, Digital Lighting, Digital Compositing)",
  "Animator",
  "Creative Director / Creative Supervisor / 3D Art Supervisor",
  "Lead 2D Artist",
  "Lead 3D Artist (Live-action Visual Effects)",
  "Lead Animator",
  "Senior 2D Artist (Concept Art / Background Art / Character Art, Storyboarding)",
  "Senior 3D Artist (Modelling / Rigging / Texturing, Digital Lighting, Digital Compositing)",
  "Senior Animator",
  "Assistant Manufacturing Manager / Section Manager",
  "Chief Executive Officer / Chief Operating Officer / Chief Technology Officer / Managing Director / General Manager / Vice-President",
  "Manufacturing Manager / Operations Manager / Production Manager",
  "Plant Manager",
  "Assistant Engineer / Associate Engineer",
  "Automation Coordinator / Robot Coordinator",
  "Chief Engineer",
  "Engineer",
  "Machinist / Technician",
  "Master Craftsman",
  "Process Specialist / Shift Leader / Team Leader",
  "Product Engineer / Product Designer",
  "Senior Engineer",
  "Senior Machinist / Senior Technician",
  "Deputy Workshop Manager",
  "Master Technical Specialist",
  "Section Supervisor / Foreman",
  "Senior Technical Specialist",
  "Senior Technician",
  "Technical Specialist",
  "Workshop Manager",
  "Workshop Supervisor / Senior Foreman",
  "Bus Captain",
  "Bus Operations Control Centre Manager",
  "Chief Bus Captain / Master Bus Captain",
  "Chief Bus Controller / Bus Operations Control Centre Controller",
  "Depot Operations Manager / Depot Manager",
  "Deputy Bus Operations Control Centre Manager",
  "Interchange Manager",
  "Senior Bus Captain",
  "Senior Depot Officer / Senior Depot Assistant / Depot Officer / Depot Assistant",
  "Senior Depot Supervisor / Depot Supervisor",
  "Senior Interchange Officer / Senior Interchange Assistant / Interchange Officer / Interchange Assistant",
  "Senior Interchange Supervisor / Interchange Supervisor",
  "Chief Engineer / Senior Engineering Manager (Automatic Fare Collection)",
  "Chief Engineer / Senior Engineering Manager (Engineering Train)",
  "Chief Engineer / Senior Engineering Manager (Mechanical and Electrical)",
  "Chief Engineer / Senior Engineering Manager (Permanent Way and Civil Structure)",
  "Chief Engineer / Senior Engineering Manager (Power)",
  "Chief Engineer / Senior Engineering Manager (Rolling Stock)",
  "Chief Engineer / Senior Engineering Manager (Signal and Communications)",
  "Engineering Head (Automatic Fare Collection)",
  "Engineering Head (Mechanical and Electrical)",
  "Engineering Head (Permanent Way and Civil Structure)",
  "Engineering Head (Power)",
  "Engineering Head (Rolling Stock / Engineering Train)",
  "Engineering Head (Signal and Communications)",
  "Principal Engineer / Engineering Manager (Automatic Fare Collection)",
  "Principal Engineer / Engineering Manager (Engineering Train)",
  "Principal Engineer / Engineering Manager (Mechanical and Electrical)",
  "Principal Engineer / Engineering Manager (Permanent Way and Civil Structure)",
  "Principal Engineer / Engineering Manager (Power)",
  "Principal Engineer / Engineering Manager (Rolling Stock)",
  "Principal Engineer / Engineering Manager (Signal and Communications)",
  "Senior Assistant Engineer / Assistant Engineer (Automatic Fare Collection)",
  "Senior Assistant Engineer / Assistant Engineer (Engineering Train)",
  "Senior Assistant Engineer / Assistant Engineer (Mechanical and Electrical)",
  "Senior Assistant Engineer / Assistant Engineer (Permanent Way and Civil Structure)",
  "Senior Assistant Engineer / Assistant Engineer (Power)",
  "Senior Assistant Engineer / Assistant Engineer (Rolling Stock)",
  "Senior Assistant Engineer / Assistant Engineer (Signal and Communications)",
  "Senior Engineer / Engineer (Automatic Fare Collection)",
  "Senior Engineer / Engineer (Engineering Train)",
  "Senior Engineer / Engineer (Mechanical and Electrical)",
  "Senior Engineer / Engineer (Permanent Way and Civil Structure)",
  "Senior Engineer / Engineer (Power)",
  "Senior Engineer / Engineer (Rolling Stock)",
  "Senior Engineer / Engineer (Signal and Communications)",
  "Senior Technician (Automatic Fare Collection)",
  "Senior Technician (Engineering Train)",
  "Senior Technician (Mechanical and Electrical)",
  "Senior Technician (Permanent Way and Civil Structure)",
  "Senior Technician (Power)",
  "Senior Technician (Rolling Stock)",
  "Senior Technician (Signal and Communications)",
  "Technician (Automatic Fare Collection)",
  "Technician (Engineering Train)",
  "Technician (Mechanical and Electrical)",
  "Technician (Permanent Way and Civil Structure)",
  "Technician (Power)",
  "Technician (Rolling Stock)",
  "Technician (Signal and Communications)",
  "Chief Controller / Controller",
  "Communication Controller",
  "Crew Manager",
  "Customer Service Officer / Rover",
  "Depot Train Controller",
  "Head, Operations Control Centre",
  "Head, Train Operations / Head, Station Operations / Head, Passenger Services",
  "Operations Control Centre Manager",
  "Rail Service Manager",
  "Senior Assistant Station Manager / Assistant Station Manager",
  "Senior Station Manager / Station Manager / Deputy Station Manager",
  "Senior Train Captain / Train Captain",
  "Service Operations Manager / Duty Operations Manager",
  "Station Operations Manager",
  "Train Operations Manager",
  "Train Service Controller / Depot Traffic Controller",
  "Brand Associate / Assistant",
  "Brand Director",
  "Brand Executive / Customer Loyalty Executive",
  "Brand Manager / Customer Loyalty Manager",
  "E-Commerce Associate / Assistant",
  "E-Commerce Director",
  "E-Commerce Executive",
  "E-Commerce Manager",
  "Marketing Associate / Assistant",
  "Merchandising Associate / Assistant",
  "Merchandising Director",
  "Merchandising Executive / Category Executive / House Brand Executive",
  "Merchandising Manager / Category Manager / House Brand Manager",
  "Visual Merchandiser",
  "Visual Merchandising Associate / Assistant",
  "Area Manager / District Manager",
  "Operations Director / Retail Manager / Operations Manager",
  "Sales Associate / Brand Associate",
  "Sales Supervisor / Brand Supervisor",
  "Store Manager / Outlet Manager",
  "Chief Executive Officer / Chief Operating Officer / Managing Director / Country Manager",
  "Assistant / Trainee Sales and Purchase Broker",
  "Assistant Chartering Broker / Trainee Chartering Broker",
  "Chartering Broker",
  "Desk Head (Chartering)",
  "Desk Head (Sales and Purchase)",
  "Head of Marine Claims",
  "Lead Plan Approval Engineer",
  "Marine Claims Executive",
  "Marine Consultant",
  "Marine Underwriter",
  "Marine Underwriting Assistant",
  "Plan Approval Engineer",
  "Principal Marine Consultant",
  "Principal Marine Surveyor",
  "Sales and Purchase Broker",
  "Senior Marine Claims Executive",
  "Senior Marine Surveyor / Marine Surveyor",
  "Senior Plan Approval Engineer",
  "Assistant Engineer / Service Engineer",
  "Chief Engineer (Special Limit)",
  "Deck Officer (Special Limit)",
  "Engine Driver",
  "Equipment Specialist",
  "Harbour Pilot",
  "Head of Engineering",
  "Head of Pilotage",
  "Head of Port Business Development / Operations",
  "Helmsman",
  "Lead Engineer / Principal Engineer",
  "Master (Special Limit)",
  "Master Engineer (Special Limit)",
  "Operations Executive / Operations Supervisor",
  "Port Business Development / Operations Manager",
  "Senior Engineer / Engineer",
  "Senior Equipment Specialist",
  "Senior Technician / Technician",
  "Steersman",
  "Tug Master",
  "2nd / 3rd / 4th Officer",
  "3rd / 4th / 5th Engineer",
  "Assistant Crewing Manager",
  "Assistant Marine Superintendent",
  "Assistant Technical Superintendent",
  "Capacity Management Executive",
  "Capacity Manager",
  "Chartering Manager",
  "Chief Engineer / 2nd Engineer",
  "Crewing Executive",
  "Crewing Manager",
  "Demurrage Analyst / Laytime Analyst / Post Fixture Executive",
  "Demurrage Manager / Laytime Manager / Post Fixture Manager",
  "Head of Capacity Management",
  "Head of Chartering",
  "Head of Network Planning",
  "Head of Strategy and Analysis",
  "Head of Trade Management",
  "Head of Vessel Operations / Director of Operations",
  "Head of Vessel Stowage",
  "HSSEQ Executive",
  "HSSEQ Manager",
  "HSSEQ Superintendent",
  "Hub Coordinator / Shipping Clerk",
  "Marine Manager",
  "Network Planner",
  "Network Planning Manager",
  "Senior Charterer / Charterer",
  "Senior Marine Superintendent / Marine Superintendent",
  "Senior Shipping Analyst",
  "Senior Technical Superintendent / Technical Superintendent",
  "Senior Vessel Operator / Vessel Operator",
  "Ship Agency Manager",
  "Ship Agent / Boarding Officer",
  "Ship Captain / Chief Mate",
  "Shipping Analyst",
  "Stowage Manager / Senior Stowage Planner",
  "Stowage Planner / Stowage Coordinator",
  "Technical Manager",
  "Trade Management Executive",
  "Trade Manager / Assistant Trade Manager",
  "Vessel Account Executive",
  "Vessel Accountant",
  "Vessel Cost Controller",
  "Vessel Operations Manager",
  "Managing Director / General Manager / Chief Operating Officer / Fleet Director",
  "Auxiliary Police Officer",
  "Auxiliary Police Officer Supervisor / Team Leader",
  "Commanding Officer",
  "Officer Commanding / Operations Manager",
  "Chief Security Officer",
  "Security Officer",
  "Security Supervisor",
  "Senior Security Officer",
  "Senior Security Supervisor",
  "Principal Security Consultant",
  "Security Consultant",
  "Care Staff",
  "Programme Coordinator / Programme Executive",
  "Programme Manager",
  "Senior Care Staff",
  "Volunteer Executive",
  "Volunteer Manager",
  "Associate Counsellor",
  "Chief Counsellor",
  "Counselling Educator",
  "Counsellor",
  "Executive Director",
  "Manager / Assistant Manager",
  "Principal Counsellor",
  "Principal Research Counsellor",
  "Research Counsellor",
  "Senior Counsellor",
  "Senior Manager",
  "Senior Principal Counsellor",
  "Beginning Early Intervention Teacher",
  "Early Intervention Teacher",
  "Lead Early Intervention Teacher",
  "Senior Early Intervention Teacher",
  "Senior Lead Early Intervention Teacher",
  "Teacher Aide",
  "Assistant Director / Senior Manager",
  "Associate Psychologist",
  "Chief Psychologist",
  "Chief Research Psychologist",
  "Deputy Director / Senior Assistant Director",
  "Principal Psychologist",
  "Principal Psychologist Educator",
  "Principal Research Psychologist",
  "Psychologist",
  "Research Psychologist",
  "Senior Principal Psychologist",
  "Senior Principal Psychologist Educator",
  "Senior Principal Research Psychologist",
  "Senior Psychologist",
  "Senior Research Psychologist",
  "Assistant Manager",
  "Assistant Research Director",
  "Lead Social Worker",
  "Master Social Worker",
  "Policy Officer",
  "Research Associate",
  "Research Director",
  "Senior Assistant Director / Assistant Director",
  "Senior Director / Director",
  "Senior Manager / Manager",
  "Senior Master Social Worker",
  "Senior Principal Researcher / Principal Researcher",
  "Senior Research Associate",
  "Senior Social Worker",
  "Social Work Associate",
  "Social Worker",
  "Social Service Assistant",
  "Lead Youth Worker",
  "Senior Youth Worker",
  "Youth Work Associate",
  "Youth Worker",
  "Admission and Ticketing Crew",
  "Assistant Content and Experience Development Executive / Assistant Curator",
  "Assistant Education and Programmes Executive",
  "Assistant Horticulturist",
  "Assistant Keeper / Assistant Aquarist",
  "Attractions Operations Director",
  "Attractions Operations Manager",
  "Attractions Operations Supervisor / Attractions Operations Executive",
  "Content and Experience Development Executive / Curator",
  "Education and Programmes Executive",
  "Guest Service Crew",
  "Guide",
  "Head Horticulturist",
  "Head Keeper / Head Aquarist",
  "Head of Content and Experience Development / Senior Curator",
  "Head of Education and Programmes",
  "Horticulturist",
  "Keeper / Aquarist",
  "Membership Crew",
  "Operations Crew",
  "Assistant Marketing Executive",
  "Assistant Sponsorship Executive",
  "Business Development Director",
  "Sales Executive / Travel Consultant (Sales)",
  "Sponsorship Director",
  "Sponsorship Executive",
  "Sponsorship Manager",
  "Assistant Event Executive / Assistant Project Executive",
  "Assistant Event Operations Executive",
  "Assistant Research Executive",
  "Event Executive / Project Executive",
  "Event Manager / Project Manager",
  "Event Operations Director",
  "Event Operations Executive",
  "Event Operations Manager",
  "Exhibition Director / Conference Director / Meeting Director",
  "Exhibition Producer / Conference Producer / Meeting Planner",
  "Project Director",
  "Research Executive",
  "Chief Executive Officer / Managing Director / General Manager",
  "Assistant Coordination and Reservations Executive",
  "Assistant Customer Support Executive / Assistant Travel Consultant (Customer Support)",
  "Assistant Product and Experience Development Executive",
  "Coordination and Reservations Executive",
  "Customer Support Executive / Travel Consultant (Customer Support)",
  "Product and Experience Development Director",
  "Product and Experience Development Executive",
  "Product and Experience Development Manager",
  "Tour Leader",
  "Tourist Guide",
  "Travel Account Director",
  "Travel Account Executive",
  "Travel Account Manager",
  "Travel Operations Director",
  "Travel Operations Manager",
  "Assistant Event Services Executive",
  "Assistant Venue Operations Executive",
  "Event Services Director",
  "Event Services Executive",
  "Event Services Manager",
  "Venue Operations Director",
  "Venue Operations Executive",
  "Venue Operations Manager",
  "Branding, Marketing & Communications Director / Assistant Director",
  "Branding, Marketing & Communications Manager / Assistant Manager",
  "Branding, Marketing & Communications Senior Executive / Executive",
  "Capability Building Director / Assistant Director",
  "Capability Building Manager / Assistant Manager",
  "Capability Building Senior Executive / Executive",
  "Industry Development Director / Assistant Director",
  "Industry Development Manager / Assistant Manager",
  "Industry Development Senior Executive / Executive",
  "Internationalisation Director / Assistant Director",
  "Internationalisation Manager / Assistant Manager",
  "Internationalisation Senior Executive / Executive",
  "Chief Executive Officer / Executive Director",
  "Membership Director / Assistant Director",
  "Membership Manager / Assistant Manager",
  "Membership Senior Executive / Executive",
  "Research Director / Assistant Director",
  "Research Manager / Assistant Manager",
  "Research Senior Analyst / Analyst",
  "Strategy & Governance Director / Assistant Director",
  "Strategy & Governance Manager / Assistant Manager",
  "Strategy & Governance Senior Executive / Executive",
  "Assessor",
  "Courseware Developer",
  "Curriculum Lead",
  "Learning Consultant / Learning Solutionist",
  "Learning Facilitator",
  "Learning Technology Designer",
  "Centre Director / Head of Institute",
  "Learning and Operations Manager",
  "Learning Quality Manager",
  "Learning Support Executive",
  "Learning Systems Manager",
  "Credit Manager",
  "Head of Credit",
  "Head of Regulations and Trade Controls",
  "Head of Risk",
  "Head of Trade Finance",
  "Regulations and Trade Controls Manager",
  "Risk Manager",
  "Senior Credit Analyst / Credit Analyst",
  "Senior Regulations and Trade Controls Analyst / Regulations and Trade Controls Analyst",
  "Senior Risk Analyst / Risk Analyst",
  "Senior Trade Finance Analyst / Trade Finance Analyst",
  "Senior Treasury Analyst / Treasury Analyst",
  "Trade Finance Manager",
  "Business Analyst / Market Research Analyst / Market Analyst",
  "Head of Business Development",
  "Head of Marketing",
  "Marketing Assistant / Marketing Coordinator",
  "Senior Business Analyst / Senior Market Research Analyst / Senior Market Analyst",
  "Contract Specialist",
  "Head of Procurement / Head of Sourcing",
  "Operations Assistant / Operations Coordinator",
  "Operations Executive",
  "Procurement Assistant / Sourcing Assistant / Sourcing Coordinator",
  "Procurement Executive / Sourcing Executive",
  "Procurement Manager / Sourcing Manager",
  "Commodities Trader",
  "Commodities Trading Manager / Senior Commodities Trader",
  "Derivatives Trader",
  "Derivatives Trading Manager / Senior Derivatives Trader",
  "Head of Commodities Trading",
  "Head of Derivatives Trading",
  "Sales Assistant / Sales Coordinator",
  "Director / Senior Regional Manager / Senior Corporate Manager",
  "Senior Vice President / Vice President / Assistant Vice President",
  "Occupational Hygiene Officer",
  "Occupational Hygienist",
  "Workplace Safety and Health Supervisor",
  "Lead Workplace Safety and Health Auditor",
  "Workplace Safety and Health Auditor",
];
export const OrganizationOption = [
  {
    value: "AtPace",
    label: "AtPace",
  },
  {
    value: "Skillmotion",
    label: "Skillmotion",
  },
];
export const filterOption = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "in-progress",
    label: "In Progress",
  },
  {
    value: "yet to start",
    label: "Yet To Start",
  },
];
export const talentPoolOption = [
  {
    value: "talentpool",
    label: "Talent Pool",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "in-progress",
    label: "In Progress",
  },
];
export const jobRolesOption = [
  {
    value: "jobroles",
    label: "Job Roles",
  },
  {
    value: "completed",
    label: "Completed",
  },
];
export const sortFilterOption = [
  // {
  //   value: "",
  //   label: "Select Option",
  // },
  {
    value: "Alphabetic",
    label: "Alphabetic",
  },
  {
    value: "Date",
    label: "Date",
  },
];

export const dashboardSortFilterOption = [
  // {
  //   value: "",
  //   label: "Select Option",
  // },
  {
    value: "Name",
    label: "Name",
  },  
  {
    value: "Sector",
    label: "Sector",
  },  
  {
    value: "Track",
    label: "Track",
  },
];
export const jobListOption = [
  {
    value: "auditmanager",
    label: "Audit Manager",
  },
  {
    value: "chief",
    label: "Chief Financial Officer",
  },
];
export const skillTypeOption = [
  {
    key: "ccs",
    value: "CoreSkills",
    label: "Core Skills",
  },
  {
    key: "tcs",
    value: "TechnicalSkills",
    label: "Technical Skills",
  },
];
export const skillProficiencyOption = [
  {
    value: "basic",
    label: "Basic",
  },
  {
    value: "intermediate",
    label: "Intermediate",
  },
  {
    value: "advanced",
    label: "Advanced",
  },
];
export const skillProficiencyNOption = [
  {
    value: "level1",
    label: "1 (Novice)",
  },
  {
    value: "level2",
    label: "2 (Beginner)",
  },
  {
    value: "level3",
    label: "3 (Intermediate)",
  },
  {
    value: "level4",
    label: "4 (Advanced)",
  },
  {
    value: "level5",
    label: "5 (Expert)",
  },
  {
    value: "level6",
    label: "6 (Master)",
  },
];
export const skillOptionList = [
  {
    value: "html",
    label: "HTML",
  },
  {
    value: "bootstrap",
    label: "Bootstrap",
  },
  {
    value: "shopify",
    label: "Shopify",
  },
  {
    value: "angular",
    label: "Angular",
  },
  {
    value: "css",
    label: "CSS",
  },
  {
    value: "wordpress",
    label: "Wordpress",
  },
  {
    value: "react",
    label: "React",
  },
  {
    value: "cloudcomputing",
    label: "Cloud Computing",
  },
];
export const JobRolesValue = [
  {
    id: 1,
    icon: JobRoleIcon,
    title: "Total Job Roles",
    value: "65",
  },
  {
    id: 2,
    icon: CertifiedIcon,
    title: "Job Roles Certified",
    value: "35",
  },
  {
    id: 3,
    icon: ProgressIcon,
    title: "Job Roles In-Progress",
    value: "30",
  },
];
export const jobTableColumn = [
  {
    name: "Sr No.",
    width: 60,
  },
  {
    name: "Job Role",
    width:200
  },
  {
    name: "No. of Questions",
  },
  {
    name: "Date of Certification",
  },
  {
    name: "Status",
  },
  {
    name: "Action",
    width: 150,
  },
];
export const jobRoleList = [
  {
    id: 1,
    jobRole: "Software Engineering",
    questionNo: "75",
    date: "25/05/2024",
    status: "certified",
    action: "Continue",
  },
  {
    id: 2,
    jobRole: "Software Engineering",
    questionNo: "75",
    date: "25/05/2024",
    status: "certified",
    action: "Start",
  },
  {
    id: 3,
    jobRole: "Software Engineering",
    questionNo: "75",
    date: "25/05/2024",
    status: "certified",
    action: "Start",
  },
  {
    id: 4,
    jobRole: "Software Engineering",
    questionNo: "75",
    date: "25/05/2024",
    status: "certified",
    action: "Continue",
  },
];
export const coreSkillList = [
  {
    id: 1,
    skillname: "Problem-Solving",
  },
  {
    id: 2,
    skillname: "Communication",
  },
  {
    id: 3,
    skillname: "Collaboration",
  },
  {
    id: 4,
    skillname: "Time Management",
  },
  {
    id: 5,
    skillname: "Adaptability",
  },
  {
    id: 6,
    skillname: "Attention to Detail",
  },
  {
    id: 7,
    skillname: "Continuous Learning",
  },
  {
    id: 8,
    skillname: "Adaptability",
  },
  {
    id: 9,
    skillname: "Collaboration",
  },
  {
    id: 10,
    skillname: "Communication",
  },
  {
    id: 11,
    skillname: "Communication",
  },
];
export const technicalSkillList = [
  {
    id: 1,
    skillname: "Problem-Solving",
  },
  {
    id: 2,
    skillname: "Communication",
  },
  {
    id: 3,
    skillname: "Collaboration",
  },
  {
    id: 4,
    skillname: "Time Management",
  },
  {
    id: 5,
    skillname: "Adaptability",
  },
  {
    id: 6,
    skillname: "Attention to Detail",
  },
  {
    id: 7,
    skillname: "Continuous Learning",
  },
];
export const questionsList = [
  {
    id: 1,
    question: "First step in bug troubleshooting?",
    options: ["Option A", "Option B", "Option C", "Option D"],
    correctAnswer: 1,
  },
  {
    id: 2,
    question: "What does HTML stand for?",
    options: [
      "Hyper Text Markup Language",
      "High Tech Markup Language",
      "Hyperlinks and Text Markup Language",
      "Hyper Transfer Markup Language",
    ],
    correctAnswer: 1,
  },
  {
    id: 3,
    question: "How to prioritize technical issues?",
    options: ["Random", "Prioritize", "Assign", "Learn basics"],
    correctAnswer: 3,
  },
  {
    id: 4,
    question: "First step in bug troubleshooting?",
    options: ["Option A", "Option B", "Option C", "Option D"],
    correctAnswer:2,
  },
  {
    id: 5,
    question: "What is the purpose of a constructor in Java?",
    options: ["Hyper Text Markup Language", "Option B", "Option C", "Option D"],
    correctAnswer: 3,
  },
];
export const answerOptions = [
  { number: "A", text: "HyperText Markup Language.", value: "a" },
  { number: "B", text: "HyperText Markup Language.", value: "b" },
  { number: "C", text: "HyperText Markup Language.", value: "c" },
  { number: "D", text: "HyperText Markup Language.", value: "d" },
];
export const questionData = [
  {
    id: 1,
    question: "What does the abbreviation HTML stand for?",
    options: [
      { id: "A", answer: "HyperText Markup Language." },
      { id: "B", answer: "Hypertext Markup Language." },
      { id: "C", answer: "HyperTag Markup Language." },
      { id: "D", answer: "Hyperlinking Text Markup Language." },
    ],
  },
  {
    id: 2,
    question: "What does the abbreviation HTML stand for?",
    options: [
      { id: "A", answer: "HyperText Markup Language." },
      { id: "B", answer: "Hypertext Markup Language." },
      { id: "C", answer: "HyperTag Markup Language." },
      { id: "D", answer: "Hyperlinking Text Markup Language." },
    ],
  },
  {
    id: 3,
    question: "What does the abbreviation HTML stand for?",
    options: [
      { id: "A", answer: "HyperText Markup Language." },
      { id: "B", answer: "Hypertext Markup Language." },
      { id: "C", answer: "HyperTag Markup Language." },
      { id: "D", answer: "Hyperlinking Text Markup Language." },
    ],
  },
  {
    id: 4,
    question: "What does the abbreviation HTML stand for?",
    options: [
      { id: "A", answer: "HyperText Markup Language." },
      { id: "B", answer: "Hypertext Markup Language." },
      { id: "C", answer: "HyperTag Markup Language." },
      { id: "D", answer: "Hyperlinking Text Markup Language." },
    ],
  },
  {
    id: 5,
    question: "What does the abbreviation HTML stand for?",
    options: [
      { id: "A", answer: "HyperText Markup Language." },
      { id: "B", answer: "Hypertext Markup Language." },
      { id: "C", answer: "HyperTag Markup Language." },
      { id: "D", answer: "Hyperlinking Text Markup Language." },
    ],
  },
  {
    id: 6,
    question: "What does the abbreviation HTML stand for?",
    options: [
      { id: "A", answer: "HyperText Markup Language." },
      { id: "B", answer: "Hypertext Markup Language." },
      { id: "C", answer: "HyperTag Markup Language." },
      { id: "D", answer: "Hyperlinking Text Markup Language." },
    ],
  },
  {
    id: 7,
    question: "What does the abbreviation HTML stand for?",
    options: [
      { id: "A", answer: "HyperText Markup Language." },
      { id: "B", answer: "Hypertext Markup Language." },
      { id: "C", answer: "HyperTag Markup Language." },
      { id: "D", answer: "Hyperlinking Text Markup Language." },
    ],
  },
];
export const renewalData = [
  {
    department: "Software Engineers",
    status: "Yet to Start",
    description:
      "Explore essential skills and responsibilities for software development careers.",
    buttons: ["View Roadmap", "Beta"],
  },
  {
    department: "Cybersecurity Analysts",
    status: "In Progress",
    description:
      "Explore essential skills and responsibilities for cybersecurity analyst careers.",
    buttons: ["Create Roadmap", "Skill Renewal"],
  },
  {
    department: "Automotive Technician",
    status: "Yet to Start",
    description:
      "Explore essential skills and responsibilities for cybersecurity analyst careers.",
    buttons: ["Create Roadmap", "Skill Renewal"],
  },
  {
    department: "Cybersecurity Analysts",
    status: "Completed",
    description:
      "Explore essential skills and responsibilities for cybersecurity analyst careers.",
    buttons: ["View Roadmap", "Waiting"],
  },
  {
    department: "Software Engineers",
    status: "Yet to Start",
    description:
      "Explore essential skills and responsibilities for cybersecurity analyst careers.",
    buttons: ["Create Roadmap", "Waiting"],
  },
  {
    department: "Cybersecurity Analysts",
    status: "in-progress",
    description:
      "Explore essential skills and responsibilities for cybersecurity analyst careers.",
    buttons: ["View Roadmap", "Waiting"],
  },
];
