import { useEffect, useState } from "react";
import { BarChart } from "../common/barChart";
import DashboardHead from "../common/dashboard-head";
import { callGetApi, callPostApi } from "../../../services/roadmap_api";
import { capitalizeFirstLetter, getCurrent, getCurrentTec, getCurrentTecChartSkill, getCurrentTecRadarSkill, getProfNum, getStatus } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { storeTabStatus } from "../../../store/skillRenewal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { Card } from "react-bootstrap";

const SkillTestReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();


  const userLo = localStorage.getItem("user");
  const user =userLo? JSON.parse(localStorage.getItem("user")):null;
  const  {gJobRole} = useSelector((state)=>(state?.counterSlice));
  const getData = JSON?.parse(localStorage.getItem("renewal-data"));

  const [userSkills, setUserSkills] = useState([]);

  const getAllSkills = async () => {
    const { user_id } = user || { user_id: "1111" };
    const userSkillsData = await callGetApi(
      `/api/user/skill-proficiency-graph/?target_role_name=${encodeURIComponent(gJobRole || getData?.target_role_name ||
        "Audit Associate / Audit Assistant Associate"
      )}&user_id=${user_id}`
    );
    let payload1={
      name:gJobRole || getData?.target_role_name || "Audit Associate / Audit Assistant Associate",
      type: "tsc"
    }
    let payload2={
      name:gJobRole || getData?.target_role_name || "Audit Associate / Audit Assistant Associate",
      type: "ccs"
    }
    const resTecSkills = await callPostApi(`/api/domain-expert/sector/target-roles/skills/`,payload1);
    const resCorSkills = await callPostApi(`/api/domain-expert/sector/target-roles/skills/`,payload2 );

    // const resTecSkills = await callGetApi(
    //   `/api/domain-expert/sector/target-roles/skills/?name=${gJobRole || getData?.target_role_name || "Audit Associate / Audit Assistant Associate"}&type=tsc`
    // );
    // const resCorSkills = await callGetApi(
    //   `/api/domain-expert/sector/target-roles/skills/?name=${gJobRole || getData?.target_role_name || "Audit Associate / Audit Assistant Associate"}&type=ccs`
    // );
    let reverseUserSkills = userSkillsData?.length>1?[...userSkillsData]?.reverse():userSkillsData;


    const resultTec = (resTecSkills || []).map((it) => ({
      name: it.name,
      expetedProf: +it.proficiency,
      currentProf: getCurrentTecChartSkill(it.name, reverseUserSkills),
      status: getStatus(it.name, reverseUserSkills),
      type: "tsc",
    }));

    const resultCor = (resCorSkills || []).map((it) => ({
      name: it.name,
      expetedProf: +getProfNum(it.proficiency),
      currentProf: getCurrent(it.name, "CoreSkills", reverseUserSkills, true),
      status: getStatus(it.name, reverseUserSkills),
      type: "ccs",
    }));
    
    let adding = (resultTec || [])?.concat(resultCor || []);
    // setCountSkills(adding);

    // const result = (userSkillsData || []).map(
    //   ({
    //     skill_name,
    //     proficiency,
    //     current_proficiency,
    //     skill_type,
    //     status,
    //   }) => ({
    //     name: skill_name,
    //     expetedProf: 
    //     skill_type?.toLowerCase() === "coreskills"
    //         ? getProfNum(proficiency)
    //         : Number(proficiency),
    //     currentProf:
    //       skill_type?.toLowerCase() === "coreskills"
    //         ? getProfNum(current_proficiency)
    //         : Number(current_proficiency),
    //     status: status || false,
    //     type: skill_type,
    //   })
    // );

    setUserSkills(adding);
  };

  useEffect(() => {
    getAllSkills();
  }, []);

  const handleBack=()=>{
    if(searchParams.get('role')){
      navigate("/dashboard/renewal")
    dispatch(storeTabStatus("skills"));
    }else{
      navigate('/dashboard')
    }
  }

  console.log("userskils", { userSkills })

  return (
    <>
      <Card className="mb-3">
        <div className="card-body d-flex gap-3">
          <Button className="primary-border-btn" onClick={handleBack}><span className="me-1"><DoubleLeftOutlined /></span>Back</Button>
          <DashboardHead
            pageTitle="Test Report"
            subPageTitle={ capitalizeFirstLetter(gJobRole || getData?.target_role_name || "Audit Associate / Audit Assistant Associate") }
          />
        </div>
      </Card>
      <div className="technical-skill-report mb-5">
        <Card>
          <div className="card-header">
            <h5 className="card-title mb-0">Technical Skills</h5>
          </div>
          <div className="card-body">
            <div className="chart-box">
              <BarChart skillData={userSkills?.filter((it) => it?.type == "tsc")} type="tsc"/>
            </div>
          </div>
        </Card>
      </div>
      <div className="technical-skill-report mb-5">
        <Card>
          <div className="card-header">
            <h5 className="card-title mb-0">Core Skills</h5>
          </div>
          <div className="card-body">
            <div className="chart-box">
              <BarChart  skillData={userSkills?.filter((it) => it?.type == "ccs")}  type="ccs" />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
export default SkillTestReport;
