import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      try {
        //console.log('scroll to top');
        const headerHeight = document.querySelector("header").offsetHeight;
        window.scrollTo({ top: -headerHeight, behavior: "smooth" });
      } catch (error) {
       // console.log("Error occurred while scrolling to top:", error);
        // Handle the error if necessary
      }
    }, 500);
  }, [location.pathname, navigate]);

  return null;
}

export default ScrollToTop;
