export function generateUniqueID() {
    const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 8; i++) {
        id += charSet.charAt(Math.floor(Math.random() * charSet.length));
    }
    return id;
}
export function calculateTotalExperience(experiences) {
    let totalMonths = 0;
  
    experiences.forEach((experience) => {
        if (experience.ends_at !== null && experience.ends_at !== undefined && experience.ends_at !== '') {
            //console.log(experience," --- >");
            const start = experience.starts_at;
            const end = experience.ends_at;
            totalMonths += calculateDuration(start, end);
        }
        //console.log(totalMonths," ----> total Exp")
    });
    return totalMonths;
}
const calculateDuration = (start, end) => {
    if (!start || !end) {
        end = { day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() };
    }
    const parseDate = (date) => {
        if (date?.year && date?.month && date?.day) {
            return new Date(date.year, date.month - 1, date.day);
        }
        else {
            const formatted = formatDateString(date);
            return new Date(formatted.year, formatted.month - 1, formatted.day);
        }
    };
    const startDate = parseDate(start);
    const endDate = parseDate(end);
    let diffInMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
    if (endDate.getDate() < startDate.getDate()) {
        diffInMonths--;
    }
    // console.log(diffInMonths, "different months --->");
    return diffInMonths >= 0 ? diffInMonths : 0;
};
export const formatDateString = (dateStr) => {
    try {
        const months = {
            Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
            Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
        };
        // console.log("dateStr", dateStr);
        // Split the input string into month and year
        const parts = dateStr?.split(' ');
        const month = months[parts[0]];
        const year = parseInt(parts[1], 10);
        // Return the formatted date string
        return { day: 1, month: month, year: year };
    }
    catch (error) {
        return { day: 1, month: 1, year: 1 };
    }
};
export const convertExperienceDates = (experienceArray) => {
    try {
        return experienceArray.map((item) => {
            const convertedItem = {
                ...item,
                starts_at: convertDateToObject(item.startDate || ""),
                ends_at: convertDateToObject(item.endDate || "Present", true) // Assuming 'Present' means current date or keep as is
            };
            // Remove the old date properties
            delete convertedItem.startDate;
            delete convertedItem.endDate;
            return convertedItem;
        });
    }
    catch (error) {
        console.log(" Error in helper date");
    }
};
function convertDateToObject(dateString, isEndDate = false) {
    try {
        if (dateString.toLowerCase() === "present") {
            const now = new Date();
            return { day: now.getDate(), month: now.getMonth() + 1, year: now.getFullYear() };
        }
        const parts = dateString.split('-');
        let day, month, year;
        if (parts.length === 3) {
            [day, month, year] = parts.map(Number);
        }
        else if (parts.length === 2) {
            [month, year] = parts.map(Number);
            day = isEndDate ? new Date(year, month, 0).getDate() : 1; // Last day of month if endDate, else first
        }
        else {
            const currentDate = new Date();
            year = currentDate.getFullYear();
            month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
            day = currentDate.getDate();
            // Or some default/fallback value
        }
        return { day, month, year };
    }
    catch (error) {
    }
}
