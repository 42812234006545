import { useEffect, useState } from "react";
import apiFetch, { BASE_URL } from "../../api";
import { message, Table, Pagination, Select, Input, Switch } from "antd";
import { useParams } from "react-router-dom";

const { Option } = Select;

const TalentPoolAssign = () => {
  const { id } = useParams();
  const userLo = localStorage.getItem("user");
  const user = userLo ? JSON.parse(localStorage.getItem("user")) : null;

  const [pool_name, setPool_name] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [targetRoles, setTargetRoles] = useState([]);
  const [assignedRoleIds, setAssignedRoleIds] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [filterAssign, setFilterAssign] = useState(null); // "Assigned", "Unassigned", or ""

  useEffect(() => {
    if (id) {
      getTalentPool(id);
    }
  }, [id, currentPage, pageSize, filterSearch, filterAssign]);

  const getTalentPool = async (id) => {
    try {
      setIsLoading(true);
      const data = await apiFetch(
        `/api/get-talent-pool-roles/${id}/`,
        "POST",
        {
          page_size: pageSize,
          page: currentPage,
          filter_search: filterSearch,
          filter_assign: filterAssign,
        },
        { Authorization: `Token ${user?.token}` }
      );

      if (data.success) {
        setPool_name(data.name);
        setTargetRoles(data.target_roles);
        setTotal(data.total_items);
        setAssignedRoleIds(data.assigned_role_ids);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching TalentPool data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleRole = (roleId, checked) => {
    let updatedAssignedRoleIds;

    let added = null;

    if (checked) {
      // Add role ID if checked
      updatedAssignedRoleIds = [...assignedRoleIds, roleId];
      added = true;
    } else {
      // Remove role ID if unchecked
      updatedAssignedRoleIds = assignedRoleIds.filter((id) => id !== roleId);
      added = false;
    }

    setAssignedRoleIds(updatedAssignedRoleIds);
    UpdateAssignment(roleId, added);
  };

  const UpdateAssignment = async (roleId, added) => {
    try {
      setIsLoading(true);      
      const data = await apiFetch(
        `/api/talent-pool-assign-roles/`,
        "POST",
        {
          pool_id: id,
          role_Id: roleId,
          added: added,
        },
        { Authorization: `Token ${user?.token}` }
      );

      if (data.success) {
        message.success(data.message);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching TalentPool data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Assign",
      dataIndex: "id",
      key: "assign",
      render: (roleId) => (
        <>
          <Switch
            checked={assignedRoleIds.includes(roleId)}
            onChange={(checked) => handleToggleRole(roleId, checked)}
          />
          {/* {roleId} */}
        </>
      ),
    },
    {
      title: "Sector",
      dataIndex: "Sector",
      key: "sector",
    },
    {
      title: "Track",
      dataIndex: "Track",
      key: "track",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  const handleSearchChange = (e) => {
    setCurrentPage(1);
    setFilterSearch(e.target.value);
  };

  const handleFilterAssignChange = (value) => {
    setCurrentPage(1);
    setFilterAssign(value);
  };

  return (
    <div>
      <div className="headingbar d-flex justify-content-between">
        <div className="title d-flex justify-content-between align-items-end w-100 mb-4">
          <h2>Assign Roles in Talent Pool ({pool_name}) </h2>
        </div>
      </div>
      <div className="serach-bar">
        <div style={{ marginBottom: 16 }}>
          <Input
            placeholder="Filter by Role Name"
            className="filter-input"
            value={filterSearch}
            onChange={handleSearchChange}
            style={{ width: 200, marginRight: 16 }}
          />
          <Select
            placeholder="Filter by Assignment"
            value={filterAssign || null}
            onChange={handleFilterAssignChange}
            allowClear
            style={{ width: 200 }}
          >
            <Option value="1">Assigned</Option>
            <Option value="0">Unassigned</Option>
          </Select>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={targetRoles}
        rowKey="id"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        loading={isLoading}
      />
    </div>
  );
};

export default TalentPoolAssign;
