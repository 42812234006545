import { Button, Modal, Radio } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import CheckIcon from "../../assets/images/check-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { storeAllDomains } from "../../store/domainExpert";
import { toMessage } from "../utils";

const EditQuestionAnswer = ({ show, data, handleClose, keyValue, allData }) => {
  const dispatch = useDispatch();
  const [ques, setQues] = useState(data);

  useEffect(() => {
    if (data) {
      setQues(data);
    }
  }, [data]);

  const handleChange = (e) => {
    setQues({ ...ques, [e?.target?.name]: e?.target?.value });
  };

  const handleChangeOption = (e, index) => {
    let allOp = [...ques?.options];
    let arrOp = ["A)", "B)", "C)", "D)"];
    const trimmedValue = e?.target?.value.trimStart();
    if (trimmedValue === "" || trimmedValue.includes(")")) {
      allOp[index] = trimmedValue;
    } else {
      allOp[index] = `${arrOp[index]} ${trimmedValue}`;
    }
    setQues({ ...ques, ["options"]: allOp });
  };


  const handleCAnswerChange = (e, index) => {
    let arrOp = ["A)", "B)", "C)", "D)"];
    let val = `${arrOp[index]} ${e?.target?.value.trimStart()}`;
    console.log(
      `${arrOp[index]} ${e?.target?.value.trimStart()}`,
      index,
      "allOp ques"
    );
    setQues({ ...ques, ["correct_answer"]: val });
  };

  console.log(ques, "allOp ques");

  const isChecked = (key) => {
    let data =
      ques && ques?.options?.filter((it) => it?.charAt(0) == key.charAt(0));
    if (data?.length != 0) {
      return rPre(data[0]);
    }
    return rPre(key);
  };

  const checkEmpty = (options) => {
    const isEmpty = options.some(
      (option) => option.trim() === "" || option.trim()?.length < 3
    );
    return isEmpty;
  };

  const checkUniqueOptions = (options) => {
    const processedOptions = options.map(option => rPre(option.toLowerCase().trim()));
    const uniqueOptions = new Set(processedOptions);
    return uniqueOptions.size !== options.length;
  };

  const handleOk = () => {
    if (
      !ques?.question.trim() ||
      !ques?.correct_answer ||
      checkEmpty(ques?.options)
    ) {
      toMessage("Please fill all the input fields, they are mandatory", "error");
      return;
    }
    if (checkUniqueOptions(ques?.options)) {
      toMessage("Options are not accepted: all options must be unique after processing.", "error");
      return;
    }
    console.log(ques, "ques");
    let updateQuestion = { ...ques };
    // ,question:`${keyValue+1}. ${ques?.question}`}
    const updatedOptions = [...allData];
    updatedOptions[keyValue] = updateQuestion;
    toMessage("Questions have been successfully updated.", "success");
    dispatch(storeAllDomains({ type: "question", data: updatedOptions }));
    handleClose();
  };

  const Arr = ["A", "B", "C", "D"];

  const rPre = (o) => {
    if (o) {
      return o?.replace(/^[^.]*[.)]\s*/, "") || o;
    }
    return "";
  };
  // const rPre = (o) => {
  //   if (o?.split(")")?.length > 1) {
  //     return o?.split(")")[1];
  //   }
  //   return o?.split(")")[0];
  // };

  return (
    <div>
      <Modal
        visible={show}
        okText={"Save"}
        onOk={handleOk}
        onCancel={handleClose}
        closeIcon={false}
        className="edit-qs-modal"
        width={600}
      >
        <div className="edit-question-modal">
          <img src={CheckIcon} alt="icon" />
          <h2 className="title">Edit Question & Answer</h2>
          <div className="edit-title">
            <label>Question <span style={{color:'red'}}>*</span></label>
            <TextArea
              placeholder="What does the abbreviation HTML stand for?"
              autoSize
              value={ques?.question}
              name="question"
              onChange={handleChange}
            />
          </div>
          <div className="answer-option-list">
            <label>Answer Options <span style={{color:'red'}}>*</span>:</label>
            <div className="answer-list">
              <Radio.Group
                name="correct_answer"
                value={isChecked(ques?.correct_answer)}
              >
                {ques &&
                  ques?.options?.length > 0 &&
                  ques?.options.map((option, index) => (
                    <div key={index} className="answerlist">
                      <span className="number">{Arr[index]}</span>
                      <h3>
                        <input
                          onChange={(e) => handleChangeOption(e, index)}
                          type="text"
                          value={rPre(option)}
                        />
                      </h3>
                      <Radio
                        onChange={(e) => handleCAnswerChange(e, index)}
                        value={rPre(option)}
                      >
                        Correct Answer
                      </Radio>
                    </div>
                  ))}
              </Radio.Group>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditQuestionAnswer;
