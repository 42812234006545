import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import apiFetch, { BASE_URL } from "../api";
import { useSelector, useDispatch } from "react-redux";
import { storeLoginStatus } from "../store/slice";
import { useGoogleTranslate } from "../GoogleTranslateProvider";

function Header({ isLoggedIn, setIsLoggedIn, current_type, setCurrent_type }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [logo, setLogo] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation();
  const [access, setAccess] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [company, setCompany] = useState("");
  const isLoaded = useGoogleTranslate();
  const [dashBoardLink, setDashboardLink] = useState("");

  

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".navbar") && isMenuOpen) {
        setIsMenuOpen(false);
        setIsDivVisible(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiFetch("/api/skillsettings/");
        setLogo(data.logo);
      } catch (error) {
        console.error("Error fetching settings data:", error);
      }
    };

    const fetchMenu = async () => {
      try {
        const data = await apiFetch("/api/menu/");
        setMenuItems(data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchData();
    fetchMenu();
  }, []);

  const renderMenuItems = (menuItems) => {
    return menuItems.map(
      (menuItem) =>
        (menuItem.slug !== "skill-analyser" || isLoggedIn) && (
          <li
            className={`nav-item ${
              (menuItem.slug === "" && location.pathname === "/") ||
              (menuItem.slug !== "" &&
                (location.pathname === `/${menuItem.slug}` ||
                  location.pathname.includes(`/${menuItem.slug}`)))
                ? "active"
                : ""
            } ${menuItem.submenu.length > 0 ? "has-submenu" : ""}`}
            key={menuItem.id}
          >
            <Link
              to={`/${menuItem.slug}`}
              className="nav-link"
              onClick={handleLinkClick}
            >
              {menuItem.title}
            </Link>
            {menuItem.submenu.length > 0 && (
              <ul className="sub-menu">{renderMenuItems(menuItem.submenu)}</ul>
            )}
          </li>
        )
    );
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsDivVisible(!isDivVisible);
  };

  const handleDivClick = () => {
    setIsMenuOpen(false);
    setIsDivVisible(false);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setIsDivVisible(false);
  };

  const [defaultLanguage, setDefaultLanguage] = useState("en");

  const handleLanguageChange = (event) => {
    setDefaultLanguage(event.target.value);
  };

  useEffect(() => {
    if (current_type) {
      if (current_type.id === 12) {
        setDashboardLink("/domain-expert-dashboard/");
      } else if (current_type.id === 13) {
        setDashboardLink("/organization/dashboard");
      } else if (current_type.id === 2 || current_type.id === 5) {
        setDashboardLink("/admin-dashboard");
      } else {
        setDashboardLink("/dashboard");
      }
    } else {
      setCurrent_type({ "id": 6, "name": "Learner" });
      setDashboardLink("/dashboard");
    }
  }, [current_type]);

  return (
    <header className="header col-md-12">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="nav-logo" to={`/`}>
            <img src={logo} className="img-fluid" alt="logo" width="100" />
          </Link>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <Link
              className="mobile-logo text-center"
              to={`/`}
              onClick={handleLinkClick}
            >
              <img src={logo} className="img-fluid" alt="logo" width="150" />
            </Link>

            <ul className="navbar-nav ms-auto me-4 mb-2 mb-lg-0">
              {renderMenuItems(menuItems)}
              <li className="nav-item ">
                <Link
                  className="nav-link"
                  to="https://calendly.com/ravi-skillmotion/demosma"
                  target="_blank"
                >
                  Book a Demo
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex">
            <div className="destop-button">
              <ul className="ms-auto mb-lg-0">
                {isLoggedIn ? (
                  <>
                    <li>
                      <Link
                        className="btn button-dark dashboard"
                        to={dashBoardLink}
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link className="btn button-dark" to={`/logout`}>
                        Logout
                      </Link>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link className="btn button-dark" to={`/login`}>
                      Log in
                    </Link>
                  </li>
                )}
                <li className="language-swichter">
                 {isLoaded && <div id="google_translate_element"></div>}
                </li>
              </ul>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {isDivVisible && (
              <div className="background-div" onClick={handleDivClick}></div>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
