import React, { useEffect, useState } from "react";
import { Button, Divider, Modal, Steps } from "antd";
import { Col, Row } from "antd";
import Loader from "../../../../components/loader";
import CustomDropdown from "../../../../components/dropdown";
import {
  skillProficiencyNOption,
  skillProficiencyOption,
  skillTypeOption,
} from "../../../../constant";
import { useDispatch, useSelector } from "react-redux";
import {
  storeAllDomains,
  storeSelValues,
  storeSkillOptions,
  storeTestStatus,
} from "../../../../store/domainExpert";
import { callGetApi, callPostApi, callTestApi } from "../../../../services/roadmap_api";
import DashboardHead from "../../common/dashboard-head";
import { toast } from "react-toastify";
import { toMessage } from "../../../utils";
import { Card } from "react-bootstrap";

const SkillStepsList = () => {
  const dispatch = useDispatch();
  const { selectedValues } = useSelector((state) => state?.domainExpert);

  const [selectedOption, setSelectedOption] = useState({
    type: null,
    name: null,
    role: selectedValues?.role,
    level: null,
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [loadingFor, setLoadingFor] = useState("");
  const [skillsOption, setSkillOptions] = useState([]);

  const handleBackStep = () => {
    if (currentStep == 3) {
      setSelectedOption({...selectedOption,level:null})
      dispatch(storeSelValues({ type: "level", value: null }));
    }else if (currentStep == 2) {
      setSelectedOption({...selectedOption,name:null})
      dispatch(storeSelValues({ type: "name", value: null }));
    }
    setCurrentStep((prev) => prev - 1);
  };

  const handleNextStep = async () => {
    if (currentStep == 1) {
      await getAllSkills();
    }
    if (currentStep == 3) {
      await generateQueAns();
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  console.log(selectedOption?.type,"selectedOption")
  
  const getAllSkills = async () => {
    setLoadingFor("Getting skills...");
    let payload={
      name:selectedOption?.role?.value,
      type:selectedOption?.type == "CoreSkills" ? "ccs" : "tsc"
    }
    const res = await callPostApi(`/api/domain-expert/sector/target-roles/skills/`,payload );
    // const res = await callGetApi(
    //   `/api/domain-expert/sector/target-roles/skills/?name=${
    //     selectedOption?.role?.value
    //   }&type=${selectedOption?.type == "CoreSkills" ? "ccs" : "tsc"}`
    // );
    if (res && res !== undefined) {
      let updatedData = res?.map((it) => ({
        label: it?.name,
        value: it?.name,
        type: "name",
      }));
      setSkillOptions(updatedData);
      dispatch(storeSkillOptions(updatedData));
    } else {
      toMessage("Something went wrong! try again later","error")
    }
    setLoadingFor("");
  };

  const generateQueAns = async () => {
    setLoadingFor("Generating test-MCQs...");
    let payload={
      skill_name: selectedOption?.name || "",
      skill_type: selectedOption?.type == "CoreSkills" ? "core" : "technical",
      proficiency_level : selectedOption?.level,
      target_role_name : selectedValues?.role?.label
    }
    const res = await callPostApi(
      `/api/test-MCQs/generate/`,payload
    );
    if (res && res !== undefined) {
      dispatch(storeAllDomains({ type: "question", data: res }));
      dispatch(storeTestStatus("q/a"));
    } else {
      toMessage("Something went wrong! try again later","error")
    }
    setLoadingFor("");
  };

  const handleStateChangeInSkill = (newValue) => {
    if (newValue) {
      setSelectedOption({ ...selectedOption, [newValue.type]: newValue.value });
      dispatch(storeSelValues({ type: newValue.type, value: newValue.value }));
    }
  };

  const isDisable = () => {
    if (currentStep == 1 && !selectedOption?.type) {
      return true;
    } else if (currentStep == 2 && !selectedOption?.name) {
      return true;
    } else if (currentStep == 3 && !selectedOption?.level) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <Card>
      <div className="card-header">
        <DashboardHead pageTitle={selectedValues?.role?.label || "Domain Expert"} />
      </div>
      <div className="card-body">
        <div className="home-page mnt-dashboard">
          <Row>
            <Col span={24}>
              <div className="step-form">
                <Steps
                  progressDot
                  current={currentStep}
                  items={[
                    {
                      title: "",
                      description: "",
                    },
                    {
                      title: "Skill Type",
                      description: "",
                    },
                    {
                      title: "Skill",
                      description: "",
                    },
                    {
                      title: "Proficiency Level",
                      description: "",
                    },
                  ]}
                />
                <Divider className="header" />
                {loadingFor !== "" ? <Loader text={loadingFor} /> : ""}

                {currentStep === 1 ? (
                  <>
                    <CustomDropdown
                      type="type"
                      selectedOption={selectedOption?.type || null}
                      placeholder="Select Skill Type"
                      allRoles={skillTypeOption}
                      onStateChange={handleStateChangeInSkill}
                      title="Select the skill type "
                      subTitle="Select the skill type for the selcted job role which you want to generate questions"
                    />
                  </>
                ) : currentStep === 2 ? (
                  <>
                    <CustomDropdown
                      type="name"
                      selectedOption={selectedOption?.name || null}
                      placeholder="Select Skills"
                      allRoles={skillsOption}
                      onStateChange={handleStateChangeInSkill}
                      title="Select the skills"
                      subTitle="Select the skill for which you want to generate the questions"
                    />
                  </>
                ) : currentStep === 3 ? (
                  <>
                    <CustomDropdown
                      type="level"
                      placeholder="Select Proficiency Level"
                      selectedOption={selectedOption?.level || null}
                      allRoles={
                        selectedOption?.type == "CoreSkills"
                          ? skillProficiencyOption
                          : skillProficiencyNOption
                      }
                      onStateChange={handleStateChangeInSkill}
                      title="Select the skill proficiency level"
                      subTitle="Select the proficiency level of the selected skill for which you want to generate the questions"
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="nxtbckbtn skill-btn-field">
                  {currentStep > 1 && (
                    <div className="back-btn">
                      <Button onClick={handleBackStep}>Back</Button>
                    </div>
                  )}
                  {currentStep < 4 && (
                    <div className="next-btn">
                      <Button disabled={isDisable()} onClick={handleNextStep}>
                        Next
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};
export default SkillStepsList;
