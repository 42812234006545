import { Button, Modal } from "antd";
import React from "react";

const NoMcqs = ({ show, handleClose }) => {

  return (
    <div>
      <Modal
         title="MCQs Validation in Progress"
         visible={show}
         onCancel={handleClose}
         footer={[
           <Button key="close" onClick={handleClose} style={{ backgroundColor: 'blue', color: 'white' }}>
             Close
           </Button>
         ]}
         closeIcon={true}
         className="sma-modal validate-value-modal"
      >
        <div className="subtitle-modal">
        <p>Questions are currently being validated. Please wait while we add enough questions for the assessment.</p>
      
        </div>
      </Modal>
    </div>
  );
};

export default NoMcqs;
