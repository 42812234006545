import UserTalentProfile from "../../components/UserTalentProfile";

const TalentProfile = () => {
    return (
        <>
          {/* <h2 className="layout-heading mb-4">Generate Roadmap</h2> */}
          <UserTalentProfile />
        </>
      );
};
export default TalentProfile;
