import React from 'react';

const SocialShare = ({ url }) => {
    const shareUrl = encodeURIComponent(url);

    return (
        <div className="social-left-fixed">
            <ul>
                <li><a target='_blank' rel="noreferrer" className='twitter' href={`https://twitter.com/intent/tweet?url=${shareUrl}`} ><i className="fa-brands fa-x-twitter"></i></a></li>
                <li><a target='_blank' rel="noreferrer" className='facebook' href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}><i className="fa-brands fa-facebook-f"></i></a></li>
                <li><a target='_blank' rel="noreferrer" className='linkedin' href={`https://www.linkedin.com/company/skillmotionai/`}><i className="fa-brands fa-linkedin-in"></i></a></li>
                <li><a target='_blank' rel="noreferrer" className='instagram' href={'https://www.instagram.com/skillmotion.ai/?hl=en'}><i className="fa-brands fa-instagram"></i></a></li>
            </ul>
        </div>
    );
};

export default SocialShare;
