import { configureStore } from "@reduxjs/toolkit";
import counterSlice from "./slice";
import domainExpert from "./domainExpert";
import skillRenewal from "./skillRenewal";
import organization from "./organization";
import pathway from "./pathway";

export const store = configureStore({
  reducer: { counterSlice, domainExpert,skillRenewal, organization,pathway },
});
