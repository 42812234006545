import { useEffect, useState } from "react";
import RenewalStartUp from "../component/skill-renewal/blank-renewal";
import DevRenewalList from "../component/skill-renewal/dev-test-list";
import RenewalList from "../component/skill-renewal/renewal-list";
import RevewalProgressList from "../component/skill-renewal/renewal-progress-list";
import SkillTestReport from "../component/test-report";
import RelevantSkillTestModal from "../modal/relevant-skill-tests";
import { useDispatch, useSelector } from "react-redux";
import { callGetApi, callTestApi } from "../../services/roadmap_api";
import { storeAllSkillTests, storeTabStatus } from "../../store/skillRenewal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Quiz from "../zztestFiles/renewalList";
import ExpertiseLevel from "../component/expertise-level";

const RenewalAllSkills = () => {
  const dispatch = useDispatch();

  const { tabQaStatus, askedQuestion, allJobRole, selectedVal } = useSelector(
    (state) => state?.skillRenewal
  );

  const getData=JSON?.parse(localStorage.getItem("mcq_skills"));

  const getAllTests = async () => {
    
    const res = await callGetApi(`/api/user/test-questions/?target_role_name=${encodeURIComponent(selectedVal?.role?.value || getData?.role?.value)}&skill_name=${selectedVal?.name || getData?.skill_name}&skill_type=${selectedVal?.type=="TechnicalSkills" || getData?.skill_type == "TechnicalSkills" ? "tsc" : "ccs"}`);
    console.log(res,"res getData");
    if (res) {
      dispatch(storeAllSkillTests({ type: "question", data: res?.questions }));
    }
  };

  useEffect(() => {
    if (getData || selectedVal?.role?.value) {
      getAllTests();
    }
  }, [getData?.role?.value]);

  return (
    <>
      { tabQaStatus == "start" ? (
        <DevRenewalList allQuestion={askedQuestion} getData={getData} selectedValue={selectedVal}/>
      ) : (
        <ExpertiseLevel getData={getData}/>
      ) }
    </>
  );
};
export default RenewalAllSkills;
