import React from "react";
import { Col, Row, Select } from "antd";
import "./CustomDropdown.css";
import { Typography } from "antd";
const { Text } = Typography;
const { Option } = Select;

const CustomDropdown = ({
  type,
  allRoles,
  selectedOption,
  onStateChange,
  title,
  subTitle,
  placeholder,
}) => {
  // const handleSearch = (value) => {
  //   const filteredOptions = allRoles.filter((opt) =>
  //     opt?.label.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setOptions(filteredOptions);
  // };

  const handleSelect = (value, option) => {
   // const selectedRole = allRoles?.find((it) => it.value === value);
    onStateChange({ value, label: option.children, type });
  };

  console.log(allRoles, "allRoles");

  return (
    <>
      <Row>
        <Col span={24}>
          <Text className="custom-dropdown-label">{title}</Text>
          <Text className="custom-dropdown-label description">{subTitle}</Text>
          <Select
            showSearch
            className="custom-dropdown"
            placeholder={placeholder || "Select job title"}
            optionFilterProp="children"
            // filterOption={false}
            onSelect={handleSelect}
            // onSearch={handleSearch}
            value={selectedOption || null}
            // onKeyDown={(e) => e.key === 'Enter' && handleCustomOptionKeyDown(e)}
            // notFoundContent={
            //   allRoles.length === 0 ? <p>No role found</p> : <Spin size="small" />
            // }
          >
            {allRoles?.length > 0 &&
              allRoles.map((op,i) => (
                <Option key={i} value={op.value}>
                  {op.label}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
    </>
  );
};
export default CustomDropdown;
