import { useEffect, useState } from "react";
import RenewalStartUp from "../component/skill-renewal/blank-renewal";
import DevRenewalList from "../component/skill-renewal/dev-test-list";
import RenewalList from "../component/skill-renewal/renewal-list";
import RevewalProgressList from "../component/skill-renewal/renewal-progress-list";
import SkillTestReport from "../component/test-report";
import RelevantSkillTestModal from "../modal/relevant-skill-tests";
import { useDispatch, useSelector } from "react-redux";
import { callGetApi, callTestApi } from "../../services/roadmap_api";
import { storeAllSkillTests, storeTabStatus } from "../../store/skillRenewal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Quiz from "../zztestFiles/renewalList";
import ExpertiseLevel from "../component/expertise-level";

const RenewalSkillsGraph = () => {
  return (
    <>
        <SkillTestReport />
      {/* test some regular data */}
      {/* <Quiz/> */}
    </>
  );
};
export default RenewalSkillsGraph;
