import Select from "react-select";
import {
  jobListOption,
  skillOptionList,
  skillProficiencyNOption,
  skillProficiencyOption,
  skillTypeOption,
} from "../../../../constant";
import { useDispatch, useSelector } from "react-redux";
import {
  storeAllDomains,
  storeSelValues,
  storeSkillOptions,
} from "../../../../store/domainExpert";
import { useState } from "react";
import Loader from "../../../../components/loader";
import { callGetApi, callPostApi } from "../../../../services/roadmap_api";
import { toast } from "react-toastify";
import { toMessage } from "../../../utils";

const SkillDropDown = ({ selectedValues, skillOptions }) => {
  const dispatch = useDispatch();
  const [loadingFor, setLoadingFor] = useState("");

  const handleChange = async (newValue, type) => {
    console.log(newValue, type, "selectedValuesselectedValuesselectedValues");
    if (type == "type") {
      dispatch(storeSelValues({ type: "level", value: null }));
      dispatch(storeSelValues({ type: "name", value: null }));
      await getAllSkills(newValue?.value);
    }
    dispatch(storeAllDomains({ type: "question", data: [] }));
    dispatch(storeSelValues({ type: type, value: newValue?.value }));
  };

  const getAllSkills = async (type) => {
    setLoadingFor("Getting skills...");
    // const res = await callGetApi(
    //   `/api/domain-expert/sector/target-roles/skills/?name=${
    //     selectedValues?.role?.value
    //   }&type=${type == "CoreSkills" ? "ccs" : "tsc"}`
    // );
    let payload={
      name:selectedValues?.role?.value,
      type:type == "CoreSkills" ? "ccs" : "tsc"
    }
    const res = await callPostApi(`/api/domain-expert/sector/target-roles/skills/`,payload );
    if (res && res !== undefined) {
      let updatedData = res?.map((it) => ({
        label: it?.name,
        value: it?.name,
        type: "name",
      }));
      dispatch(storeSkillOptions(updatedData));
    } else {
      toMessage("Something went wrong! try again later", "error");
    }
    setLoadingFor("");
  };

  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
  };

  const getCoustom = (val, type) => {
    if (!val) return null;

    switch (type) {
      case "type":
        return { label: val?.replace(/(?=[A-Z])/g, " ")?.trim() };
      case "name":
        return { label: val };
      default:
        if (selectedValues?.type === "CoreSkills") {
          return { label: val?.charAt(0).toUpperCase() + val?.slice(1) };
        } else {
          return { label: val?.slice(-1) };
        }
    }
  };

  return (
    <>
      {loadingFor !== "" ? <Loader text={loadingFor} /> : ""}
      <div className="mt-4 skill-dropdown d-flex align-items-center">
        <div className="skill-box">
          <label>Job Role</label>
          <Select
            isSearchable={false}
            className="filter-option-list"
            classNamePrefix="select"
            defaultValue={selectedValues?.role}
            isDisabled={true}
            styles={customStyles}
            // options={jobListOption}
          />
        </div>
        <div className="skill-box">
          <label>Skill Type</label>
          <Select
            isSearchable={false}
            className="filter-option-list"
            classNamePrefix="select"
            value={getCoustom(selectedValues?.type, "type")}
            // defaultValue={selectedValues?.type}
            options={skillTypeOption}
            onChange={(e) => handleChange(e, "type")}
          />
        </div>
        <div className="skill-box">
          <label>Skills</label>
          <Select
            className="filter-option-list"
            classNamePrefix="select"
            value={getCoustom(selectedValues?.name, "name")}
            // defaultValue={selectedValues?.name || null}
            options={skillOptions}
            onChange={(e) => handleChange(e, "name")}
          />
        </div>
        <div className="skill-box">
          <label>Proficiency Level</label>
          <Select
            isSearchable={false}
            className="filter-option-list"
            classNamePrefix="select"
            value={getCoustom(selectedValues?.level)}
            // defaultValue={{ label:selectedValues?.level} }
            options={
              selectedValues?.type == "CoreSkills"
                ? skillProficiencyOption
                : skillProficiencyNOption
            }
            onChange={(e) => handleChange(e, "level")}
          />
        </div>
      </div>
    </>
  );
};
export default SkillDropDown;
