import React, { useState, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import Dashboard from "../../assets/images/dashboard/dashboard.png";
import SkillRenewal from "../../assets/images/dashboard/skill-renewal.png";
import Learning from "../../assets/images/dashboard/learning.png";
import Goals from "../../assets/images/dashboard/goals.png";
import Roadmap from "../../assets/images/dashboard/roadmap.png";
import MytalentProfile from "../../assets/images/dashboard/my-talent-profile.png";
import Activity from "../../assets/images/dashboard/activity.png";
import Mentor from "../../assets/images/dashboard/mentor.png";
import BrowseJourney from "../../assets/images/dashboard/browse-journey.png";
import Support from "../../assets/images/dashboard/support.png";
import Community from "../../assets/images/dashboard/community.png";
import { Scrollbar } from "react-scrollbars-custom";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import AnalyserIcon from "../../assets/images/analyser-icon.png";
import PathWayIcon from "../../assets/images/pathways-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { Button } from "react-bootstrap";
import { storeTestStatus } from "../../store/domainExpert";
import { storeTabStatus } from "../../store/skillRenewal";
import { BASE_URL } from "../../api";
import { support } from "jquery";
import { toMessage } from "../utils";
import UpdateTalentprofile from "../../assets/images/dashboard/upload-talent-profile.png";
import Analyser from "../../assets/images/dashboard/analyser-icon.png";
import Pathway from "../../assets/images/dashboard/pathway-icon.png";
import Assessments from "../../assets/images/dashboard/assessment-icon.png";
import LearningSprints from "../../assets/images/dashboard/assessment-icon.png";
import MentorIcon from "../../assets/images/dashboard/mentor-icon.png";
import Communityicon from "../../assets/images/dashboard/community-icon.png";
import ActivityIcon from "../../assets/images/dashboard/activity-icon.png";
import Supporticon from "../../assets/images/dashboard/support-icon.png";
import JobSearch from "../../assets/images/dashboard/job-search.png";
import Logo from "../../assets/images/logo.png";
import CerifySkillProficiency from "../../assets/images/dashboard/certify-skill.png";
import UpdateSkillMap from "../../assets/images/dashboard/update-skill-map.png";
import AssessmentList from "../../assets/images/dashboard/assessment-list.png";
import CreateAssessment from "../../assets/images/dashboard/create-assessment.png";
import TalentHeatMap from "../../assets/images/dashboard/Telate-heat-map.png";
import TalentPool from "../../assets/images/dashboard/visual-map-generate.png";
import TrainingNeedAnalysis from "../../assets/images/dashboard/Tranning-need-analysis.png";
import ComapanyProgressReport from "../../assets/images/dashboard/progress-reports.png";
import SearchTalent from "../../assets/images/dashboard/search-telent.png";
import CreateTalentPool from "../../assets/images/dashboard/create-talent-pool.png";
import TalentPoolAdmin from "../../assets/images/dashboard/talent-pool.png";
import CreateTargetRole from "../../assets/images/dashboard/create-target-roll.png";
import TargetRole from "../../assets/images/dashboard/target-roll.png";
import CourseRagIcon from "../../assets/images/dashboard/course-rag.png";
const Sidebard = ({
  isLoggedIn,
  setIsLoggedIn,
  current_company,
  setCurrent_company,
  profileImage,
  setProfileImage,
  sideBarMenu,
  handleHideSidebarMenu,
  isOrganization,
  current_type,
  setCurrent_type,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState("");
  const location = useLocation();
  const pathname = location.pathname;
  const [showredirects, setShowredirects] = useState("Learner");

  const [goalsLink, setGoalsLink] = useState("");
  const [communityLink, setCommunityLink] = useState("");
  const [activityLink, setActivityLink] = useState("");
  const [supportLink, setSupportLink] = useState("");

  const userLo = localStorage.getItem("user");
  const user = userLo ? JSON.parse(localStorage.getItem("user")) : null;

  const [height, setHeight] = useState("100%");
  const topRef = useRef(null);
  const navTitleRef = useRef(null);
  const [dashBoardLink, setDashboardLink] = useState("");

  useEffect(() => {
    if (current_type) {
      if (current_type.id === 12) {
        setDashboardLink("/domain-expert-dashboard/");
      } else if (current_type.id === 13) {
        setDashboardLink("/organization/dashboard");
      } else if (current_type.id === 2 || current_type.id === 5) {
        setDashboardLink("/admin-dashboard");
      } else {
        setDashboardLink("/dashboard");
      }
    } else {
      setCurrent_type({ "id": 6, "name": "Learner" });
      setDashboardLink("/dashboard");
    }
  }, [current_type]);

  useEffect(() => {    
    if (current_type) {
      
      console.log(current_type.name, pathname, "current_type,pathname");
      if (
        current_type.name == "Skill Manager" &&
        (!pathname?.includes("organization") ||
          pathname?.includes("domain-expert"))
      ) {
        toMessage("You do not have permission to access this page.", "warning");
        navigate("/", { replace: true });
      } else if (
        (current_type.name == "Learner" || current_type.name == "Mentor") &&
        (!pathname?.includes("dashboard") ||
          pathname?.includes("organization") ||
          pathname?.includes("domain-expert"))
      ) {
        //toMessage("You do not have permission to access this page.", "warning");
        navigate("/dashboard", { replace: true });
      } else if (current_type.name != "Admin" && pathname?.includes("admin-dashboard")) {
        //toMessage("You do not have permission to access this page.", "warning");
        navigate("/dashboard", { replace: true });
      } else if (
        current_type.name == "Domain Expert" &&
        (!pathname?.includes("domain-expert") ||
          pathname?.includes("organization"))
      ) {
        toMessage("You do not have permission to access this page.", "warning");
        navigate("/", { replace: true });
      } 
    }
  }, [pathname, current_type]);

  useEffect(() => {
    const updateHeight = () => {
      if (topRef.current && navTitleRef.current) {
        const topHeight = topRef.current.offsetHeight;
        const navTitleHeight = navTitleRef.current.offsetHeight;
        const screenHeight = window.innerHeight;

        setHeight(`calc(100vh - ${topHeight + navTitleHeight + 50}px)`);
      }
    };

    updateHeight(); // Initial height calculation
    window.addEventListener("resize", updateHeight); // Recalculate on window resize

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const jsonData = localStorage.getItem("user");
      if (jsonData) {
        try {
          const userdata = JSON.parse(jsonData);
          console.log("user", userdata);
          setName(userdata.name);
          setProfileImage(userdata.avatar);
          setCompanies(userdata.user_companies);          
          setShowredirects(current_type.name);


          let currentCompanyData = localStorage.getItem("current_company");

          setGoalsLink(
            `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${current_type.id}&gotopage=goals`
          );
          setCommunityLink(
            `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${current_type.id}&gotopage=community`
          );
          setActivityLink(
            `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${current_type.id}&gotopage=activity`
          );
          setSupportLink(
            `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${current_type.id}&gotopage=support`
          );

          if (currentCompanyData) {
            let currentCompany = JSON.parse(currentCompanyData);
            setCurrent_company(currentCompany);
          } else {
            setCurrent_company(userdata.logged_company); // Correct way to update state
            localStorage.setItem(
              "current_company",
              JSON.stringify(userdata.logged_company)
            );            
          }          
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }
      }
    } else {
      navigate("/login");
    }
  }, [isLoggedIn, current_type]);

  const handleCompanyChange = (event) => {
    const selectedCompanyId = event.target.value;
    const selectedCompany = companies.find(
      (company) => company.id === selectedCompanyId
    );
    setCurrent_company(selectedCompany); // Correct way to update state
    localStorage.setItem(
      "current_company",
      JSON.stringify({ id: selectedCompanyId, name: selectedCompany?.name })
    );
  };

 

  if (isOrganization) {
    return (
      <div className="sidebar">
        <div className="sidebar-top">
          {/* <div className="sidebar-logo text-center mb-4">
            <Link to="/dasboard">
              <img src={Logo} alt="Logo" />
            </Link>
          </div> */}
          <div className="user-profile online">
            {/* <figure>
              <img src={profileImage} alt="" />
            </figure>
            <figcaption>
              <h4 className="mb-0">{name}</h4>
              <div className="usertype align-center">( {usertype} )</div>
            </figcaption> */}
          </div>
          <div className="change-organisation">
            <div className="color-white nav-title ps-0 pt-0">
              Organization
              {/* <span>{current_company?.name}</span> */}
            </div>
            <select
              className="form-select"
              value={current_company.id}
              onChange={handleCompanyChange}
            >
              {companies &&
                companies.map((var_company, index) => (
                  <option key={index} value={var_company.id}>
                    {var_company?.name}
                  </option>
                ))}
            </select>
          </div>
          
        </div>
        <div className="sidebar-bottom">
          <div className="nav-title">MAIN NAVIGATION</div>
          <div className="main-navigation">
            <Scrollbar style={{ height: "100vh" }}>
              <ul>
                <li
                  className={
                    pathname === "/organization/dashboard" ||
                    pathname === "/organization/dashboard/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to={"/organization/dashboard/"}>
                    <img src={Dashboard} alt="Dashboard" />
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname === "/organization/dashboard/skill-chart" ||
                    pathname === "/organization/dashboard/skill-chart/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/skill-chart">
                    <img src={TalentHeatMap} alt="Talent heatmap" />
                    <span>Talent Heat Map</span>
                  </Link>
                </li>

                <li
                  className={
                    pathname === "/organization/dashboard/visual-map" ||
                    pathname === "/organization/dashboard/visual-map/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/visual-map">
                    <img src={TalentPool} alt="Talent heatmap" />
                    <span>TalentPool Visual Map Generation</span>
                  </Link>
                </li>

                <li
                  className={
                    pathname ===
                      "/organization/dashboard/training-need-analysis" ||
                    pathname ===
                      "/organization/dashboard/training-need-analysis/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/training-need-analysis">
                    <img src={TrainingNeedAnalysis} alt="Talent heatmap" />
                    <span>Training Need Analysis</span>
                  </Link>
                </li>

                <li
                  className={
                    pathname ===
                      "/organization/dashboard/company-progress-report" ||
                    pathname ===
                      "/organization/dashboard/company-progress-report/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/company-progress-report">
                    <img src={ComapanyProgressReport} alt="Talent heatmap" />
                    <span>Company Progress Report</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname ===
                      "/organization/dashboard/search-talent" ||
                    pathname ===
                      "/organization/dashboard/search-talent/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/search-talent">
                    <img src={SearchTalent} alt="Talent heatmap" />
                    <span>Search Talent</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname ===
                      "/organization/dashboard/course-rag" ||
                    pathname ===
                      "/organization/dashboard/course-rag/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/course-rag">
                    <img src={CourseRagIcon} alt="Talent heatmap" />
                    <span>Course RAG</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname ===
                      "/organization/dashboard/create-assessment-report" ||
                    pathname ===
                      "/organization/dashboard/create-assessment-report/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/create-assessment-report">
                    <img src={CourseRagIcon} alt="Talent heatmap" />
                    <span>Create Assessment Report</span>
                  </Link>
                </li>
              </ul>
            </Scrollbar>
          </div>
        </div>
      </div>
    );
  }

  const handleClick = (type) => {
    if (type == "dashboard") {
      dispatch(storeTestStatus("list"));
    } else if (type == "analyser") {
      localStorage.removeItem("analyserData");
      localStorage.removeItem("analyserDataCopy");
    } else if (type == "renewal") {
      dispatch(storeTabStatus("list"));
    }
  };

  const getDashboardPath = (user) => {
    switch (user?.user_type) {
      case "Domain Expert":
        return "/domain-expert-dashboard";
      case "Admin":
        return "/admin-dashboard";
      default:
        return "/dashboard";
    }
  };

  return (
    <div className={`sidebar ${sideBarMenu ? "mobileSidebar" : "hidesidebar"}`}>
      <div className="sidebar-top" ref={topRef}>
        <Button className="sidebar-close-icon" onClick={handleHideSidebarMenu}>
          <MdClose />
        </Button>
        {/* <div className="sidebar-logo text-center mb-4">
          <Link to={getDashboardPath(user)}>
            <img src={Logo} alt="Logo" />
          </Link>
        </div> */}
        <div className="user-profile online">
          {/* <figure>
            <img src={profileImage} alt="" />
          </figure>
          <figcaption>
            <h4 className="mb-0">{name}  <span className="usertype align-center text-start">  ( {usertype} ) </span>
            </h4>
          </figcaption> */}
        </div>
        <div className="change-organisation">
          <div className="color-white nav-title ps-0 pt-0">Organization</div>
          {/* <h6>
            <span>{current_company?.name}</span>
          </h6> */}
          <select
            className="form-select"
            value={current_company.id}
            onChange={handleCompanyChange}
          >
            {companies &&
              companies.map((var_company, index) => (
                <option key={index} value={var_company.id}>
                  {var_company?.name}
                </option>
              ))}
          </select>
        </div>
        
      </div>
      <div className="sidebar-bottom">
        <div className="nav-title" ref={navTitleRef}>
          MAIN NAVIGATION
        </div>
        <div className="main-navigation">
          <Scrollbar style={{ height: height }}>
            <ul>
              <li
                className={
                  pathname === "/dashboard" ||
                  pathname === "/dashboard/" ||
                  pathname === "/domain-expert-dashboard" ||
                  pathname === "/domain-expert-dashboard/"
                    ? "active"
                    : ""
                }
              >
                <Link
                  onClick={() => {
                    handleHideSidebarMenu();
                    handleClick("dashboard");
                  }}
                  to={dashBoardLink}
                >
                  <img src={Dashboard} alt="Dashboard Icon" />
                  <span>Dashboard</span>
                </Link>
              </li>
              {current_type.name=== "Admin" && (
                <>
                  <li>
                    <a
                      rel="noopener"
                      href={`${BASE_URL}/auto-login/?access=${user?.token}&company=${user?.company}&type=${current_type.id}`}
                    >
                      <img src={Dashboard} alt="Old DashBoard" />
                      <span>Old Dashboard</span>
                    </a>
                  </li>

                  <li>
                    <Link to={"/admin-dashboard/talent-pool"}>
                      <img src={CreateTalentPool} alt="Target Role" />{" "}
                      <span>Create Talent Pool</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/admin-dashboard/talent-pools"}>
                      <img src={TalentPoolAdmin} alt="Talent Pools" />{" "}
                      <span>Talent Pools</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/admin-dashboard/assign-employees-talentpool"}>
                      <img src={Dashboard} alt="Target Role" />{" "}
                      <span>Employees to Talent Pool</span>
                    </Link>
                  </li>

                  <li>
                    <Link to={"/admin-dashboard/create-role"}>
                      <img src={CreateTargetRole} alt="Target Role" />{" "}
                      <span>Create Target Role</span>
                    </Link>
                  </li>

                  <li>
                    <Link to={"/admin-dashboard/roles-list"}>
                      <img src={TargetRole} alt="target Roles List" />{" "}
                      <span>Target Roles</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
            {pathname && pathname.includes("/domain-expert-dashboard") && (
              <>
                <div className="nav-menu-bar menu-bar-list">
                  {" "}
                  <ul>
                    <li
                      className={
                        pathname ===
                          "/domain-expert-dashboard/certify-skill-proficiency/" ||
                        pathname ===
                          "/domain-expert-dashboard/certify-skill-proficiency"
                          ? "active"
                          : ""
                      }
                    >
                      <Link
                        to={
                          "/domain-expert-dashboard/certify-skill-proficiency/?role=no_role"
                        }
                      >
                        <img src={CerifySkillProficiency} alt="Dashboard" />{" "}
                        <span>Certify Skill Proficiency</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="nav-menu-bar menu-bar-list">
                  {" "}
                  <ul>
                    <li
                      className={
                        pathname ===
                          "/domain-expert-dashboard/update-skillmap" ||
                        pathname === "/domain-expert-dashboard/update-skillmap/"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={"/domain-expert-dashboard/update-skillmap"}>
                        <img src={UpdateSkillMap} alt="Dashboard" />{" "}
                        <span>Update Skillmap</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="nav-menu-bar menu-bar-list">
                  {" "}
                  <ul>
                    <li
                      className={
                        pathname ===
                          "/domain-expert-dashboard/skill-matrix" ||
                        pathname === "/domain-expert-dashboard/skill-matrix/"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={"/domain-expert-dashboard/skill-matrix"}>
                        <img src={UpdateSkillMap} alt="Dashboard" />{" "}
                        <span>Skill Matrix</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <Accordion defaultActiveKey={["Assessments"]} alwaysOpen>
                  <Accordion.Item eventKey="Assessments">
                    <Accordion.Header>Assesments</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li
                          className={
                            pathname ===
                              "/domain-expert-dashboard/assessment-list" ||
                            pathname ===
                              "/domain-expert-dashboard/assessment-list/"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to={"/domain-expert-dashboard/assessment-list"}>
                            <img src={AssessmentList} alt="Dashboard" />{" "}
                            <span>Assessment List</span>
                          </Link>
                        </li>
                        <li
                          className={
                            pathname ===
                              "/domain-expert-dashboard/assessment" ||
                            pathname === "/domain-expert-dashboard/assessment/"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to={"/domain-expert-dashboard/assessment"}>
                            <img src={CreateAssessment} alt="Dashboard" />{" "}
                            <span>Create Assessment</span>
                          </Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            )}
            {current_type.name=== "Learner" && (
              <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
                {pathname && pathname.includes("/dashboard") && (
                  <>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Goals</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li
                            className={
                              pathname === "/dashboard/talent-profile" ||
                              pathname === "/dashboard/talent-profile/"
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="talent-profile">
                              <img src={MytalentProfile} alt="Skill Renewal" />
                              <span>My Talent Profile</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname === "/dashboard/talent-profile/create" ||
                              pathname === "/dashboard/talent-profile/create/"
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="talent-profile/create">
                              <img
                                src={UpdateTalentprofile}
                                alt="Talent Profile"
                              />{" "}
                              <span>Update Talent Profile</span>
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Skill Compass</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li
                            className={
                              pathname === "/dashboard/analyser" ||
                              pathname === "/dashboard/analyser/"
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="analyser"
                              onClick={() => {
                                handleClick("analyser");
                                handleHideSidebarMenu();
                              }}
                            >
                              <img src={Analyser} alt="Skill Renewal" />
                              <span>Analyser</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname === "/dashboard/skill-compass/pathway" ||
                              pathname === "/dashboard/skill-compass/pathway/"
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="skill-compass/pathway"
                              onClick={handleHideSidebarMenu}
                            >
                              <img src={Pathway} alt="Pathway" />{" "}
                              <span>Pathway</span>
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Skill Renewal</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li
                            className={
                              pathname === "/dashboard/renewal" ||
                              pathname === "/dashboard/renewal/" ||
                              pathname === "/dashboard/renewal/skills_graph/" ||
                              pathname === "/dashboard/renewal/skills_graph" ||
                              pathname === "/dashboard/renewal/skills" ||
                              pathname === "/dashboard/renewal/skills/"
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="renewal"
                              onClick={() => {
                                handleClick("renewal");
                                handleHideSidebarMenu();
                              }}
                            >
                              <img src={Assessments} alt="Skill Renewal" />
                              <span>Assessments</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname === "/dashboard/learning" ? "active" : ""
                            }
                          >
                            <Link
                              to="/dashboard/learning"
                              onClick={handleHideSidebarMenu}
                            >
                              <img src={LearningSprints} alt="Learning" />{" "}
                              <span>Learning Sprints</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname === "/dashboard/mentors" ? "active" : ""
                            }
                          >
                            <Link
                              to="/dashboard/mentors"
                              onClick={handleHideSidebarMenu}
                            >
                              <img src={MentorIcon} alt="Mentor" />
                              <span>Mentor</span>
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}

                {showredirects === "Learner" && (
                  <>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Search Job</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li
                            className={
                              pathname === "/dashboard/job-search" ? "active" : ""
                            }
                          >
                            <Link
                              rel="noopener"
                              to={`/dashboard/job-search`}
                              onClick={handleHideSidebarMenu}
                            >
                              <img src={JobSearch} alt="Job Search" />{" "}
                              <span>Job Search</span>
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Support</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {/* <li
                            className={
                              pathname === "/dashboard" ? "active" : ""
                            }
                          >
                            <a
                              rel="noopener"
                              href={communityLink}
                              onClick={handleHideSidebarMenu}
                              target="_blank"
                            >
                              <img src={Communityicon} alt="Community" />{" "}
                              <span>Community</span>
                            </a>
                          </li> */}
                          <li
                            className={
                              pathname === "/dashboard/activity" ? "active" : ""
                            }
                          >
                            <a
                              rel="noopener"
                              href={activityLink}
                              onClick={handleHideSidebarMenu}
                              target="_blank"
                            >
                              <img src={ActivityIcon} alt="Activity" />
                              <span>Activity</span>
                            </a>
                          </li>
                          <li
                            className={
                              pathname === "/dashboard/Support" ? "active" : ""
                            }
                          >
                            <a
                              rel="noopener"
                              href={supportLink}
                              onClick={handleHideSidebarMenu}
                              target="_blank"
                            >
                              <img src={Supporticon} alt="Support" />
                              <span>Support</span>
                            </a>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
              </Accordion>
            )}
          </Scrollbar>
        </div>
      </div>
    </div>
  );
};

export default Sidebard;
