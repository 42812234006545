// SkillChart.js

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ReactApexChart from 'react-apexcharts';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SkillChart = ({ chart_label, color, barData }) => {
  const labels = barData.map(item => item.name);
  const values = barData.map(item => item.value);
  const data = {
    //labels: barData.map(item => item.name),
    series: [
      {
        name: chart_label,
        data: values, 
      },
    ],
    // datasets: [
    //   {
    //     label: chart_label,
    //     backgroundColor: color,
    //     borderColor: 'rgba(75,192,192,1)',
    //     borderWidth: 1,
    //     hoverBackgroundColor: 'rgba(75,192,192,0.4)',
    //     hoverBorderColor: 'rgba(75,192,192,1)',
    //     data: barData.map(item => item.value),
    //   },
    // ],
  };

  // const options = {
  //   responsive: true,
  //   indexAxis: 'y', 
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //     },
  //     title: {
  //       display: true,
  //       text: `${chart_label}`,
  //     },
  //   },
  //   scales: {
  //     x: {
  //       beginAtZero: true,
  //     },
  //     y: {
  //       ticks: {
  //         autoSkip: false, // Ensure all labels are displayed
  //         font: {
  //           size: 12, // Adjust font size if needed
  //         },
  //         padding: 10, // Add padding for better readability
  //       },
  //     },
  //   },
  // };
  
  const options = {
    chart: {
      type: 'area',
      height: 400,
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: chart_label,
      align: 'center',
    },
    
    xaxis: {
      categories: labels, 
      title: {
        text: 'Skills', 
      },
      labels: {
        show: true,
        formatter: (value) => {
          const maxLength = 10; 
          return value?.length > maxLength
            ? value.substring(0, maxLength) + "..."
            : value;
        }, 
      },
    },
    yaxis: {
      min: 0,
      title: {
        text: 'Values', 
      },
      labels: {
        show: true, 
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          const fullSkillName = labels[dataPointIndex]; // Get full skill name
          const maxLength = 10;
          const displaySkillName = fullSkillName?.length > maxLength 
            ? fullSkillName.substring(0, maxLength) 
            : fullSkillName;
          return `${displaySkillName}: ${value}`;
        }
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
      colors: ['#2853e0'], 
    },
    fill: {
      type: 'gradient', 
      gradient: {
        shade: 'light',
        type: 'vertical',
        gradientToColors: ['#1ff767'], 
        opacityFrom: 0.5,
        opacityTo: 0.5,
      },
    },
   
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'top',
    },
  };

  return (
    // <Bar data={data} options={options} />
    <ReactApexChart options={options} series={data.series} type="area" height={400} />
  )
};

export default SkillChart;
