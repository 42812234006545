import { Button, Space, Table, Switch, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  storeAllSkillTests,
  storeQaTabStatus,
  storeSavedSkillData,
  storeSelValues,
  storeTabStatus,
} from "../../../store/skillRenewal";
import { useNavigate } from "react-router-dom";
import {
  callGetApi,
  callPostApi,
  callPutApi,
} from "../../../services/roadmap_api";
import { toast } from "react-toastify";
import Loader from "../../../components/loader";
import {
  getCoreMap,
  getCoreSingleMap,
  getTecMap,
  getTecSingleMap,
  toMessage,
} from "../../utils";
import NoMcqs from "../../../components/modal/noQuestions";

const ProgressTableList = ({
  dataSource,
  skillType,
  conSkill,
  setConSkill,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = JSON?.parse(localStorage?.getItem("user"));
  const renewalDataString = localStorage.getItem("renewal-data");
  const renewData =
    renewalDataString && renewalDataString != undefined
      ? JSON.parse(renewalDataString)
      : null;

  const { selectedVal, runNextSkill } = useSelector(
    (state) => state?.skillRenewal
  );
  console.log(selectedVal, "selectedVal");
  const { Column, ColumnGroup } = Table;
  const [toggleState, setToggleState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [openMod, setOpenMod] = useState(false);

  const handleToggle = () => {
    setToggleState(!toggleState);
  };

  const postSkillRenewal = async (name, level, id, cpro) => {
    setLoading({ is: true, id });
    let payload = [
      {
        id: 0,
        skill_name: name,
        skill_type: selectedVal?.type == "TechnicalSkills" ? "tsc" : "ccs",
        proficiency: level,
        action: "in-progress",
        user_id: user?.user_id || "1111",
        current_proficiency: cpro || "",
        asked_question: "",
        current_question: "",
        remaining_time: "",
        status: false,
        level_count: "0",
        target_role_name:
          selectedVal?.role?.label || renewData?.target_role_name || "",
      },
    ];
    const res = await callPostApi(`/api/user/skill-renewal/`, payload);

    if (res && res?.length > 0) {
      let ids = res?.map((it) => it?.id?.toString());
      const res2 = await callPutApi(
        `/api/user/skill-renewal-dashboard/${renewData?.id || "1"}`,
        { skill_ids: ids }
      );
      console.log(res2, "res skill post 222");
      let val = { ...selectedVal, ...res[0] };
      localStorage.setItem("mcq_skills", JSON.stringify(val));
      if (res2 && res2 !== undefined) {
        localStorage.setItem("renewal-data", JSON.stringify(res2));
      }
      dispatch(storeSavedSkillData(res[0]));
    }
    setLoading({ is: "", id: "" });
  };

  const getAllTestQuestions = async (name, level) => {
    setLoadingFor("Getting your question for tests...");
    const res = await callGetApi(
      `/api/user/test-questions/?target_role_name=${encodeURIComponent(
        selectedVal?.role?.value || renewData?.target_role_name
      )}&skill_name=${name}&skill_type=${
        selectedVal?.type == "TechnicalSkills" ? "tsc" : "ccs"
      }`
    );
    if (res && res?.questions?.length > 30) {
      dispatch(storeAllSkillTests({ type: "question", data: res?.questions }));
      navigate("skills");
      dispatch(storeQaTabStatus("start"));
      setLoadingFor("");
      return true;
    } else {
      setLoadingFor("");
      if (res?.questions?.length == 0) {
        setOpenMod(true);
        // toMessage("No questions found for the selected skill", "error");
      } else {
        setOpenMod(true);
        // toMessage("No questions found for the selected skill", "error");
      }
      localStorage.removeItem("is_continue");
      localStorage.removeItem("next_skill");
      localStorage.removeItem("mcq_skills");
      return false;
    }
  };

  // const removed=dataSource?.filter((it)=>it?.action!="completed" && !text?.skill_name==it?.skill_name)
  // const index = dataSource.findIndex(item => item.name === removed[0]?.name);
  // console.log(index,removed,"removed")
  // if(removed?.length>0){
  //   let skillAdd = { ...removed[0], type:removed[0]?.action=="in-progress"?"continue":removed[0]?.action=="completed"?"retake":"start", index:removed[0]?.index };
  //   localStorage.setItem("next_skill", JSON.stringify(skillAdd));

  // }

  const storeNextSkill = (ind, type) => {
    const { current, pageSize } = pagination;
    let index = (current - 1) * pageSize + ind;

    let nextSkill;
    let skillAdd;
    do {
      nextSkill = dataSource?.[index + 1];
      index += 1;
    } while (nextSkill && nextSkill.action === "completed");

    if (nextSkill) {
      skillAdd = {
        ...nextSkill,
        type:
          nextSkill.action === "in-progress"
            ? "continue"
            : nextSkill.action === "completed"
            ? "retake"
            : "start",
        index: index,
      };
      console.log(skillAdd, "skillAdd");
      localStorage.setItem("next_skill", JSON.stringify(skillAdd));
    }
  };
  const handleProceed = async (type, text, ind) => {
    storeNextSkill(ind, type);

    let val = { ...selectedVal, ...text };
    localStorage.setItem("mcq_skills", JSON.stringify(val));
    let name = text?.skill_name,
      lavel = text?.proficiency,
      id = text?.id,
      cpro = text?.current_proficiency;
    dispatch(storeSelValues({ type: "name", value: name }));
    dispatch(
      storeSelValues({
        type: "level",
        value: lavel,
      })
    );
    let sType = skillType == "core" ? "CoreSkills" : "TechnicalSkills";
    dispatch(storeSelValues({ type: "type", value: sType }));
    if (type == "continue") {
      localStorage.setItem("is_continue", true);
    } else {
      localStorage.removeItem("is_continue");
    }
    let res = await getAllTestQuestions(name, lavel);
    if (type == "start" && res == true) {
      await postSkillRenewal(name, lavel, id, cpro);
    }
  };
  useEffect(() => {
    if (runNextSkill) {
      let next = JSON?.parse(localStorage.getItem("next_skill"));

      if (next) {
        if (next?.skill_name) {
          handleProceed(next?.type, next, next?.index);
        } else {
          toMessage("No next skill pending for Renewal", "warning");
          localStorage.removeItem("next_skill");
        }
      }
    }
  }, [runNextSkill]);

  useEffect(() => {
    if (conSkill) {
      console.log(conSkill, "conSkill");
      let action = conSkill?.action == "in-progress" ? "continue" : "start";
      const index = dataSource?.findIndex(
        (it) => it?.skill_name === conSkill?.skill_name
      );
      const finalIndex = index !== -1 ? index : 0;
      handleProceed(action, conSkill, finalIndex);
      setConSkill("");
    }
  }, [conSkill]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  console.log(dataSource);

  return (
    <>
      {loadingFor !== "" ? <Loader text={loadingFor} /> : ""}
      <div className="progress-list-table">
        <Table dataSource={dataSource} onChange={handleTableChange}>
          <Column
            title="Sr.No"
            dataIndex="id"
            render={(text, record, index) => {
              const { current, pageSize } = pagination;
              return <span>{(current - 1) * pageSize + index + 1}</span>;
            }}
          />
          <Column title="Skill Name" dataIndex="skill_name" key="skill_name" />
          <Column
            title={
              <Space>
                Proficiency
                {/* {toggleState ? "Expected Proficiency" : "Current Proficiency  1"}
                <Switch checked={toggleState} onChange={handleToggle} /> */}
              </Space>
            }
            key="proficiency"
            render={(record) => (
              <>
                {skillType === "core" ? (
                  <Space size="middle">
                    {/* {getCoreMap(record?.proficiency || "basic",record?.current_proficiency || "")} */}
                    <div className="current-prof">
                      <span className="skill-view-field d-flex align-items-center">
                        {getCoreSingleMap(record?.current_proficiency || "")}
                      </span>
                    </div>
                    <div className="expecred-prof">
                      <span className="skill-view-field d-flex align-items-center">
                        {getCoreSingleMap(record?.proficiency || "basic")}
                      </span>
                    </div>

                    {/* {getCoreSingleMap(
                        toggleState
                          ? record?.proficiency || "basic"
                          : record?.current_proficiency || "",
                        toggleState
                      )} */}
                  </Space>
                ) : (
                  <>
                    {/* {getTecMap( record?.proficiency || "1", record?.current_proficiency || "")} */}
                    <div className="current-prof">
                      <span className="skill-view-field d-flex align-items-center">
                        {getTecSingleMap(record?.current_proficiency || "")}
                      </span>
                    </div>
                    <div className="expecred-prof">
                      <span className="skill-view-field d-flex align-items-center">
                        {getTecSingleMap(record?.proficiency || "1")}
                      </span>
                    </div>

                    {/* {getTecSingleMap(
                      toggleState
                        ? record?.proficiency || "1"
                        : record?.current_proficiency || "",
                      toggleState
                    )} */}
                  </>
                )}
              </>
            )}
          />

          <Column
            title="Action"
            key="action"
            width={190}
            render={(text, _, index) => (
              <Space size="middle">
                {console.log(text, _, index, "text, _, index")}
                {!text?.action || text?.action == "" ? (
                  <Button
                    type="primary"
                    onClick={() => handleProceed("start", text, index)}
                    disabled={loading?.is}
                  >
                    {loading?.is && loading?.id == text?.id
                      ? "loading..."
                      : "Take Test"}
                  </Button>
                ) : text?.action == "in-progress" ? (
                  <>
                    <Button
                      type="primary"
                      disabled={loading?.is}
                      onClick={() => handleProceed("continue", text, index)}
                    >
                      {loading?.is && loading?.id == text?.id
                        ? "loading..."
                        : "Continue"}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => handleProceed("restart", text, index)}
                      disabled={loading?.is}
                    >
                      Restart
                    </Button>
                  </>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => handleProceed("retake", text, index)}
                    disabled={loading?.is}
                  >
                    {loading?.is && loading?.id == text?.id
                      ? "loading..."
                      : "Retake Test"}
                  </Button>
                )}
                {/* <Button
                      className="primary-border-btn"
                      disabled={loading?.is}
                      onClick={() => handleProceed("continue", text,index)}
                    >
                      {loading?.is && loading?.id == text?.id
                        ? "loading..."
                        : "Conitue"}
                    </Button> */}
              </Space>
            )}
          />
        </Table>
      </div>
      <div className="tooltip-Proficiency-field">
        <div className="Proficiency-lavel">
          <div className="prf-level">
            <span className="current-level"></span>
            <span>Current Proficiency</span>
          </div>
          <div className="prf-level">
            <span className="Expected-level"></span>
            <span>Expected Proficiency</span>
          </div>
        </div>
      </div>
      <NoMcqs show={openMod} handleClose={() => setOpenMod(false)} />
    </>
  );
};

export default ProgressTableList;
