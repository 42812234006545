export const BASE_URL = "https://dashboard.skillmotion.ai";

// export const BASE_URL = "http://127.0.0.1:8000";


const refreshToken = async () => {
    try {
      // Make a request to refresh the token
      
      const jsonData = localStorage.getItem("user");
      let userdata = JSON.parse(jsonData);
      if (jsonData) {        
          const response = await fetch(`${BASE_URL}/api/refresh-token/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${userdata.token}`,
            },
            body: JSON.stringify({
              token: userdata.token,
              compnay: userdata.compnay,
              type: userdata.type,
            }),
          });

          if (response.ok) {
            // Token refreshed successfully, update access token in state
            const data = await response.json();
            console.log("refreshdata", data);
            localStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(data.data));
             return data.token;
          } else {
              // Handle token refresh failure
              window.location.href = "/logout";
            console.log("Token refresh failed:", response.statusText);
            // Redirect to login page or perform other actions
          }
        
      }
    } catch (error) {
      console.log("Error refreshing token:", error);
      // Handle token refresh failure
    }
  };

const apiFetch = async (url, method = 'GET', data = null, headers = {}) => {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers,  // Custom headers can be added
        },
    };

    if (data) {
        options.body = JSON.stringify(data); // Attach data for POST, PUT requests
    }

    try {
        const response = await fetch(BASE_URL + url, options);
        if (response.status === 401) {
            console.log('Access token expired. Attempting to refresh token...');
            
            // Attempt to refresh the token
            const newAccessToken = await refreshToken();
            
            // Retry the original request with the new token
            options.headers['Authorization'] = `Token ${newAccessToken}`;
            response = await fetch(BASE_URL + url, options);
        }

        // Check if response status is OK (200-299)
        if (!response.ok) {
            const errorData = await response.json();
            console.log(errorData || 'Something went wrong');
        }

        // Return response as JSON, if applicable
        return await response.json();
    } catch (error) {
        console.log(`Error in ${method} request to ${url}:`, error);
    }
};


const apiFormPost = async (url, method = 'GET', data = null, headers = {}) => {
    const options = {
        method,
        headers: {
            'Content-Type': 'multipart/form-data',
            ...headers,  // Custom headers can be added
        },
    };

    if (data) {
        options.body = JSON.stringify(data); // Attach data for POST, PUT requests
    }

    try {
        const response = await fetch(BASE_URL + url, options);
        if (response.status === 401) {
            console.log('Access token expired. Attempting to refresh token...');
            
            // Attempt to refresh the token
            const newAccessToken = await refreshToken();
            
            // Retry the original request with the new token
            options.headers['Authorization'] = `Token ${newAccessToken}`;
            response = await fetch(BASE_URL + url, options);
        }

        // Check if response status is OK (200-299)
        if (!response.ok) {
            const errorData = await response.json();
            console.log(errorData || 'Something went wrong');
        }

        // Return response as JSON, if applicable
        return await response.json();
    } catch (error) {
        console.log(`Error in ${method} request to ${url}:`, error);
    }
};

const apiFetchOnly= async (url, method = 'GET', data = null, headers = {}) => {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers,  // Custom headers can be added
        },
    };

    if (data) {
        options.body = JSON.stringify(data); // Attach data for POST, PUT requests
    }

    try {
        await fetch(BASE_URL + url, options);        
    } catch (error) {
        console.error(`Error in ${method} request to ${url}:`, error);
        throw error;
    }
};

const apiFetchFormData = async (url, method = 'POST', data = null, headers = {}) => {
    const options = {
        method,
        headers: {
            ...headers, // Custom headers can be added
        },
    };

    if (data) {
        options.body = data; // Attach FormData for POST, PUT requests
    }

    try {
        let response = await fetch(BASE_URL + url, options);

        if (response.status === 401) {
            console.log('Access token expired. Attempting to refresh token...');

            // Attempt to refresh the token
            const newAccessToken = await refreshToken();

            if (newAccessToken) {
                // Retry the original request with the new token
                options.headers['Authorization'] = `Token ${newAccessToken}`;
                response = await fetch(BASE_URL + url, options);
            }
        }

        // Check if response status is OK (200-299)
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Something went wrong');
        }

        // Return response as JSON, if applicable
        return await response.json();
    } catch (error) {
        console.error(`Error in ${method} request to ${url}:`, error);
        throw error;
    }
};

export { apiFetchOnly, apiFetchFormData };

export default apiFetch;
