import { Breadcrumb, Button, Input, Progress, Radio, Space } from "antd";
import { useEffect, useState } from "react";
import { TimerIcon } from "../../../assets/icon";
import { questionsList } from "../../../constant";
import { callGetApi, callPutApi } from "../../../services/roadmap_api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  storeNextSkillRun,
  storeQaTabStatus,
  storeRunSkills,
  storeTabStatus,
} from "../../../store/skillRenewal";
import {
  capitalizeFirstLetter,
  getCoreLevel,
  getTecLevel,
  toMessage,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
const DevRenewalList = ({ allQuestion, getData, selectedValue }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isUser = localStorage?.getItem("user");
  const renewalDataString = localStorage.getItem("renewal-data");
  const isContinue = localStorage.getItem("is_continue");

  const renewData =
    renewalDataString && renewalDataString != undefined
      ? JSON.parse(renewalDataString)
      : null;
  const user = isUser ? JSON.parse(isUser) : null;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [remainingTime, setRemainingTime] = useState(20 * 60);
  const [timerRunning, setTimerRunning] = useState(false);
  const [nextDisable, setNextDisable] = useState(true);
  const [askedQues, setAskedQues] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [letestQues, setLetestQues] = useState(-1);
  const [levelCount, setLevelCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimerRunning(true);
  }, [allQuestion]);

  useEffect(() => {
    let countdown;
    if (timerRunning && remainingTime > 0) {
      countdown = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (remainingTime === 0) {
      handleCountDownFinish();
    }

    return () => clearInterval(countdown);
  }, [remainingTime, timerRunning]);

  const getQuesLength = (ques) => {
    return ques ? Math.min(ques, 30) : 0;
  };

  const formatTime = () => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // console.log(selectedAnswer, "askProfi selectedAnswer");
  const handleNextQuestion = (index) => {
    console.log(
      allQuestion[letestQues],
      selectedAnswer[letestQues],
      index,
      "askProfi data"
    );

    const question = allQuestion[letestQues];
    const selectedQuestion = selectedAnswer[letestQues];

    const updatedAskedQuestions = { ...askedQues };
    const newIndex = Object.keys(updatedAskedQuestions).length + 1;
    updatedAskedQuestions[newIndex] = question?.question;
    setAskedQues(updatedAskedQuestions);

    let nextProficiency = "intermediate";
    if (selectedQuestion?.isCorrect) {
      nextProficiency =
        selectedQuestion?.level === "basic" ? "intermediate" : "advanced";
    } else {
      nextProficiency =
        selectedQuestion?.level === "advanced"
          ? "intermediate"
          : selectedQuestion?.level || "intermediate";
    }

    console.log(nextProficiency, "askProfi ,pro");

    let resData = allQuestion.filter(
      (it) =>
        it?.proficiency === nextProficiency &&
        !Object.values(updatedAskedQuestions).includes(it?.question)
    );
    console.log(resData, "askProfi ,resData");
    if (resData.length === 0) {
      resData = allQuestion.filter(
        (it) => !Object.values(updatedAskedQuestions).includes(it?.question)
      );
      if (resData?.length === 0) {
        resData = allQuestion.filter((it) => it);
      }
      console.log(resData, "askProfi ,resData.length === 0");
    }
    const getRandomIndex = (max) => {
      return Math.floor(Math.random() * max);
    };

    const randomIndex = getRandomIndex(resData.length);
    let que = resData?.[randomIndex]?.question;
    let indexOfQuestion = allQuestion.indexOf(
      allQuestion.find((it) => it?.question === que)
    );
    console.log(
      indexOfQuestion,
      currentQuestion,
      getQuesLength(allQuestion?.length) - 1,
      "askProfi ,indexOfQuestion currentQuestion getQuesLength(allQuestion?.length) - 1"
    );

    if (currentQuestion < getQuesLength(allQuestion?.length) - 1) {
      setNextDisable(true);
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      setCurrentIndex(indexOfQuestion);
    }
  };

  function getLastIndex(obj) {
    let lastIndex = -1;
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const index = parseInt(key);
        if (!isNaN(index) && index > lastIndex) {
          lastIndex = index;
        }
      }
    }
    return lastIndex;
  }

  const handleCountDownFinish = () => {
    let lastIndexOfSelected = Object.keys(selectedAnswer)?.length;
    let preData = { ...selectedAnswer };
    if (lastIndexOfSelected < 30) {
      for (
        let i = lastIndexOfSelected + 1;
        i <= getQuesLength(allQuestion?.length);
        i++
      ) {
        const selectedOption = {
          index: i,
          isCorrect: selectedAnswer[i]?.isCorrect || false,
          level: allQuestion[i]?.proficiency,
        };
        preData[i] = selectedOption;
        setSelectedAnswer(preData);
      }
    }
    setTimeout(() => {
      handleFinish(preData);
    }, 1000);
  };

  const handleFinish = async (preData) => {
    setLoading(true);
    const category =
      selectedValue?.type === "TechnicalSkills" ||
      getData?.skill_type === "TechnicalSkills" ||
      getData?.skill_type === "tsc"
        ? getTecLevel(preData || selectedAnswer, levelCount)
        : getCoreLevel(preData || selectedAnswer, levelCount);

    let newData = {
      ...getData,
      proficiency: category?.level,
      level: category?.percentage,
    };

    localStorage?.setItem("mcq_skills", JSON?.stringify(newData));

    const getProfStatus = (ex, cu, type) => {
      if (!ex || !cu || !type) return false;
      if (type === "CoreSkills" || type === "ccs") {
        const levels = ["basic", "intermediate", "advanced"];
        const exIndex = levels.indexOf(ex?.toLowerCase());
        const cuIndex = levels.indexOf(cu?.toLowerCase());
        return cuIndex >= exIndex;
      } else {
        const cuIndex = cu.slice(-1);
        return cuIndex >= ex;
      }
    };

    let payload = {
      // id: getData?.id,
      // skill_name: getData?.skill_name,
      // skill_type: getData?.skill_type,
      // proficiency: getData?.proficiency,
      action: "completed",
      current_proficiency: category?.level,
      user_id: user?.user_id || "1111",
      asked_question: "",
      current_question: "",
      remaining_time: "",
      status: getProfStatus(
        getData?.proficiency,
        category?.level,
        selectedValue?.type || getData?.skill_type
      ),
      level_count: "0",
    };

    localStorage.removeItem("is_continue");
    const res = await callPutApi(
      `/api/user/skill-renewal/${getData?.id || "1"}`,
      payload
    );
    if (res && res != undefined) {
      setLoading(false);

      const reachedCount = payload?.status
        ? Number(renewData?.reached_count) + 1
        : Number(renewData?.reached_count);
      const roleStatus =
        Number(renewData?.total_count) <= reachedCount
          ? "completed"
          : "in-progress";

      console.log(roleStatus, renewData?.status,reachedCount, "roleStatus");

      if (renewData && renewData?.status != roleStatus) {
        const res2 = await callPutApi(
          `/api/user/skill-renewal-dashboard/${renewData?.id || "1"}`,
          { status: roleStatus }
        );
        console.log(res2, "skill-renewal-dashboard updated");
      }
      toMessage("Skill proficiency updated successfully.", "success");
      let newData = {
        ...getData,
        proficiency: category?.level,
        level: category?.percentage,
      };
      console.log(newData, "newData");
      localStorage?.setItem("mcq_skills", JSON?.stringify(newData));
      dispatch(storeQaTabStatus("level"));
    }
    setLoading(false);
  };

  const handleRadioChange = (e, index, data) => {
    const selectedValue = e.target.value;

    let indexOfQue = allQuestion.findIndex(
      (it) => it?.question === data?.question
    );

    console.log(indexOfQue, "e, index, data");
    setLetestQues(indexOfQue);
    const isCorrect = data?.answer?.charAt(0) === selectedValue?.charAt(0);
    const selectedOption = { index, isCorrect, level: data?.proficiency };

    setSelectedAnswer((prevState) => ({
      ...prevState,
      [index]: selectedOption,
    }));

    setNextDisable(false);
  };

  function removeNumber(str) {
    if (str) {
      const regex = /^\d+\.\s*/;
      const result = str?.replace(regex, "");

      return result;
    }
    return "";
  }

  const handlePause = async () => {
    setTimerRunning(false);
    let payload = {
      action: "in-progress",
      asked_question: JSON.stringify(askedQues),
      current_question:
        currentQuestion > 30 ? "1" : currentQuestion?.toString(),
      remaining_time: remainingTime?.toString(),
      level_count:
        selectedValue?.type === "TechnicalSkills" ||
        getData?.skill_type === "TechnicalSkills" ||
        getData?.skill_type === "tsc"
          ? getTecLevel(selectedAnswer, levelCount, "pause").toString()
          : getCoreLevel(selectedAnswer, levelCount, "pause").toString(),
    };
    const res = await callPutApi(
      `/api/user/skill-renewal/${getData?.id || "1"}`,
      payload
    );
    if (res) {
      console.log(res, "resresres");
      localStorage.removeItem("is_continue");
      localStorage.removeItem("next_skill");
      dispatch(storeTabStatus("skills"));
      dispatch(storeRunSkills(true));
      dispatch(storeNextSkillRun(false));
      navigate("/dashboard/renewal", { replace: true });
    }
  };

  const handleResume = async () => {
    const pauseData = await callGetApi(
      `/api/user/skill-renewal/${getData?.id || "1"}`
    );
    console.log(pauseData, "pauseData");
    if (pauseData && pauseData != undefined) {
      const {
        remaining_time = 0,
        level_count = 0,
        current_question = 0,
        asked_question = {},
      } = pauseData;

      if (pauseData && remaining_time != 0 && askedQues) {
        setRemainingTime(Number(remaining_time) + 1);
        setCurrentQuestion(Number(current_question));
        setAskedQues(asked_question ? JSON.parse(asked_question) : {});
        setLevelCount(Number(level_count) || 0);
      }
      const resData = allQuestion?.filter(
        (it) => !Object.values(asked_question).includes(it?.question)
      );
      const que = resData?.[0]?.question;
      const indexOfQuestion =
        allQuestion.findIndex((it) => it?.question === que) || 1;
      let number = randomNumber(allQuestion);
      setCurrentIndex(number);
    }
    setTimerRunning(true);
  };

  const randomNumber = (ques) => {
    const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
    let num = random(1, ques?.length);
    return num;
  };

  useEffect(() => {
    if (isContinue) {
      handleResume();
    }
  }, [isContinue]);

  function cleanString(str) {
    if (str) {
      return str?.replace(/^[a-zA-Z]\)|^[a-zA-Z]\./, "")?.trim();
    }
    return "";
  }

  console.log(allQuestion[currentIndex]?.answer, "Check Answer");

  return (
    <Card>
      <div className="card-header">
      <div className="page-heading-bar">
        <h5 className="card-title">
          {capitalizeFirstLetter(
            selectedValue?.role?.label ||
              getData?.role?.label ||
              renewData?.target_role_name ||
              "Software Engineers"
          )}
        </h5>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "Assessments",
            },
            {
              title:
                selectedValue?.role?.label ||
                getData?.role?.label ||
                renewData?.target_role_name ||
                "Software Engineers",
            },
            {
              title:
                selectedValue?.name || getData?.skill_name || "Communication",
            },
          ]}
        />
      </div>
      </div>
      <div className="card-body">
        <div className="devops-box-wrap">
          <div className="dev-ops-list">
            <div className="dev-title d-flex align-items-center justify-content-between">
              <h2>
                {selectedValue?.name || getData?.skill_name || "Communication"}
              </h2>
              {allQuestion?.length > 0 && (
                <div className="dev-timerbox d-flex align-items-center ">
                  <Button className="primary-btn" onClick={handlePause}>
                    Pause
                  </Button>{" "}
                  <span className="me-2">
                    <TimerIcon />
                  </span>
                  <span className="timer">{formatTime()}</span>
                </div>
              )}
            </div>
            {allQuestion?.length > 0 ? (
              <div className="dev-test-question">
                <h3>
                  {currentQuestion + 1}. {""}
                  {removeNumber(allQuestion[currentIndex]?.question)}
                </h3>
                <div className="question-list">
                  <Radio.Group
                    onChange={(e) =>
                      handleRadioChange(
                        e,
                        currentIndex,
                        allQuestion[currentIndex]
                      )
                    }
                  >
                    <Space direction="vertical">
                      <Radio value={allQuestion[currentIndex]?.option1}>
                        {cleanString(allQuestion[currentIndex]?.option1) ||
                          "none"}
                      </Radio>
                      <Radio value={allQuestion[currentIndex]?.option2}>
                        {cleanString(allQuestion[currentIndex]?.option2) ||
                          "none"}
                      </Radio>
                      <Radio value={allQuestion[currentIndex]?.option3}>
                        {cleanString(allQuestion[currentIndex]?.option3) ||
                          "none"}
                      </Radio>
                      <Radio value={allQuestion[currentIndex]?.option4}>
                        {cleanString(allQuestion[currentIndex]?.option4) ||
                          "none"}
                      </Radio>
                      {/* <Radio >
                      {allQuestion[currentIndex]?.answer}
                    </Radio> */}
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            ) : (
              <div className="dev-test-question">Please wait...</div>
            )}
            <div className="dev-ops-footer">
              <div className="progressbar">
                <span>{`Question ${currentQuestion + 1} / ${getQuesLength(
                  allQuestion?.length
                )}`}</span>
                <Progress
                  percent={
                    ((currentQuestion + 1) / getQuesLength(allQuestion?.length)) *
                    100
                  }
                  showInfo={false}
                />
              </div>
              {getQuesLength(allQuestion?.length) - 1 === currentQuestion ? (
                <Button
                  className="primary-btn"
                  disabled={loading || nextDisable}
                  onClick={() => handleFinish()}
                >
                  {loading ? "Processing..." : "Finish Test"}
                </Button>
              ) : (
                <Button
                  className="primary-btn"
                  disabled={nextDisable}
                  onClick={() => handleNextQuestion(currentQuestion)}
                >
                  Next Question
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default DevRenewalList;
