import Select from "react-select";
import { FilterIcon } from "../../../assets/icon";
import { filterOption } from "../../../constant";
const CustomPlaceholder = (props) => (
  <div className="d-flex align-items-center position-absolute ps-3">
    <FilterIcon />
    <span className="ms-2 fs-6">Filter</span>
  </div>
);
const LayoutHead = ({ pageTitle, handleSearch, handleSort, type }) => {
  return (
    <>
      <div className="headingbar d-flex justify-content-between align-items-center">
        <div className="title">
          <h5 className="card-title mb-0">{pageTitle}</h5>
        </div>
        <div className="search-field d-flex align-items-center justify-content-end">
          <div className="SKD-search d-flex align-items-center">
            <div className="input-group">
              <i className="fa-solid fa-magnifying-glass"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Search Job Role"
                onChange={handleSearch}
              />
            </div>
          </div>
          {type && type == "pathway" ? (
            <></>
          ) : (
            <div className="filter-field ms-3">
              <Select
                className="select-option-list"
                classNamePrefix="select"
                options={filterOption}
                components={{
                  Placeholder: CustomPlaceholder,
                }}
                isSearchable={false}
                onChange={(val) => handleSort(val)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default LayoutHead;
