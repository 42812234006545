import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Navigate } from "react-router";
import { useNavigate } from 'react-router-dom';

const EnrolledModal = ({ show, handleClose, url, modalTitle, enrollCase, message, journeyID }) => {
    const navigate = useNavigate();
    const [buttonTitle, setButtonTitle] = useState('');
    // Function to handle 'Yes' button click

    useEffect(() => {
        if (show){
            console.log('enroll case', enrollCase)
            if (enrollCase == "1") {
                setButtonTitle('Register')
            }
            else if (enrollCase == "2") {
                setButtonTitle('Enroll')
            }
            else if (enrollCase == "3") {
                setButtonTitle('Go To DashBoard')
            }
        }

    }, [show]);

    const handleYesClick = () => {
        if (enrollCase === "3") {           
            navigate('/dashboard');
        }
        else {
            navigate(`/register/${journeyID}`);
        }
        
    };

    const handleLoginClick = () => {
        navigate(`/login/${journeyID}`);
    };

    
    return (
        <Modal show={show}  onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{ modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer> 
                {enrollCase == 1 && <Button variant="secondary" onClick={handleLoginClick}>
                    Login
                </Button>
                }
                <Button variant="primary" onClick={handleYesClick}>
                    {buttonTitle}
                </Button>
                
            </Modal.Footer>
        </Modal>
    );
};

export default EnrolledModal;
