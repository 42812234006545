import React, { useEffect, useState } from "react";
import { Tabs, Select, Table, Button, Input, message } from "antd";
import Loader from "../../components/loader";
import { callPostApi, callPostFormDataApi } from "../../services/roadmap_api";
import apiFetch, { BASE_URL } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import UploadCv from "../../components/UploadCv";
import { Card } from "react-bootstrap";
const { Option } = Select;
const { Search } = Input;
const { TabPane } = Tabs;

const LearnerJobSearch = ({ current_company }) => {
  const [error, setError] = useState("");
  const [linkorfile, setLinkorfile] = useState(null);
  const [linkedinUrl, setLindedinUrl] = useState(null);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");
  const [allJobs, setAllJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState([]);

  const handleButtonClick = () => {
    setIsVisible(true);
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const loadAllJobs = async () => {
    try {
      setLoading(true);
      setLoadingFor("Fetching Jobs");

      // First API call to get relevant job suggestions
      // Create a new FormData object
      let formData = new FormData();

      // Append fields to the FormData object
      formData.append("company_id", current_company?.id);
      formData.append("candidate_id", user?.id);
      formData.append("candidate_type", "employee");
      console.log("formdata", formData);

      // Use the FormData object in your API call
      //?company_id=68ccad46-dc0f-4bb0-ac14-1d924faf7f08&linkedin_url=https%3A%2F%2Fwww.linkedin.com%2Fjobs%2Fview%2F3978453766%2F
      let response = await callPostApi(
        `/api/job_candidate_panel/get_relevant_jobs_suggestions?company_id=${current_company?.id}&candidate_id=${user?.id}&candidate_type=employee`,
        null
      );
      // check if response is an array then setAllJobs(response); else show message.error(response)
      if (Array.isArray(response)) {
        setAllJobs(response);
      } else {
        setAllJobs([]);
        message.error(response);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLinkedinUrl(user?.id);
  }, []);

  useEffect(() => {
    handlebtnJobProfile();
  }, [current_company]);

  const handlebtnJobProfile = async () => {
    await handleAddJobProfile();
    loadAllJobs();
  };

  const getLinkedinUrl = async (user_id) => {
    const data = await apiFetch(`/api/user-linkedin-profile/`, "GET", null, {
      Authorization: `Token ${user?.token}`,
    });
    if (data.success) {
      setLindedinUrl(data.linkedin_url);
    }
  };

  const setUserLinedinProfile = async () => {
    const data = await fetch(
      `/api/user-linkedin-profile/`,
      "POST",
      { linkedin_url: linkedinUrl },
      { Authorization: `Token ${user?.token}` }
    );
  };

  const handleAddJobProfile = async () => {
    if (!linkorfile && !linkedinUrl) {
      setError("Please provide either a LinkedIn URL or upload a CV.");
      return;
    }

    if (!linkedinUrl) {
      setUserLinedinProfile(linkedinUrl);
    }

    try {
      setLoading(true);
      setLoadingFor("Adding Job Profile");
      let url = "";
      if (linkedinUrl) {
        url = `/api/job_candidate_panel/add_candidate_info?company_id=${current_company?.id}&candidate_id=${user?.id}&candidate_type=employee&linkedin_profile_url=${linkedinUrl}`;
      } else {
        url = `/api/job_candidate_panel/add_candidate_info?company_id=${current_company?.id}&candidate_id=${user?.id}&candidate_type=employee&cv_file=${linkedinUrl}`;
      }
      let response = await callPostApi(url, {});
      if (response) {
        message.success(response);
      }
    } catch (error) {
      console.error("Error adding job:", error);
    } finally {
      setLoading(false);
    }
  };

  const validateLinkedinUrl = (value) => {
    const pattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[^\/]+\/?$/;
    return pattern.test(value);
  };

  const handleLinkedinChange = (e) => {
    setLindedinUrl(e.target.value);
  };

  const jobColumns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Relevant",
      dataIndex: "score",
      key: "score",
      render: (score) => `${Math.round(score * 100)}%` || "N/A",
    },
    {
      title: "Linkedin Url",
      dataIndex: "linkedin_url",
      key: "linkedin_url",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            placeholder="Search URL"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button onClick={clearFilters} style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.linkedin_url.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Active", value: "Y" },
        { text: "Inactive", value: "N" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => (status === "Y" ? "Active" : "Inactive"),
    },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "skills",
      width: 400,
      render: (skills) => (
        <div className="role-list max-h-200">
          <div>
            {Object.entries(skills).length > 0
              ? Object.entries(skills).map(([skillName, skillData], index) => (
                  <span
                    key={index}
                    className="role-item custombadge bg-secondary"
                  >
                    {`${skillData.skilltype} - ${skillName}(${skillData.imp})`}
                  </span>
                ))
              : "N/A"}
          </div>
        </div>
      ),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            placeholder="Search Industry"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button onClick={clearFilters} style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.industry.toLowerCase().includes(value.toLowerCase()),
      render: (industry) => industry || "N/A",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            placeholder="Search Title"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button onClick={clearFilters} style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.title.main_title.toLowerCase().includes(value.toLowerCase()),
      render: (title) => title.main_title || "N/A",
    },
    {
      title: "Employment Type",
      dataIndex: "employment_type",
      key: "employment_type",
      render: (employment_type) => employment_type || "N/A",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            placeholder="Search Type"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button onClick={clearFilters} style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.employment_type.toLowerCase().includes(value.toLowerCase()),
    },
  ];

  return (
    <>
      <Card className="job-profile mb-4">
        {loading && <Loader text={loadingFor} />}
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title mb-0">Search Job</h5>
            <div className="text-end">
              <Button type="primary" onClick={handleButtonClick}>
                Add Your Profile
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row py-12">
            {isVisible && (
              <div className="linked-url">
                <Tabs
                  activeKey={activeKey}
                  onChange={(key) => setActiveKey(key)}
                  // tabBarExtraContent={
                  //   <Button type="primary" onClick={handleAddJobProfile}>
                  //     Add
                  //   </Button>
                  // }
                  items={[
                    {
                      key: "1",
                      label: "LinkedIn URL",
                      children: (
                        <div className="d-flex gap-3">
                          <input
                            type="text"
                            value={linkedinUrl}
                            className="form-control"
                            onChange={handleLinkedinChange}
                            placeholder="https://www.linkedin.com/in/david-6456682b0/oad"
                          />
                        </div>
                      ),
                    },
                    {
                      key: "2",
                      label: "Upload CV",
                      children: (
                        <div className="d-flex gap-3">
                          <UploadCv
                            setLinkorfile={setLinkorfile}
                            setNextDisabled={setNextDisabled}
                          />
                        </div>
                      ),
                    },
                  ]}
                />
                <div className="text-end my-3">
                  <Button type="secondary" onClick={handlebtnJobProfile}>
                    Add
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
      <Card className="job-profile">
        <div className="card-header">
          <h5 className="card-title mb-0">Suggested Jobs</h5>
        </div>
        <div className="card-body existing-jobs mt-4">
          <Table
            columns={jobColumns}
            dataSource={allJobs}
            rowKey="id"
            pagination={{
              pageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
              showTotal: (total) =>
                `Total ${total} Jobs Found In ${current_company?.name}`,
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default LearnerJobSearch;
