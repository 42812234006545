import React, { useEffect, useState } from "react";
import RenewalList from "../component/skill-renewal/renewal-list";
import { useDispatch, useSelector } from "react-redux";
import { callGetApi, callPostApi } from "../../services/roadmap_api";
import Loader from "../../components/loader";
import { toMessage, uniqueByTargetRoleName } from "../utils";
import { storeAllSkillPathway } from "../../store/pathway";
import apiFetch from "../../api";

const SkillsPathway = ({ current_company = current_company }) => {
  const dispatch = useDispatch();

  const [testsBackup, setTestsBackup] = useState([]);
  const [loadingFor, setLoadingFor] = useState("");

  const { pathway } = useSelector((state) => state?.pathway);

  const userString = localStorage.getItem("user");

  const user = userString ? JSON.parse(userString) : null;
  console.log(user, "user");

  const getAllTests = async () => {
    setLoadingFor("Getting User all pathway...");
    // const res = await callGetApi(
    //   `/api/user/get-skill-renewal-dashboard/?user_id=${user?.user_id || "1111"}`
    // );

    const pathres = await apiFetch(
      "/api/get-skill-renewal-dashboard/",
      "POST",
      {
        company_id: current_company?.id,
      },
      {
        "Content-Type": "application/json",
        Authorization: `Token ${user?.token}`,
      }
    );
    if (pathres.success == true) {
      const res = pathres?.data;
      console.log(res, "resresresresres");
      if (res && res !== undefined) {
        setLoadingFor("");
        const IsRoadmap = res?.filter((it) => it?.is_roadmap);
        let filteredData = uniqueByTargetRoleName(IsRoadmap);
        dispatch(storeAllSkillPathway(filteredData));
        setTestsBackup(filteredData);
      } else {
        setLoadingFor("");
        toMessage("Something went wrong! try again later", "error");
      }
    } else {
      setLoadingFor("");
      toMessage("Please contact admin", "error");
      dispatch(storeAllSkillPathway([]));
      setTestsBackup([]);
    }
    setLoadingFor("");
  };

  useEffect(() => {
    if (user?.user_id && current_company?.id) {
      getAllTests();
    }
  }, [current_company]);

  return (
    <>
      {loadingFor !== "" ? <Loader text={loadingFor} /> : ""}
      <RenewalList
        type="pathway"
        user={user}
        allSkillTests={pathway}
        testsBackup={testsBackup}
        // showModal={handleShow}
        // handleClickRenewal={handleClickRenewal}
        // handleRoadmap={handleToRoadmap}
        // handleBetaShow={handleBetaShow}
      />
    </>
  );
};
export default SkillsPathway;
