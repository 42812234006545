import { Button, Card, Col, Row } from "react-bootstrap";
import LayoutHeading from "./layout-head";
import {
  storeAllSkillTests,
  storePreSkills,
  storeSelValues,
  storeTabStatus,
} from "../../../store/skillRenewal";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { callGetApi } from "../../../services/roadmap_api";
import {
  capitalizeFirstLetter,
  removeDuplicateNameSkills,
  toMessage,
  truncateTextAfterWords,
} from "../../utils";
import { Tooltip, message } from "antd";
import RenewalDescriptionModal from "./renewal-description-modal";
import { useState, useEffect } from "react";
import { storeAllSkillPathway } from "../../../store/pathway";
import { BASE_URL } from "../../../api";
const RenewalList = ({
  allSkillTests,
  testsBackup,
  showModal,
  handleClickRenewal,
  handleRoadmap,
  user,
  handleBetaShow,
  type,
  assessments,
  handleSkillTest,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [viewLoading, setViewLoading] = useState(false);
  const [filter, setFilter] = useState({ search: "", filter: "" });

  const dashboard_role = localStorage.getItem("dashboard_role");

  useEffect(() => {
    if (allSkillTests && allSkillTests.length > 0) {
      const dashboard_role = localStorage.getItem("dashboard_role");
      if (dashboard_role) {
        dispatch(
          storeSelValues({
            type: "role",
            value: {
              label: dashboard_role,
              value: dashboard_role?.toLowerCase(),
            },
          })
        );
        const foundItem = allSkillTests?.find(
          (item) =>
            item?.target_role_name?.toLowerCase() ===
            dashboard_role?.toLowerCase()
        );

        if (foundItem) {
          // Store the found item in localStorage as "renewal-data"
          localStorage.setItem("renewal-data", JSON?.stringify(foundItem));

          // Trigger skill renewal by calling handleStateChangeInSkill with foundItem
          localStorage.removeItem("dashboard_role");
          handleStateChangeInSkill(foundItem);
        } else {
          console.warn("Dashboard role not found in allSkillTests.");
          localStorage.removeItem("dashboard_role");
        }
      } else {
        console.warn("No dashboard role found in localStorage.");
      }
    }
  }, [allSkillTests, dispatch]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.trim().toLowerCase();
    setFilter({ ...filter, search: searchTerm });

    let searchData = testsBackup?.filter((data) =>
      data?.target_role_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filter?.filter !== "") {
      searchData = searchData?.filter(
        (data) =>
          data?.status?.trim().toLowerCase() === filter?.filter?.toLowerCase()
      );
    }
    if (type && type == "pathway") {
      dispatch(storeAllSkillPathway(searchData));
    } else {
      dispatch(storeAllSkillTests({ type: "tests", data: searchData }));
    }
  };
  console.log(allSkillTests.length, "allSkillTests");

  const handleSort = (selectedOption) => {
    const searchTerm = selectedOption?.value.trim().toLowerCase();
    setFilter({ ...filter, filter: searchTerm == "all" ? "" : searchTerm });
    let searchData = testsBackup;
    if (searchTerm !== "all") {
      searchData = testsBackup?.filter(
        (data) => data?.status?.trim()?.toLowerCase() == searchTerm
      );
    }
    if (filter?.search !== "") {
      searchData = searchData?.filter((data) =>
        data?.target_role_name?.toLowerCase().includes(filter?.search)
      );
    }

    dispatch(storeAllSkillTests({ type: "tests", data: searchData }));
  };

  const getFilteredLink = (allLinks, roadmapres) => {
    let result = {
      Reminder: roadmapres?.Roadmap?.Reminder,
    };
    let roadmap = roadmapres?.Roadmap;
    for (let key in roadmap) {
      if (Array.isArray(roadmap[key])) {
        let data = roadmap[key]?.map((it) => {
          for (let key2 in allLinks) {
            if (key2.includes(it.Topic)) {
              return { ...it, links: allLinks[key2] };
            }
          }
          return it;
        });
        result[key] = data;
      }
    }
    let replcedData = { ...roadmapres, Roadmap: result };
    console.log(replcedData, "roadmapres filteredLinks");
    return replcedData;
  };

  const getRoadMap = async (id, role) => {
    setViewLoading(true);
    const res = await callGetApi(`/api/db-roadmap/get-reg-user/${id || "33"}`);
    if (res && res != undefined) {
      setViewLoading(false);
      let striRes = {
        ...res,
        content_plan: JSON.parse(res?.content_plan[0]),
        roadmap: JSON.parse(res?.roadmap[0]),
        mentor: JSON.parse(res?.mentor[0]),
      };
      let planss = getFilteredLink(striRes?.content_plan, striRes?.roadmap);
      // let data={CurrentSkills:"",Mentorships:"",Roadmap:"",SkillGapAnalysis:""}
      let updatePlan = { ...planss, Mentorships: striRes?.mentor };
      // console.log(updatePlan,res, "roadmapres resresresresres");

      localStorage.setItem("showlinks", true);
      localStorage.setItem("roadmap_id", id);

      localStorage.setItem("analyserData", JSON?.stringify(updatePlan));
      let route =
        type && type == "pathway"
          ? "/dashboard/analyser/?pathway=true"
          : "/dashboard/analyser";
      navigate(route);
      let current = await getCurrentSkills(role);

      getUserPreDetailsForUpdate(id, current);
    }
  };

  const getCurrentSkills = async (role) => {
    // const res = await callGetApi(
    //   `/api/user/get-skill-renewal/${user?.user_id || "1111"}`
    // );
    const res = await callGetApi(
      `/api/user/skill-proficiency-graph/?target_role_name=${encodeURIComponent(
        role
      )}&user_id=${user?.user_id}`
    );
    console.log(res, "resresresresres");
    let reverseUserSkills = res?.length > 1 ? [...res]?.reverse() : res;

    if (reverseUserSkills && reverseUserSkills !== undefined) {
      return reverseUserSkills;
    }
    return [];
  };

  const getUserPreDetailsForUpdate = async (id, current) => {
    // console.log(current,"current userPreDetils")
    let skillDetails =
      current
        ?.filter((it) => it?.current_proficiency)
        ?.map((it) => ({
          skill: it?.skill_name,
          proficiency: it?.current_proficiency,
          type: it?.skill_type,
        })) || [];

    let currentSkillNames =
      current?.filter((ite) => !ite?.status)?.map((it) => it.skill_name) || [];
    let proficientSkills =
      current?.filter((ite) => ite?.status)?.map((it) => it.skill_name) || [];

    let res = await callGetApi(`/api/reg-user/read_by_roadmap/${id || "57"}/`);

    if (res) {
      res.existing_skills = [...res.existing_skills, ...currentSkillNames];
    } else {
      res = { existing_skills: currentSkillNames };
    }
    const uniqueSkills = removeDuplicateNameSkills(res.existing_skills);
    res.existing_skills = uniqueSkills;

    dispatch(
      storeAllSkillTests({ type: "proficient_skills", data: proficientSkills })
    );
    dispatch(storeAllSkillTests({ type: "update_roadmap", data: res }));
    dispatch(storePreSkills(skillDetails));
  };

  const handleOnClick = (type, item) => {
    localStorage.setItem("renewal-data", JSON?.stringify(item));
    if (type == "create") {
      handleRoadmap(item?.target_role_name);
      navigate("/dashboard/analyser");
      localStorage.setItem("isMap", true);
    } else if (type == "view" && item) {
      getRoadMap(item?.roadmap_id, item?.target_role_name);
    } else {
      toMessage("Roadmap not Found", "warning");
    }
  };

  const handleStateChangeInSkill = (item) => {
    let val = item?.target_role_name;
    dispatch(
      storeSelValues({
        type: "role",
        value: { label: val, value: val?.toLowerCase() },
      })
    );
    localStorage.setItem("renewal-data", JSON?.stringify(item));
    handleClickRenewal(val);
  };
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [renewalModal, setRenewalModal] = useState(false);

  const handleRenewalShow = (description, target_role_name) => {
    setSelectedTitle(target_role_name);
    setSelectedDescription(description);
    setRenewalModal(true);
  };

  const handleRenewalHide = () => {
    setRenewalModal(false);
    setSelectedTitle(null);
    setSelectedDescription(null);
  };

  return (
    <Card>
      <div className="card-header">
        <LayoutHeading
          pageTitle={
            type && type == "pathway"
              ? "Pathway Dashboard"
              : "Skill Renewal Dashboard"
          }
          type={type}
          handleSearch={handleSearch}
          handleSort={handleSort}
        />
      </div>
      <div className="card-body">
        {type && type == "pathway" ? (
          <></>
        ) : (
          <>
            <div>
              {assessments && assessments.length > 0 && (
                <div className="renewal-list-wrapper">
                  <Row>
                    {assessments.map((assessment) => (
                      <Col
                        key={assessment?.id}
                        xs={12}
                        sm={6}
                        md={4}
                        className="mb-4 rnl-column"
                      >
                        <div className="rnl-box">
                          <div className="rnl-box-field">
                            <div className="skill-test">
                              <div className="skill-department">
                                <span>
                                  {capitalizeFirstLetter(
                                    assessment?.name || ""
                                  )}
                                </span>
                              </div>
                            </div>
                            <ul>
                              {assessment.skills
                                .filter((skill) => skill.status === true) // Ensure this matches your data structure
                                .map((skill) => (
                                  <li key={skill.id}>{skill.name}</li> // Added a key prop for uniqueness
                                ))}
                            </ul>
                            {assessment.user_assessment.status ===
                            "Completed" ? (
                              <>
                                <ul>
                                  <li>
                                    <b>Score:</b>{" "}
                                    {assessment.user_assessment.score} %
                                  </li>
                                  <li>
                                    <b>Proficiency Level:</b>{" "}
                                    {
                                      assessment.user_assessment
                                        .proficiency_level
                                    }
                                  </li>
                                </ul>
                                <div className="button-box">
                                  <Button
                                    // disabled={viewLoading}
                                    onClick={() =>
                                      handleSkillTest(
                                        assessment.id,
                                        assessment.name
                                      )
                                    }
                                    className="primary-border-btn mt-2"
                                  >
                                    {"ReTake Skill Test"}
                                  </Button>
                                  {assessment.user_assessment.report_pdf && (
                                    <Button
                                      onClick={() =>
                                        window.open(
                                          assessment.user_assessment.report_pdf,
                                          "_blank"
                                        )
                                      }
                                      className="btn-primary mt-2"
                                    >
                                      {"Download Report"}
                                    </Button>
                                  )}
                                </div>
                              </>
                            ) : (
                              <div className="button-box">
                                <Button
                                  // disabled={viewLoading}
                                  onClick={() =>
                                    handleSkillTest(
                                      assessment.id,
                                      assessment.name
                                    )
                                  }
                                  className="primary-border-btn mt-2"
                                >
                                  {"Take Skill Test"}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
            </div>
            <div className="subskill-title alert alert-success d-flex align-items-center justify-content-center">
              <p className="mb-0">
                <b>Note:</b> Did you know? Take skill tests without a roadmap!
                Assess your skills and proficiency levels in various job roles.
              </p>
              <Button className="primary-btn" onClick={showModal}>
                Explore Skill Tests
              </Button>
            </div>
          </>
        )}
        <div className="renewal-list-wrapper">
          <Row>
            {allSkillTests?.length > 0 ? (
              allSkillTests?.map((items, index) => (
                <>
                  <Col
                    key={index}
                    xs={12}
                    sm={6}
                    md={4}
                    className="mb-4 rnl-column"
                  >
                    <div className="rnl-box">
                      <div className="rnl-box-field">
                        <div className="skill-test">
                          <div className="skill-department">
                            {/* <i className="fa-solid fa-location-crosshairs"></i> */}
                            <span>
                              {capitalizeFirstLetter(
                                items?.target_role_name || ""
                              ) || ""}
                            </span>
                          </div>
                          {type && type == "pathway" ? (
                            <></>
                          ) : (
                            <div className="filter-data">
                              <span className={items?.status}>
                                {items?.status?.toLowerCase() == "completed" ||
                                items?.status?.toLowerCase() == "in-progress"
                                  ? capitalizeFirstLetter(items?.status || "")
                                  : "Yet To Start"}
                              </span>
                            </div>
                          )}
                        </div>
                        <p>
                          {truncateTextAfterWords(items?.description, 20)}{" "}
                          {items?.description?.length > 20 && (
                            <span
                              onClick={() =>
                                handleRenewalShow(
                                  items?.description,
                                  items?.target_role_name
                                )
                              }
                              style={{ color: "blue", cursor: "pointer" }}
                            >
                              view more
                            </span>
                          )}
                        </p>
                        {/* <Tooltip
                          title={
                            items?.description 
                            || "The Responsible for ensuring efficient operations and achieving organizational goals, the Production Manager plays a critical role in driving business success through strategic planning, analysis, and execution of key tasks."
                            
                          }
                          // placement="right"
                          color={"#E0E0F0"}
                          key={"#E0E0F0"}
                        >
                          {truncateTextAfterWords(
                            items?.description 
                            || "The Responsible for ensuring efficient operations and achieving organizational goals, the Production Manager plays a critical role in driving business success through strategic planning, analysis, and execution of key tasks."
                          )}
                        </Tooltip> */}
                        {type && type == "pathway" ? (
                          <div className="button-box">
                            <Button
                              // disabled={viewLoading}
                              onClick={() => handleOnClick("view", items)}
                              className="primary-border-btn"
                            >
                              {"View Roadmap"}
                            </Button>
                          </div>
                        ) : (
                          <div className="button-box">
                            {items?.is_roadmap ? (
                              <Button
                                // disabled={viewLoading}
                                onClick={() => handleOnClick("view", items)}
                                className="primary-border-btn"
                              >
                                {"View Roadmap"}
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleOnClick("create", items)}
                                className="primary-border-btn"
                              >
                                Create Roadmap
                              </Button>
                            )}
                            {items?.is_beta ? (
                              <Button
                                onClick={handleBetaShow}
                                className="primary-border-btn"
                              >
                                Beta
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                onClick={() => handleStateChangeInSkill(items)}
                              >
                                Skill Renewal
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </>
              ))
            ) : (
              <div className="not-found-data">
                <h3 style={{ textAlignLast: "center" }}>
                  {" "}
                  {type && type == "pathway"
                    ? "No Pathway Found"
                    : "No Tests Found"}{" "}
                </h3>
              </div>
            )}
          </Row>
          <RenewalDescriptionModal
            ItemTitle={selectedTitle}
            ItemDescription={selectedDescription}
            show={renewalModal}
            hideModal={handleRenewalHide}
          />
        </div>
      </div>
    </Card>
  );
};
export default RenewalList;
