import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Table,
  InputNumber,
  Button,
  Input,
  message,
  Menu,
  Dropdown,
} from "antd";
import MultiSelect from "../component/MutliSelect";
import apiFetch, { BASE_URL } from "../../api";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

const AssessmentList = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(false);
  let user = JSON?.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchAssessments('name', 'asc');
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const { field, order } = sorter;
    // Transform sort order to a format your backend expects
    const sortOrder = order === "ascend" ? "asc" : order === "descend" ? "desc" : null;
    await fetchAssessments(field, sortOrder); // Pass the sorting field and order
  };

  const fetchAssessments = async (sortField, sortOrder) => {
    setLoading(true);
    try {
      
      const data = await apiFetch(`/api/assessment-list/?sortField=${sortField}&sortOrder=${sortOrder}`, "GET", null, {
        Authorization: `Token ${user?.token}`,
      });
      if (data && data.success) {
        setAssessments(data.data);
        message.success("Assessments fetched successfully.");
      } else {
        message.error("Failed to retrieve assessments.");
      }
    } catch (error) {
      message.error(`Error fetching assessments: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  const [openMenuRowId, setOpenMenuRowId] = useState(null);

  const toggleActionMenu = (customRowId) => {
    setOpenMenuRowId((prevRowId) => (prevRowId === customRowId ? null : customRowId));
  };
  const columns = [
    {
      title: "Assessment Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Domain",
      dataIndex: "domain_name",
      key: "domain_name",
      sorter: true,
    },
    {
      title: "Talent Pool",
      dataIndex: "talent_pools",
      key: "talent_pools",
      sorter: true,
    },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "skills",
      render: (skills) => (
        <ul className="assessment-skills-list">
          {skills.map((skill) => (
            <li key={skill.name}>
              {skill.name} - Proficiency: {skill.proficiency}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status ? (
          <span class="status active">Active</span>
        ) : (
          <span class="status inactive">Inactive</span>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (row, index) => {
        const customRowId = `custom-${row.id}-${index}`; 
        return (
          <div className="action ms-auto">
            <div className="text-end">
              <i
                className="fa fa-ellipsis-v"
                aria-hidden="true"
                onClick={() => toggleActionMenu(customRowId)} 
                style={{ cursor: "pointer" }}
              ></i>
            </div>
            {openMenuRowId === customRowId && ( 
              <div className="action-item">
                <Link to={`/domain-expert-dashboard/assessment/${row.id}/`}>
                  <i className="fa fa-edit option-icon"></i> Edit
                </Link>
                <Link
                  to={`/domain-expert-dashboard/assessment/assign/${row.id}/`}
                >
                  <i className="fa fa-tasks option-icon"></i> Assign Talent Pool
                </Link>
                <Link
                  to={`/domain-expert-dashboard/assessment/sample-questions/${row.id}/`}
                >
                  <i className="fa fa-tasks option-icon"></i> View Sample
                  Questions
                </Link>
                <a
                  onClick={() => handleToggleStatus(row.id, !row.status)}
                  className={row.status ? "danger-text" : "success-text"}
                >
                  <i className="fa fa-toggle-off toggle-icon option-icon"></i>{" "}
                  {row.status ? "Inactivate" : "Activate"}
                </a>
              </div>
            )}
          </div>
          // <Menu>
          //   <Menu.Item key="1">
          //     <Link to={`/domain-expert-dashboard/assessment/${row.id}/`}>
          //       <i className="fa fa-edit option-icon"></i> Edit
          //     </Link>
          //   </Menu.Item>
          //   <Menu.Item key="2">
          //     <Link
          //       to={`/domain-expert-dashboard/assessment/assign/${row.id}/`}
          //     >
          //       <i className="fa fa-tasks option-icon"></i> Assign Talent Pool
          //     </Link>
          //   </Menu.Item>
          //   <Menu.Item key="3">
          //     <Link
          //       to={`/domain-expert-dashboard/assessment/sample-questions/${row.id}/`}
          //     >
          //       <i className="fa fa-tasks option-icon"></i> View Sample
          //       Questions
          //     </Link>
          //   </Menu.Item>
          //   <Menu.Item key="3">
          //     <a
          //       onClick={() => handleToggleStatus(row.id, !row.status)}
          //       className={row.status ? "danger-text" : "success-text"}
          //     >
          //       <i className="fa fa-toggle-off toggle-icon option-icon"></i>{" "}
          //       {row.status ? "Inactivate" : "Activate"}
          //     </a>
          //   </Menu.Item>
          // </Menu>
        );

        // return (
        //   <Dropdown overlay={menu} trigger={["click"]}>
        //     <Button>
        //       Select an option <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // );
      },
    },
  ];

  const handleToggleStatus = async (id, status) => {
    console.log("assessment id", id);
    console.log("assessment status", status);
    setLoading(true);
    try {
      const data = await apiFetch(
        `/api/assessment-update-status/`,
        "POST",
        { id: id, status: status },
        { Authorization: `Token ${user?.token}` }
      );
      if ( data && data.success) {
        setAssessments(data.data);
        message.success("Assessments fetched successfully.");
      } else {
        message.error("Failed to retrieve assessments.");
      }
    } catch (error) {
      message.error(`Error fetching assessments: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <div className="card-header">
        <h5 className="card-title mb-0">Assessment List</h5>
      </div>
      <div className="card-body">
        <Table
          columns={columns}
          dataSource={assessments}
          rowKey={(record) => record.name}
          loading={loading}
          pagination={{ pageSize: 10 }}
          onChange={handleTableChange} // Add this line
        />
      </div>
    </Card>
  );
};
export default AssessmentList;
