import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
const LinkedinUrl = ({ onUrlChange, setShowNext, setLinkorfile }) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const validateLinkedinUrl = (value) => {
        // const pattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9-_]+\/?$/;
        const pattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[^\/]+\/?$/;
        return pattern.test(value);
    };
    const handleExit = () => {
    };
    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        const isValid = validateLinkedinUrl(newValue);
        if (!isValid && newValue) {
            setError('Please enter a valid LinkedIn URL.');
            return;
        }
        else {
            setError('');
        }
        setLinkorfile(newValue);
        // Notify parent about the validation result
        onUrlChange(isValid);
    };
    useEffect(() => {
        setShowNext(true);
        // console.log(" in linked in ->");
    }, []);
    return (<Form.Item validateStatus={error ? 'error' : ''} help={error || ''}>
            <Input value={value} onChange={handleChange} placeholder="https://www.linkedin.com/in/david-6456682b0/oad"/>
        </Form.Item>);
};
export default LinkedinUrl;
