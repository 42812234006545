import React from "react";
import { Button, Modal } from "antd";
const RenewalDescriptionModal = ({
  show,
  hideModal,
  ItemDescription,
  ItemTitle,
}) => {
  return (
    <div>
      <Modal open={show} onCancel={hideModal} className="skill-modal-wrap"  okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}>
        <div className="renewal-modal">
            <div className="skill-title">
            <i className="fa-solid fa-location-crosshairs"></i>
            <h2>{ItemTitle}</h2>
            </div>
         
          <div className="skill-description"><p>{ItemDescription} </p></div>
        </div>
      </Modal>
    </div>
  );
};
export default RenewalDescriptionModal;
