// src/features/counterSlice.js

import { createSlice } from "@reduxjs/toolkit";

export const skillSlice = createSlice({
  name: "skill_renewal",
  initialState: {
    tabStatus: "list",
    tabQaStatus: "start",
    allSkillTests: [],
    allJobRole: [],
    allRolesDes: [],
    selectedVal: {
      type: "",
      name: "",
      role: "",
      level: "",
    },
    skillData: [],
    storedSkillData: {},
    allCurrentSkills: [],
    runNextSkill: false,
    isRunSkill: false,
    roleReload: false,
    userPreDetils: {},
    userProfSkills: [],
    userAllPreDetils: [],
    askedQuestion: [],

    roleCount: { total: 0, certified: 0, non_certified: 0 },
    allQuestion: [],
    selectedQuestion: [],
  },
  reducers: {
    storeAllSkillTests: (state, action) => {
      if (action?.payload?.type === "tests") {
        state.allSkillTests = action?.payload?.data;
      } else if (action?.payload?.type === "role") {
        state.allJobRole = action?.payload?.data;
      } else if (action?.payload?.type === "current") {
        state.allCurrentSkills = action?.payload?.data;
      } else if (action?.payload?.type === "allRoles") {
        state.allRolesDes = action?.payload?.data;
      } else if (action?.payload?.type === "update_roadmap") {
        state.userPreDetils = action?.payload?.data;
      } else if (action?.payload?.type === "question") {
        state.askedQuestion = action?.payload?.data;
      } else if (action?.payload?.type === "proficient_skills") {
        state.userProfSkills = action?.payload?.data;
      }
    },
    storeTabStatus: (state, action) => {
      state.tabStatus = action?.payload;
    },
    storeQaTabStatus: (state, action) => {
      state.tabQaStatus = action?.payload;
    },
    storeSelValues: (state, action) => {
      if (action?.payload?.type === "all") {
        return {
          type: "",
          name: "",
          role: "",
          level: "",
        };
      } else {
        return {
          ...state,
          selectedVal: {
            ...state.selectedVal,
            [action.payload.type]: action.payload.value,
          },
        };
      }
    },
    storeSkillData: (state, action) => {
      state.skillData = action?.payload;
    },
    storeSavedSkillData: (state, action) => {
      state.storedSkillData = action?.payload;
    },
    storeNextSkillRun: (state, action) => {
      state.runNextSkill = action?.payload;
    },
    storeRunSkills: (state, action) => {
      state.isRunSkill = action?.payload;
    },
    storePreSkills: (state, action) => {
      state.userAllPreDetils = action?.payload;
    },

    storeRoleCount: (state, action) => {
      state.roleCount = action?.payload;
    },
    storeReloadRoles: (state, action) => {
      state.roleReload = action?.payload;
    },
  },
});

export const {
  storeAllSkillTests,
  storeTabStatus,
  storeSelValues,
  storeSkillData,
  storeQaTabStatus,
  storeSavedSkillData,
  storeNextSkillRun,
  storeRunSkills,
  storeReloadRoles,
  storePreSkills,

  storeRoleCount,
} = skillSlice.actions;

// export const selectCount = (state) => state.counter.value;

export default skillSlice.reducer;
